import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, submit } from 'redux-form';
import {
  Create,
} from 'react-admin';
import {
  Card,
  FormCard,
  GreenButton,
  LabeledTextInput
 } from '../../components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import _ from 'lodash';
import {
  formEnhancer,
  entityMgrConnect
} from './../../services';

const style = {
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 730px)': {
      flexDirection: 'column'
    }
  },
  col: {
    width: 'calc(50% - 20px)',
    '@media (max-width: 730px)': {
      width: '100%'
    }
  },
  text: {
    font: '14px ProximaNova-Regular',
    lineHeight: 1.5,
    '@media (max-width: 730px)': {
      marginBottom: 20,
    }
  },
  formInput: {
    marginBottom: 10,
  },
  submit: {
    marginTop: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  title: {
    font: '22px ProximaNova-SemiBold',
    marginBottom: 20,
  }
}

class InviteCampaignManagerForm extends Component {

  componentWillReceiveProps(nextProps) {
    if (_.get(nextProps, 'entityMgr.id') !== _.get(this.props,'record.entity_manager_id')) {
      this.props.change('entity_manager_id', _.get(nextProps, 'entityMgr.entity_manager_id'));
      this.props.change('entity_id', _.get(nextProps, 'entityMgr.entity_id'));
    }
  }

  submit(values, action, props) {
    props.save(values, `/campaigns`);
  }


  render() {
    return (
      <div style={{minWidth:335}}>
        <Card content={style.card}>
          <div style={style.col}>
          <div style={style.title}>Invite Campaign Manager via Email</div>
            <div style={style.text}>Invite an individual to manage a campaign. Campaign Manager's are able to create a campaign site, build an in-app portal, and create & manage fundraisers.</div>
          </div>
          <div style={style.col}>
            <FormCard>
              <form onSubmit={this.props.handleSubmit(this.submit)}>
                <Field
                  component={LabeledTextInput}
                  label="First Name"
                  name="entity_manager.first_name"
                  required
                />
                <Field
                  component={LabeledTextInput}
                  label="Last Name"
                  name="entity_manager.last_name"
                  required
                />
                <Field
                  component={LabeledTextInput}
                  label="Valid Email Address"
                  name="entity_manager.email"
                  type="email"
                  required
                />
                <GreenButton type="submit" label="Invite Campaign Manager" small style={style.submit}/>
              </form>
            </FormCard>
          </div>
        </Card>
      </div>
    )
  }
}

const InviteCampaignManager = formEnhancer({
  FormComponent: InviteCampaignManagerForm,
  formName: 'invite-component'
});



class CampaignCreate extends React.Component {
  render() {
    return (
      <div>
        <Create {...this.props}>
          <InviteCampaignManager
            {...this.props}
            entityMgr={_.get(this.props, 'entityMgr') || {}}
          />
        </Create>
      </div>
    );
  }
}

export default entityMgrConnect(CampaignCreate);
