import React, { Component } from 'react';
import { Field, submit } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link} from 'react-router-dom';
import {
  Card,
  Edit,
  CampaignOverview,
  FundraiserList,
  CampaignInAppPortal
 } from '../../components';
import {
  List,
  RefreshButton,
  Filter,
  TextInput,
  Show
} from 'react-admin';
import {
  formEnhancer,
  entityMgrConnect
} from './../../services';

const style = {
  tabs: {
    width: '100%',
    background: '#FFF',
    boxShadow: `2px 2px 6px rgba(0,0,0,0.2)`,
    borderRadius: 4,
    marginBottom: 20,
    marginTop: 15,
  },
  container: {
    height: 60,
  },
  selected: {
    background: 'rgba(35,191,184,0.1)',
  },
  indicator: {
    background: '#23BFB8',
  },
  label: {
    fontSize: 16,
    fontFamily: 'ProximaNova-SemiBold',
    color: '#464D5E',
    letterSpacing: 1,
    textTransform: 'none',
    '@media (max-width: 640px)': {
      fontSize: 12,
      letterSpacing: 0,
    }
  },
  list: {
    flexDirection: 'column',
  },
  back: {
    position: 'relative',
    marginBottom: 30,
    fontSize: 14,
    fontFamily: 'ProximaNova-SemiBold',
    textDecoration: 'none',
    color: '#FF6200',
  }
}

const SubentityFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="where.templating.name.like" alwaysOn />
  </Filter>
);

const PostActions = ({ resource, filters, displayedFilters, filterValues, basePath, showFilter }) => (
  <CardActions>
    {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
    <RefreshButton />
  </CardActions>
);

class CampaignEdit extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  submitLogoRequired = () => {
    this.setState({ value: 0 });
    //wait for this CampaignOverview to render the form for remote submit
    setTimeout(() => {
      this.props.dispatch(submit('edit-campaign'));
    }, 500);
  }

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    const disabled = false

    return (
      <div>
        <Link style={style.back} to="/campaigns">Back to Campaign List</Link>
        <div className={classes.tabs}>
          <Tabs
            {...this.props}
            variant="fullWidth"
            value={value}
            onChange={this.handleChange}
            classes={{
              indicator: classes.indicator,
              flexContainer: classes.container
            }}
          >
            <Tab label="Campaign Overview" classes={{selected: classes.selected, label:classes.label}}/>
            <Tab label="Edit In-App Campaign Portal" classes={{selected: classes.selected, label:classes.label}} disabled={disabled}/>
            <Tab label="Fundraisers" classes={{selected: classes.selected, label:classes.label}} disabled={disabled}/>
          </Tabs>
        </div>
        {value === 0 &&
          <CampaignOverview id={this.props.id} entityMgr={this.props.entityMgr}/>
        }
        {value === 1 &&
          <Edit {...this.props} >
            <CampaignInAppPortal submitLogoRequired={this.submitLogoRequired} />
          </Edit>
        }
        {value === 2 &&
          <div style={{minWidth: 495}}>
            <List
              actions={<PostActions />}
              bulkActionButtons={false}
              {...this.props}
              resource="fundraisers"
              filter={{where: {subentity_id: this.props.id}}}
              classes={{root: classes.list}}
            >
              <FundraiserList {...this.props}/>
            </List>
          </div>
        }
      </div>
    );
  }
}

export default entityMgrConnect(withStyles(style)(CampaignEdit));
