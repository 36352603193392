import React from 'react';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const style = {
  error: {
    color: 'red',
    fontSize: 14,
    marginLeft: 40,
  },
  checkboxErr: {
    fill: 'rgb(255, 0, 0)'
  }
}

const RadioInput = ({
  meta: { touched, error } = {}, 
  input: { ...inputProps } = {}, 
  ...props
}) => {
  return (
    <div>
      <Radio
        {...props}
        onClick={inputProps.onClick}
        style={{color: "#23bfb8"}}
      />
      {touched && error && <div style={style.error}>{error}</div>}
    </div>
  )
}

export default RadioInput;
