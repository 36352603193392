import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import CreateIcon from '@material-ui/icons/Create';

const style = {
  leftIcon: {
    marginRight: 6,
  },
  button: {
    '@media (max-width: 640px)': {
      fontSize: 14,
    }
  }
};

const ListManage = ({ basePath = '', record = {}, resource = '' }) => {
  const path = resource ? '/'+resource : basePath

  return (
    <Button
        component={Link}
        to={`${path}/${record.id}`}
        color="primary"
        style={style.button}
        >
      <CreateIcon style={style.leftIcon} />
      Details
    </Button>
  )
};

ListManage.propTypes = {
    basePath: PropTypes.string,
    record: PropTypes.object,
};

export default Radium(ListManage);
