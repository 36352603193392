import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { crudGetOne, crudUpdate } from './../../aor-loopback/dataActions';
import _ from 'lodash';
import {
  Card,
  GreenButton,
  Edit,
  CauseSiteEdit,
  StandardModal,
} from './../../components';
import {
  entityMgrConnect,
  formEnhancer,
} from './../../services';

const style = {
  headerRoot: {
    width: '100%',
    maxWidth: 1200,
    marginBottom: 10,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 18,
    fontFamily: 'ProximaNova-SemiBold',
  },
  footerSave: {
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    maxWidth: 1200,
  },
}

class CauseSite extends Component {

  submit(values, action, props) {
    props.entityMgr.entity.templating = props.record.templating;
    props.save({
      id: props.record.id,
      templating: props.record.templating
    }, `/entities`);
  }

  render() {
    const entityMgr = this.props.entityMgr || {};
    const { classes } = this.props;

    return (
      <div>
        <Card root={style.headerRoot} content={style.header}>
        <div style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
          Edit Cause Page
          <StandardModal
            img="//cdn.startupmedia.com/greenback/misc/imgs/cause-page-demo.gif"
            header="Cause Page"
            subHeader="The Cause Page is an important feature that is attached to every greenback site you create. Displaying key messaging to potential donors arriving at sites the Cause Page helps turn potential donors into donors."
            title1="Where is it located?"
            caption1={["The Cause Page is located under the menu item \"My Cause\" in the menu at the top of the Registered Cause Site, and all Campaign, Fundraiser, and Booster Sites."]}
            title2="Who can see it?"
            caption2="The Cause Page can be seen by anyone who visits your Registered Cause Site, Campaign, Fundraiser, or Booster Sites."
            title3="Why is this important?"
            caption3="Depending on how potential donors arrive at your sites, the Cause Page allows them to learn more about your Cause."
            />
          </div>
          <GreenButton
            label="Save"
            small="true"
            onClick={this.props.handleSubmit(this.submit)}
            className="save"
          />
        </Card>
        <CauseSiteEdit {...this.props}/>
        <Card root={{marginTop: 20, maxWidth: 1200,}}>
          <GreenButton
            label="Save Cause Page"
            small={true}
            onClick={this.props.handleSubmit(this.submit)}
            style={style.footerSave}

          />
        </Card>
      </div>
    )
  }
}

const CauseSiteEnhanced = formEnhancer({
  FormComponent: CauseSite,
  formName: 'edit-cause-site'
});

const EntityEditByOwner = (props, classes) => {
  const entityMgr = _.get(props, 'entityMgr') || {}
  return (
    <Edit
      {...props}
      crudGetOne={crudGetOne}
      crudUpdate={crudUpdate}
      resource="entities"
      id={entityMgr.entity_id}
      title="&nbsp;"
      basePath="/"
      undoable={false}
    >
    <CauseSiteEnhanced {...props} />
  </Edit>
  )
}
export default entityMgrConnect(withStyles(style)(EntityEditByOwner));
