import React from 'react';
import { connect } from 'react-redux';
import {
  Datagrid,
  Filter,
  List,
  TextInput,
  CreateButton,
  RefreshButton,
  NumberField
} from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import {
  ListManage,
  ListAvatar,
  ListName,
  ListStatus,
  Widget,
  FundsRaised,
  InvitesSent,
  TotalBoosters,
  Card
} from './../../components';
import { grid } from './../../style';
import _ from 'lodash';
import { entityMgrConnect } from './../../services'

const style= {
  number: {
    fontSize: 72,
    fontFamily: 'ProximaNova-SemiBold',
    color: '#2CBBB7',
    textAlign: 'center',
  },
  list: {
    minWidth: 495
  },
  spacing:{
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    minWidth: 500,
  },
  title: {
    marginBottom: 20,
    fontSize: 18,
    fontFamily: 'ProximaNova-SemiBold',
  }
}

const SubentityFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="where.templating.name.like" alwaysOn />
  </Filter>
);


const PostActions = ({ entityMgr, resource, filters, displayedFilters, filterValues, basePath, showFilter }) => (
  <CardActions>
    {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
    {_.get(entityMgr, 'realm') === 'unit' && (<CreateButton basePath={basePath} label="Invite Booster"/>)}
    <RefreshButton />
  </CardActions>
);

const FundraiserList = (props, record) => {
  const string = 'templating.name'
  return (
    <div>
      <Card root={style.title}>Boosters</Card>
      {_.get(props.entityMgr, 'realm') === 'unit' && (
        <div style={style.spacing}>
          <div style={grid.row}>
            <div style={grid.col33}>
              <Widget
                icon="monetization_on"
                title="Funds Raised"
              >
                <FundsRaised record={_.get(props.entityMgr, 'subentity')} />
              </Widget>
            </div>
            <div style={grid.col33}>
              <Widget
                icon="email"
                title="Total Invites Sent"
              >
                <InvitesSent entityMgr={props.entityMgr} />
              </Widget>
            </div>
            <div style={grid.col33}>
              <Widget
                icon="supervised_user_circle"
                title="Total Boosters"
              >
                <TotalBoosters entityMgr={props.entityMgr} />
              </Widget>
            </div>
          </div>
        </div>
      )}
<div style={style.list}>
      <List {...props}
        actions={<PostActions entityMgr={props.entityMgr}/>}
        sort={{ field: 'templating.name', order: 'ASC' }}
        perPage={10}
        bulkActionButtons={false}
        title="Boosters"
        filters={<SubentityFilter />}
      >
          <Datagrid bodyOptions={{ stripedRows: true, showRowHover: true, minWidth: 450 }}>
            <ListStatus style={{width:20}} label="Active" source="templating.fundraising" />
            <ListAvatar source="templating.logo" label=""/>
            <ListName source="templating.name" maxLength={25} label="Name"/>
            <NumberField source="invitations_sent" label="Invites Sent" />
            <NumberField source="earnings" options={{ style: 'currency', currency: 'USD' }} label="Funds Raised"/>
            <ListManage />
          </Datagrid>
      </List>
      </div>
    </div>
  )
}

export default entityMgrConnect(FundraiserList);
