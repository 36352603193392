import React, {Component} from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import config from '../../../config/config.provider';
import { Field, FieldArray } from 'redux-form';
import AutoScale from 'react-auto-scale';
import _ from 'lodash';
import {style} from './style.js';
import { required } from './../../../validators';
import {
  Card,
  FormCard,
  GreenButton,
  Edit,
  LabeledTextInput,
  CauseSitePreview,
  ImageInput,
  ResizableTextarea,
  IconPicker,
  TourWizardRichMedia,
} from './../../../components';

class RenderServices extends Component {

   addService() {
    this.props.fields.push({});
  }
   removeService(index) {
    this.props.fields.remove(index);
  }

   componentWillReceiveProps(nextProps) {
    if (_.get(nextProps, 'fields.length', 0) === 0) {
      this.addService();
      this.addService();
    }
  }

   render() {
    const { fields, meta: { touched, error, submitFailed } } = this.props;
    const addService = this.addService.bind(this);
    const removeService = this.removeService.bind(this);

    return (
      <div>
        <div style={style.services}>
          {fields.map((service, index) => (
            <div key={`service_${index}`} style={style.service}>
              <div style={style.serviceIconWrapper}>
                <Field
                  component={IconPicker}
                  name={`${service}.icon`}
                  iconButton={style.chooseIcon}
                  validate={required}
                  iconSelected={style.iconSelected}
                  iconSize={48}
                />
              </div>
              <div style={style.serviceContent}>
                <Field
                  name={`${service}.title`}
                  component={LabeledTextInput}
                  label={`Title`}
                  placeholder="Informative Section"
                  validate={required}
                  maxLength="50"
                  multiline
                  shrink={true}
                  customStyle={{
                    fontSize: 18,
                    fontFamily: 'ProximaNova-SemiBold',
                    color: '#4A4A4A'
                  }}
                />
                <Field
                  name={`${service}.description`}
                  component={LabeledTextInput}
                  label="Caption*"
                  validate={required}
                  maxLength="200"
                  multiline
                  placeholder="In these sections pick an icon, add a title, and a caption. Write about your mission, events, services, or any other relevant information you would like to share with donors."
                  shrink={true}
                  rows="4"
                  customStyle={{
                    fontSize: 14,
                    lineHeight: 1.5,
                    color: '#4A4A4A'
                  }}
                />
              {index >= 1 ? <button type="button" style={style.remBtn} onClick={() => removeService(index)}>Remove Section</button> : ''}
              </div>
            </div>
          ))}
        </div>
        {fields.length <= 5 && (<button type="button" style={style.addBtn} onClick={addService}><Icon style={style.addIcon}>add_circle_outline</Icon> <span style={style.addTxt}>Add Section</span></button>)}
        {(touched || submitFailed) && error && <span>{error}</span>}
      </div>
    );
  }
}

class CauseSiteEdit extends Component {
  state = {
    steps: [
      {
        content: (
          <>
              <img style={{maxWidth: 500, borderRadius: '8px', paddingBottom: 15}} src='//cdn.startupmedia.com/greenback/misc/imgs/cause-page-demo.gif'/>
              <div style={{fontSize: 22, paddingBottom: 15}}>
              Cause Page
              </div>
              <div style={{fontSize: 16}}>
              The Cause Page is included with your Registered Cause Site and every Campaign, Fundraiser, and Booster Site. It is intended to provide potential donors visiting one of your greenback sites additional information about your charity when they select the "My Cause" link on the menu bar.
              </div>
          </>
        ),
        target: '.appbar',
        disableBeacon: true,
        isFixed: true,
        placement: 'center',
      }
    ]
  };

  render() {
    const entityMgr = this.props.entityMgr || {};
    const { value, steps } = this.state;
    const joyrideTrigger = !_.get(entityMgr, 'entity.templating.cobrand', '') ? true : false;
    return (
      <div>
        <TourWizardRichMedia
          continuous={true}
          steps={steps}
          run={joyrideTrigger}
          disableOverlay={false}

        />
        <form style={style.form} id="cobrand">
          <Card content={{padding:0}} >
            <AutoScale>
              <div style={style.cobrandScaled}>
                <div style={style.cobrand} id="cobrand">

                  <div style={style.header} className="header">
                    <div style={style.gradient} />
                    <Field
                      {...this.props}
                      component={ImageInput}
                      name={`templating.cobrand.mycause.img`}
                      type="cobrandHeader"
                      defaultValue="//cloudfront.startupmedia.com/greenback/mgmtportal/cobrand-header-upload-background.jpg"
                      ratio={2.36/1}
                      variant="square"
                      validate={required}
                      size={1200}
                    />
                    <div style={style.headerContent}>
                      <img style={style.logo} src={this.props.record.templating.logo} />
                      <Field
                        component={ResizableTextarea}
                        name={`templating.cobrand.mycause.head-title`}
                        placeholder="Welcome to your Cause Page!"
                        maxLength="50"
                        validate={required}
                        style={style.headerPhrase}
                        customStyle={{
                          font: '33px ProximaNova-Regular',
                          color: '#4A4A4A'
                        }}
                      />
                      <Field
                        component={ResizableTextarea}
                        name={`templating.cobrand.mycause.head-caption`}
                        placeholder="Use this page to share pictures, and information about your organization. Start here by adding a title & short description about your organization."
                        maxLength="200"
                        rows="4"
                        validate={required}
                        style={style.headerCaption}
                        customStyle={{
                          font: '22px ProximaNova-Light',
                          color: '#4A4A4A',
                          lineHeight: 1.5,
                        }}
                      />
                    </div>
                  </div>

                  <div style={style.content} className="services">
                    <Field
                      component={ResizableTextarea}
                      name={`templating.cobrand.mycause.service-title`}
                      placeholder="Use this section to share key facts with donors!"
                      maxLength="50"
                      style={style.serviceTitle}
                      validate={required}
                      centered
                      customStyle={{
                        font: '36px ProximaNova-Light',
                        textAlign: 'justify',
                        '-moz-text-align-last': 'center',
                        textAlignLast: 'center',
                        color: '#4A4A4A'
                      }}
                    />
                    <Field
                      component={ResizableTextarea}
                      name={`templating.cobrand.mycause.service-caption`}
                      placeholder="Use this section to share key facts with donors! (optional)"
                      maxLength="200"
                      style={style.serviceCaption}
                      centered
                      customStyle={{
                        font: '18px ProximaNova-Light',
                        textAlign: 'justify',
                        '-moz-text-align-last': 'center',
                        textAlignLast: 'center',
                        color: '#4A4A4A'
                      }}
                    />

                    <FieldArray name='templating.cobrand.services' component={RenderServices} />
                  </div>

                  <div style={style.section} className="footer">
                    <div style={style.sectionItem}>
                      <Field
                        {...this.props}
                        component={ImageInput}
                        name={`templating.cobrand.mycause.circle-section-top-img`}
                        validate={required}
                        type="cobrandSectionTop"
                        defaultValue="//cloudfront.startupmedia.com/greenback/mgmtportal/cobrand-section-upload-background.jpg"
                        ratio={2.5/1}
                        variant="square"
                        size={1200}
                      />
                    </div>
                    <div style={style.sectionCircle}>
                      <div style={style.sectionCircleText}>Working together with greenback, <b>{_.get(this.props, 'record.gb_cause.name')}</b>, members, and providers unite to 'bring benefit to everyone' while furthering the cause.
                      </div>
                    </div>
                    <div style={style.sectionItem}>
                      <Field
                        {...this.props}
                        component={ImageInput}
                        validate={required}
                        name={`templating.cobrand.mycause.circle-section-bottom-img`}
                        type="cobrandSectionBottom"
                        defaultValue="//cloudfront.startupmedia.com/greenback/mgmtportal/cobrand-section-upload-background.jpg"
                        ratio={2.5/1}
                        variant="square"
                        size={1200}
                      />
                    </div>
                  </div>

                </div>
              </div>
            </AutoScale>
          </Card>
        </form>
      </div>
    )
  }
}

export default CauseSiteEdit;
