import React, { Component } from 'react';
import Radium from 'radium';
import {
  DynamicComponents,
} from '../components';
import loopbackRestClient from './../aor-loopback';
import config from './../config/config.provider';
import { getStore } from './../services/createReduxStore';
import { Provider } from 'react-redux';
import _ from 'lodash';

const apiClient = loopbackRestClient(config);
const store = getStore();

function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

class Portal extends Component {

  state = {
    entity: {},
    loading: true,
  }

  decorateEntity({entity, subentity_id, subscriber_id}) {
    if (subentity_id) {
      entity.subentity = _.values(_.pickBy(entity.templating.subentities, (s) => s.type == 'subentity')).pop();
    }
    else if (subscriber_id && entity.subentity) {
      // on this attempt try use the parent
      if (!_.get(entity, 'subentity.portal')) 
        entity.subentity = entity.subentity.parent;
      // on this attempt try to fetch by the grandpatent id
      if (!_.get(entity, 'subentity.portal'))
        entity.subentity = entity.templating.subentities[entity.subentity.subentity_id];
      // when attempt have exhausted default to the entity portal
      if (!_.get(entity, 'subentity.portal'))
        delete entity.subentity;  
    }
    this.setState({entity, loading: false});
  }

  async componentDidMount() {
    const entity_id = getUrlParameter("entity_id");
    const subentity_id = getUrlParameter("subentity_id");
    const subscriber_id = getUrlParameter("subscriber_id");
    
    if(!entity_id && !subscriber_id) return;

    if(subscriber_id) {
      const entity_type = getUrlParameter("entity_type");
      if(!entity_type) return;
      apiClient("WITH_ROUTE", "subscriptions", {
        route: `getCauseWithSubentity?subscriber_id=${subscriber_id}&entity_type=${entity_type}`,
        method: "GET"
      }).then(({response}) => {
        this.decorateEntity({
          entity: response.json,
          subscriber_id
        });
        this.setState({entity: response.json, loading: false});
      }).catch((err) => {
        console.error(err.message);
        this.setState({entity: {}, loading: false});
      });
    }
    else {
      apiClient("WITH_ROUTE", "entities", {
        route: `${entity_id}/getWithSubentities`,
        data: {
          args: JSON.stringify({subentity_id})
        },
        method: "GET"
      }).then(({response}) => {
        this.decorateEntity({
          entity: response.json,
          subentity_id
        });
      }).catch((err) => {
        console.error(err.message);
        this.setState({entity: {}, loading: false});
      });
    }
  }

  render () {
    const { entity } = this.state;
    const { subentity } = entity;
    const record = (subentity) ? {templating: subentity} : entity;
    console.log(record);
    return (
      <Provider store={store}>
        <div style={{background: '#FFF', overflow:'hidden'}}>
          <DynamicComponents type="field" record={record}/>
        </div>
      </Provider>
    );
  }
}

export default Portal;
