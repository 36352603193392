import React from 'react';
import { connect } from 'react-redux';

let style = {
  root: {
    position: 'relative',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    color: '#ADB1BA',
    fontSize: 12,
    fontFamily: 'ProximaNova-SemiBold',
    marginTop: 15,
    cursor: 'pointer',
    background: 'transparent',
    border: 'none',
    outline: 'none',
    display: 'block',
  }
}

class BackButton extends React.Component {

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.props.previousStep();
  }

  render() {
    const {
      label
    } = this.props;
    return (
      <button
        style={style.root}
        onClick={this.onClick}
        type="button"
      >
        {label || 'Back'}
      </button>
    );
  }
}

export default connect(undefined, {
})(BackButton);
