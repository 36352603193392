import loopbackRestClient from './../../aor-loopback';
import config from './../../config/config.provider';
import pMemoize from './../pMemoize';
const apiClient = loopbackRestClient(config);

const clientCall = pMemoize(apiClient, { maxAge: 60000 });

export default (args) => new Promise((resolve, reject) => {
  const { endpoint, route, data, method } = args;
  clientCall("WITH_ROUTE", endpoint, {
    route,
    data,
    method: method || "GET"
  })
  .then(({response}) => {
    console.log(args);
    if (response.status === 200) {
      resolve(JSON.parse(response.body));
    }
    else {
      reject(new Error(response.status));
    }
  }).catch((err) => {
    console.error(err);
    reject(new Error(err));
  });
});
