import React, {Component} from 'react';
import Radium from 'radium';
import { Field } from 'redux-form';
import config from './../../config/config.provider';
import { crudGetOne, crudUpdate } from './../../aor-loopback/dataActions';
import moment from 'moment';
import AutoScale from 'react-auto-scale';

import {
  Card,
  GreenButton,
  FormCard,
  ImageInput,
  LabeledTextInput,
  LabeledNumberInput,
  LabeledDateInput,
  CobrandFundraiser,
  Edit,
  TourWizard
} from './../';
import {
  entityMgrConnect,
  getFromNestedSafely as gS,
  formEnhancer,
  subscribePreviewToContentHeight,
  cobrandLink
} from './../../services';
import { required } from './../../validators';

const style = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    font: '18px ProximaNova-SemiBold',
  },
  content: {
    width: '100%',
    display: 'flex',
    marginTop: 20,
    '@media (max-width: 640px)': {
      display: 'block',
    }
  },
  formCol: {
    maxWidth: 450,
    width: '100%',
    '@media (max-width: 640px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  views: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  viewBtn: {
    fontSize: 14,
    cursor: 'pointer',
    fontFamily: 'ProximaNova-SemiBold',
  },
  viewBtnDisabled: {
    color: '#23BFB8'
  },
  input: {
    marginBottom: 20,
  },
  footerSave: {
    marginTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  previewCol: {
    width: '100%',
    marginLeft: 20,
    '@media (max-width: 640px)': {
      display: 'none',
    }
  },
  preview: {
    marginTop: 20,
    width: '100%',
    overflow: 'hidden',

  },
  previewScaled: {
    width: 1200,
  },
  title: {
    font: '18px ProximaNova-SemiBold',
    textAlign: 'center',
  }
}

class FundraiserPageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      source: 'templating',
      disabled: false,
      run: false,
      steps: [
      ]
    }
  }

  componentDidMount() {
    subscribePreviewToContentHeight();
  }

  submit = (values, action, props) => {
    props.save({
      templating: props.record.templating,
      subentity_id: props.record.subentity_id,
      entity_id: props.record.entity_id,
    }, `/fundraisers/${props.record.id}`);
  }

  render() {
    const entityMgr = gS(this.props, 'entityMgr');
    const endDate = moment(gS(this.props, `${this.state.source}.fundraising.end_date`)).format('YYYY-MM-DD');
    const { steps } = this.state;
    const disabledByFundraiserSite = !gS(entityMgr, 'subentity.templating.logo') ? true : false;
    let joyrideTrigger;
    if (gS(entityMgr, 'realm') === "unit") {
      joyrideTrigger = !gS(entityMgr, 'subentity.templating.logo', '') ? true : false;
    } else {
      joyrideTrigger = false
    }

    console.log(joyrideTrigger)


    return (
      <div>
        <TourWizard
          steps={steps}
          continuous={true}
          run={joyrideTrigger}
          disableOverlay={true}
        />
        <form>
          <Card>
            <div style={style.header}>
              Edit Fundraiser Site
              <GreenButton
                label="Save"
                small="true"
                onClick={this.props.handleSubmit(this.submit)}
              />
            </div>
          </Card>
          <div style={style.content} id="content">
            <div style={style.formCol}>
              <Card className="form">
                <FormCard>
                  <Field
                    {...this.props}
                    component={ImageInput}
                    name={`${this.state.source}.logo`}
                    label="Fundraiser Image"
                    ratio={1/1}
                    variant="circle"
                    ratio={1/1}
                    style={style.input}
                    validate={required}
                    size={300}
                  />
                  <Field
                    {...this.props}
                    name={`${this.state.source}.name`}
                    component={LabeledTextInput}
                    label="Fundraiser Title*"
                    validate={required}
                    maxLength="50"
                    style={style.input}
                  />
                  <Field
                    {...this.props}
                    name={`${this.state.source}.signature`}
                    component={LabeledTextInput}
                    label="Your Name (optional)"
                    maxLength="50"
                    style={style.input}
                  />
                  <Field
                    {...this.props}
                    name={`${this.state.source}.fundraising.goal`}
                    component={LabeledNumberInput}
                    label="Fundraiser Goal*"
                    type="number"
                    step="1"
                    validate={required}
                    style={style.input}
                    tip="Set a goal you would like to reach for your fundraiser. Donors will see how much you are trying to raise on your Fundraiser Site!"
                    className="goal"
                    onInput={(e) =>{
                      e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,6)
                    }}
                  />
                  <Field
                    {...this.props}
                    name={`${this.state.source}.fundraising.end_date`}
                    component={LabeledDateInput}
                    source={this.state.source}
                    label="Fundraiser End Date*"
                    validate={required}
                    tip="Select a date for your Fundraiser to end. Once your Fundraiser reaches the end date donors will still be able to donate. We suggest picking a date 30 days from the time you plan to start the Fundraiser."
                    style={style.input}
                    className="end_date"
                  />
                  <Field
                    {...this.props}
                    name={`${this.state.source}.fundraising.short_description`}
                    component={LabeledTextInput}
                    label="How Funds Are Used*"
                    validate={required}
                    maxLength="375"
                    multiline
                    tip="Explain how the donations will be used to benefit your organization. The most successful fundraisers fill out this section entirely."
                    style={style.input}
                    className="short_description"
                  />
                  <Field
                    {...this.props}
                    name={`${this.state.source}.fundraising.long_description`}
                    component={LabeledTextInput}
                    label="Special Message*"
                    validate={required}
                    maxLength="720"
                    multiline
                    tip="Convey a special message to your donors! Such as a thank you note, or more information about your organization!"
                    style={style.input}
                    className="long_description"
                  />
                </FormCard>
                <GreenButton
                  style={style.footerSave}
                  label="Save"
                  small={true}
                  onClick={this.props.handleSubmit(this.submit)}
                  className="save"
                />
              </Card>
            </div>
            <div style={style.previewCol} id="preview">
              <Card content={style.title}>
                Fundraiser Site Preview
              </Card>
              <Card root={style.preview} content={{padding:0}} className="preview">
                <AutoScale>
                  <div style={style.previewScaled}>
                    <CobrandFundraiser subentity={this.props.record} source={this.state.source}/>
                  </div>
                </AutoScale>
              </Card>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const EnhancedFundraiserPage = formEnhancer({
  FormComponent: Radium(FundraiserPageForm),
  formName: 'edit-portal'
});

const FundraiserPage = (props) => {
  const entityMgr = gS(props, 'entityMgr');
  return (
    <Edit
      {...props}
      crudGetOne={crudGetOne}
      crudUpdate={crudUpdate}
      resource="fundraisers"
      id={gS(entityMgr, 'realm') === 'unit' ? gS(entityMgr, 'subentity_id') : gS(props, 'id')}
      basePath="/"
      undoable={false}
    >
    <EnhancedFundraiserPage entityMgr={entityMgr}/>
  </Edit>
  )
}

export default FundraiserPage;
