import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { IconButtons } from '../';

export const style = {
  wrapper: {
    position: 'relative',
  },
  header: {
    font: '22px ProximaNova-SemiBold',
    paddingBottom: 20,
    textAlign: 'center',
    lineHeight: '140%'
  },
  subHeader: {
    font: '16px ProximaNova-Regular',
    paddingBottom: 20,
    textAlign: 'center',
    maxWidth: '1000px',
    width: '80%',
    margin: 'auto',
    lineHeight: '140%'

  },
  subSections: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
  },
  sectionBox: {
    padding: 10,
    margin: '18px 9px 0px 9px',
    backgroundColor: '#f0f8ff',
    borderRadius: '8px',
    boxShadow: '0px 0px 8px #AAAAAA',
    height: 'auto',
    width: 'calc(100%/3 - 40px)',
    minWidth: '200px',
    maxWidth: '300px'
  },
  btnSection: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: 'auto',
    width: 300,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    font: '18px ProximaNova-SemiBold',
    lineHeight: '140%'
  },
  content: {
    font: '16px ProximaNova-Regular',
    lineHeight: '140%'
  },
  imgContainer: {
    textAlign: 'center',
    margin: 20,
  },
  img: {
    borderRadius: 8,
    boxShadow: '0px 0px 8px #AAAAAA',
    maxHeight: 300,
    maxWidth: '100%',

  },
  btns: {
    marginTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  btn: {
    display: 'inline-block',
    marginLeft: '10px',
    marginRight: '10px',
  },
}

export const customStyles = {
  content : {
    maxHeight: '80vh',
    width: '80vw',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-30%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
};

const StandardModal = (props) => {

  const [modalIsOpen,setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal(){
    setIsOpen(false);
  }

  return (
      <div style={{paddingLeft: 10}}>
        <IconButtons
          onClick={openModal}
          icon='help'
          ></IconButtons>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
          ariaHideApp={false}
        >
        <div style={style.wrapper}>
          <div style={style.imgContainer}>
            <img style={style.img} src={props.img}/>
          </div>
          <div style={style.header}>
            {props.header}
          </div>
            <div style={style.subHeader}>
              {props.subHeader}
            </div>
          <div style={style.subSections}>
            {props.title1 && (
            <div style={style.sectionBox}>
              <div style={style.section}>
                <div style={style.title}>
                  {props.title1}
                  </div>
                <div style={style.content}>
                  {props.caption1}
                </div>
              </div>
            </div>)}
            {props.title2 && (
              <div style={style.sectionBox}>
              <div style={style.section}>
              <div style={style.title}>
                {props.title2}
              </div>
              <div style={style.content}>
                {props.caption2}
              </div>
            </div>
          </div>)}
            {props.title3 && (
              <div style={style.sectionBox}>
              <div style={style.section}>
              <div style={style.title}>
                {props.title3}
              </div>
              <div style={style.content}>
                {props.caption3}
              </div>
            </div>
          </div>)}
          </div>
        </div>
        </Modal>
      </div>
    );
}

export default StandardModal
