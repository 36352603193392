import React, {Component} from 'react';
import { MenuItem, NestedItems } from './';
import { getFromNestedSafely as gS, cobrandLink } from './../../../services';


class CampaignMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: '',
    }
  }

  openNested = e => {
    this.setState({ [e]: !this.state[e] });
  }

  setRouteFromState() {
    const active =  (this.props.location && this.props.location.pathname) || window.location.pathname;
    this.setState({active: active});
  }

  componentDidMount() {
    this.setRouteFromState();
  }

  setActive(to) {
    this.setState({ active: to });
  }

  // <MenuItem
  //   {...this.props}
  //   active={this.state.active}
  //   icon="edit"
  //   name="Edit"
  //   to="/portal/edit"
  //   setActive={this.setActive.bind(this, '/portal/edit')}
  //   nested="true"
  // />
  render() {
    const disabled = gS(this.props, 'entityMgr.subentity.templating.logo') ? false : true;
    const disabledByPortal = gS(this.props, 'entityMgr.subentity.templating.portal') ? 0 : 1;

    return (
      <div>
        <MenuItem
          {...this.props}
          active={this.state.active}
          icon="home"
          name="Dashboard"
          href="/"
          target="_self"
          setActive={this.setActive.bind(this, '/')}
        />

        <NestedItems
          {...this.props}
          in={this.state['Campaign Site']}
          name="Campaign Site"
          icon="computer"
          openNested={this.openNested.bind(this, 'Campaign Site')}
        >
          <MenuItem
            {...this.props}
            active={this.state.active}
            icon="edit"
            name="Edit"
            to="/campaigns"
            setActive={this.setActive.bind(this, '/campaigns')}
            nested="true"
          />
          <MenuItem
            {...this.props}
            active={this.state.active}
            icon="open_in_new"
            name="View"
            href={cobrandLink({entity: gS(this.props, 'entityMgr.entity.cause'), slug: gS(this.props, 'entityMgr.subentity.slug')})}
            nested="true"
            disabled={disabled}
          />
        </NestedItems>

        <NestedItems
          {...this.props}
          in={this.state['In-App Portal']}
          name="Campaign Portal"
          icon="phone_iphone"
          openNested={this.openNested.bind(this, 'In-App Portal')}
          disabled={disabled}
        >
          <MenuItem
            {...this.props}
            active={this.state.active}
            icon="edit"
            name="Edit"
            to="/portal/edit"
            setActive={this.setActive.bind(this, '/portal/edit')}
            nested="true"
          />
        </NestedItems>


        <NestedItems
          {...this.props}
          in={this.state['Fundraisers']}
          name="Fundraisers"
          icon="star"
          openNested={this.openNested.bind(this, 'Fundraisers')}
          disabled={disabledByPortal}
        >
          <MenuItem
            {...this.props}
            active={this.state.active}
            icon="group"
            name="Manage"
            to="/fundraisers"
            setActive={this.setActive.bind(this, '/fundraisers')}
            nested="true"
          />
          <MenuItem
            {...this.props}
            active={this.state.active}
            icon="add_circle"
            name="Create Fundraiser"
            to="/fundraisers/create"
            setActive={this.setActive.bind(this, '/fundraisers/create')}
            nested="true"
          />
        </NestedItems>

        <NestedItems
          {...this.props}
          in={this.state['Boosters']}
          name="Boosters"
          icon="supervised_user_circle"
          openNested={this.openNested.bind(this, 'Boosters')}
          disabled={disabledByPortal}
        >
          <MenuItem
            {...this.props}
            active={this.state.active}
            icon="group"
            name="Manage"
            to="/boosters"
            setActive={this.setActive.bind(this, '/boosters')}
            nested="true"
          />
        </NestedItems>

        <MenuItem
          {...this.props}
          active={this.state.active}
          icon="message"
          name="Donor Invitation System"
          to="/dis"
          setActive={this.setActive.bind(this, '/dis')}
          disabled={disabledByPortal}
        />

        <MenuItem
          {...this.props}
          active={this.state.active}
          icon="help"
          name="FAQ"
          to="/faq"
          setActive={this.setActive.bind(this, '/faq')}
        />
      </div>
    )
  }
}

export default CampaignMenu
