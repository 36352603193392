import React, {Component} from 'react';
import Radium from 'radium';
import { Field } from 'redux-form';
import config from './../../config/config.provider';
import { crudGetOne, crudUpdate } from './../../aor-loopback/dataActions';
import moment from 'moment';
import AutoScale from 'react-auto-scale';
import {
  Card,
  GreenButton,
  FormCard,
  ImageInput,
  LabeledTextInput,
  LabeledNumberInput,
  LabeledDateInput,
  CobrandedHomePreview,
  Edit,
  TourWizard
} from './../../components';
import _ from 'lodash';
import {
  entityMgrConnect,
  getFromNestedSafely as gS,
  formEnhancer,
  cobrandLink
} from './../../services';
import { required } from './../../validators';

const style = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    font: '18px ProximaNova-SemiBold',
  },
  content: {
    width: '100%',
    display: 'flex',
    marginTop: 20,
  },
  formCol: {
    maxWidth: 450,
    width: '100%',
    '@media (max-width: 640px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  views: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  viewBtn: {
    fontSize: 14,
    cursor: 'pointer',
    fontFamily: 'ProximaNova-SemiBold',
  },
  viewBtnDisabled: {
    color: '#23BFB8'
  },
  input: {
    marginBottom: 20,
  },
  footerSave: {
    marginTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  previewCol: {
    width: '100%',
    marginLeft: 20,
    '@media (max-width: 640px)': {
      display: 'none',
    }
  },
  preview: {
    marginTop: 20,
    width: '100%',
    overflow: 'hidden'
  },
  previewScaled: {
    width: 1200,
  },
  title: {
    font: '18px ProximaNova-SemiBold',
    textAlign: 'center'
  }
}

class CampaignPageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: [
        {
          target: '.form',
          title: 'Campaign Site Editor',
          content: 'Easily create your Campaign Site by uploading a logo, and giving your campaign a name!',
          disableBeacon: true,
          isFixed: true,
          placement: 'right-start',
          disableOverlay: false,
          showSkipButton: true,
        },
        {
          target: '.save',
          title: 'Don\'t Forget to Save!',
          content: 'Once you have finished making changes be sure to save your Campaign Site!',
          disableBeacon: true,
          isFixed: true,
          placement: 'bottom',
          disableOverlay: false,
          showSkipButton: true,
        }
      ]
    }
  }

  submit = (values, action, props) => {
    props.entityMgr.subentity.templating = props.record.templating;
    props.save({
      id: props.record.id,
      templating: props.record.templating
    }, `/campaigns`);
  }

  render() {
    const entityMgr = _.get(this.props, 'entityMgr');
    const disabledByCampaignSite = !(_.get(entityMgr,'subentity.templating')) ? true : false
    const { steps } = this.state;
    const joyrideTrigger = !_.get(entityMgr, 'subentity.templating.logo', '') ? true : false;
    return (
      <div>
        <TourWizard
          steps={steps}
          continuous={true}
          run={joyrideTrigger}
        />
        <form onSubmit={e => { e.preventDefault(); }}>
          <Card content={style.header}>
              Edit Campaign Site
              <GreenButton
                label="Save"
                small="true"
                onClick={this.props.handleSubmit(this.submit)}
              />
          </Card>
          <div style={style.content} id="content">
            <div style={style.formCol}>
              <Card className="form">
                <FormCard>
                  <Field
                    {...this.props}
                    component={ImageInput}
                    name="templating.logo"
                    label="Campaign Logo"
                    variant="square"
                    defaultValue="//cloudfront.startupmedia.com/greenback/mgmtportal/circle-upload-background.jpg"
                    style={style.input}
                    size={300}
                  />
                  <Field
                    {...this.props}
                    name="templating.name"
                    component={LabeledTextInput}
                    label="Campaign Name*"
                    validate={required}
                    maxLength="50"
                    style={style.input}
                  />
                </FormCard>
                <GreenButton
                  label="Save"
                  small={true}
                  onClick={this.props.handleSubmit(this.submit)}
                  style={style.footerSave}
                  className="save"
                />
              </Card>
            </div>
            <div style={style.previewCol} id="preview">
              <Card content={style.title}>
                Campaign Site Preview
              </Card>
              <Card root={style.preview} content={{padding:0}} className="preview">
                <AutoScale>
                  <div style={style.previewScaled}>
                    <CobrandedHomePreview subentity={this.props.record} entityMgr={this.props.entityMgr}/>
                  </div>
                </AutoScale>
              </Card>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const EnhancedCampaignPage = formEnhancer({
  FormComponent: Radium(CampaignPageForm),
  formName: 'edit-campaign'
});

const CampaignPage = (props) => {
  const entityMgr = gS(props, 'entityMgr') || {};
  return (
    <Edit
      {...props}
      crudGetOne={crudGetOne}
      crudUpdate={crudUpdate}
      resource="campaigns"
      id={_.get(props, 'entityMgr.subentity_id')}
      basePath="/"
      undoable={false}
    >
    <EnhancedCampaignPage entityMgr={entityMgr}/>
  </Edit>
  )
}

export default entityMgrConnect(CampaignPage);
