import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

const style = {
  root: {
    border: '1px solid #FFFFFF',
    color: '#1E5E75',
    borderRadius: '20px',
    padding: 0,
    '&:hover': {
      border: '1px solid #23bfb8',
      background: 'transparent'
    }
  },
  disabled: {
    opacity: '0.4',
    color: '#FFF',
  },
  icon: {
    fontSize: 22,
  }
}

const IconButtons = ({classes, ...props}) => {
  return (
    <IconButton
      {...props}
      onClick={props.onClick}
      style={style.root}
      classes={{
        disabled: classes.disabled,
      }}
      >
        {props.icon && (<Icon style={style.icon}>{props.icon}</Icon>)}
        {props.label}
      </IconButton>
  )
}

export default withStyles(style)(IconButtons)
