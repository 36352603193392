class HttpError extends Error {
    constructor(message, status, code) {
        super(message);
        this.message = message;
        this.status = status;
        this.code = code;
        this.name = this.constructor.name;
        if (typeof Error.captureStackTrace === 'function') {
          Error.captureStackTrace(this, this.constructor);
          this.stack = new Error().stack;
        } else {
          this.stack = (new Error(message)).stack;
        }
    }
}

export default HttpError;
