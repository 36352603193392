import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, submit } from 'redux-form';
import Radium from 'radium';
import _ from 'lodash';
import {
  Create,
  ReferenceInput,
  SelectInput
} from 'react-admin';
import {
  Card,
  FormCard,
  GreenButton,
  LabeledTextInput,
  ImageInput
 } from '../../components';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  formEnhancer,
  getFromNestedSafely as gS,
  entityMgrConnect
} from './../../services';

const style = {
  tabs: {
    width: '100%',
    background: '#FFF',
    boxShadow: `2px 2px 6px rgba(0,0,0,0.2)`,
    borderRadius: 4,
    marginBottom: 20,
    minWidth: 650
  },
  container: {
    height: 60,
  },
  selected: {
    background: 'rgba(35,191,184,0.1)',
  },
  indicator: {
    background: '#23BFB8',
  },
  label: {
    fontSize: 16,
    fontFamily: 'ProximaNova-SemiBold',
    color: '#464D5E',
    letterSpacing: 1,
    textTransform: 'none',
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 730px)': {
      flexDirection: 'column'
    }
  },
  col: {
    width: 'calc(50% - 20px)',
    '@media (max-width: 730px)': {
      width: '100%'
    }
  },
  text: {
    fontSize: 14,
    fontFamily: 'ProximaNova-Regular',
    lineHeight: 1.5,
    '@media (max-width: 730px)': {
      marginBottom: 20,
    }
  },
  formInput: {
    marginBottom: 10,
  },
  submit: {
    marginTop: 10,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  title: {
    fontSize: 22,
    fontFamily: 'ProximaNova-SemiBold',
    marginBottom: 20,
  }
}

// const CreateFundraiserForm = (props) => {
//
//   return (
//     <Create {...props}>
//       <Card content={style.card}>
//         <div style={style.cardCol}>
//           <FormCard>
//             <form>
//               <div style={style.formInput}>
//                 <Field
//                   {...props}
//                   label="Fundraiser Image"
//                   component={ImageInput}
//                   name="image"
//                 />
//               </div>
//               <div style={style.formInput}>
//                 <Field
//                   component={LabeledTextInput}
//                   label="Fundraiser Name"
//                   required
//                 />
//               </div>
//               <GreenButton type="submit" label="Create Fundraiser" small style={style.submit}/>
//             </form>
//           </FormCard>
//         </div>
//         <div style={style.cardCol}>
//           <div style={style.text}>Create a new fundraiser by inviting an individual to manage it. This individual may be a coach, staff member, volunteer, or anyone else overseeing this fundraiser. The Fundraiser Manager will be able to edit the fundraiser's information, invite & manage Boosters, and share the fundraiser with others.</div>
//         </div>
//       </Card>
//     </Create>
//   )
// }

class InviteFundraiserManagerForm extends Component {

  componentWillReceiveProps(nextProps) {
    if (gS(nextProps, 'entityMgr.id') !== gS(this.props,'record.entity_manager_id')) {
      this.props.change('entity_manager_id', gS(nextProps, 'entityMgr.id'));
      this.props.change('entity_id', gS(nextProps, 'entityMgr.entity_id'));
      this.props.change('subentity_id', gS(nextProps, 'entityMgr.subentity_id'));
    }
  }

  submit(values, action, props) {
    props.dispatch({
      type: 'AOR/USER_UPDATE'
    });
    props.save(values, `/fundraisers`);
  }


  render() {
    return (
      <div>
        <Card>
          <div style={style.card}>
          <div style={style.col}>
          <div style={style.title}>Invite Fundraiser Manager via Email</div>
            <div style={style.text}>Create a new fundraiser by inviting an individual (such as a coach, staff member, or volunteer) to manage it. The Fundraiser Manager will be able to edit the fundraiser's information, invite & manage Boosters, and share the fundraiser with others.</div>
          </div>
          <div style={style.col}>
            <FormCard>
              <form onSubmit={this.props.handleSubmit(this.submit)}>
                <div style={style.formInput}>
                  <Field
                    component={LabeledTextInput}
                    label="First Name"
                    name="entity_manager.first_name"
                    required
                  />
                </div>
                <div style={style.formInput}>
                  <Field
                    component={LabeledTextInput}
                    label="Last Name"
                    name="entity_manager.last_name"
                    required
                  />
                </div>
                <div style={style.formInput}>
                  <Field
                    component={LabeledTextInput}
                    label="Valid Email Address"
                    name="entity_manager.email"
                    type="email"
                    required
                  />
                </div>
                <GreenButton type="submit" label="Invite Fundraiser Manager" small style={style.submit}/>
              </form>
            </FormCard>
          </div>
          </div>
        </Card>
      </div>
    )
  }
}

// const CreateFundraiser = formEnhancer({
//   FormComponent: CreateFundraiserForm,
//   formName: 'create-component'
// });
const InviteFundraiserManager = formEnhancer({
  FormComponent: Radium(InviteFundraiserManagerForm),
  formName: 'invite-component'
});



class FundraiserCreate extends React.Component {

  render() {

    return (
      <div style={{minWidth:335}}>
        <Create {...this.props}>
          <InviteFundraiserManager
            {...this.props}
            entityMgr={gS(this.props, 'entityMgr') || {}}
          />
        </Create>
      </div>
    );
  }
}

export default entityMgrConnect(FundraiserCreate);
