import React from 'react';
import PropTypes from 'prop-types';

function SmsMessageIcon({fill, height}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 40 40">
      <g id="text_ico" transform="translate(0 0)" fill={fill}>
        <path d="M-252.146-1840.064a20,20,0,0,1-19.946-20,19.811,19.811,0,0,1,1.562-7.749,19.856,19.856,0,0,1,4.266-6.339,19.855,19.855,0,0,1,6.338-4.279,19.843,19.843,0,0,1,7.78-1.57,19.846,19.846,0,0,1,7.753,1.562,19.9,19.9,0,0,1,6.341,4.264,19.85,19.85,0,0,1,4.28,6.335,19.812,19.812,0,0,1,1.571,7.776,19.966,19.966,0,0,1-1.562,7.785,19.882,19.882,0,0,1-4.266,6.357,19.828,19.828,0,0,1-6.338,4.286A19.828,19.828,0,0,1-252.146-1840.064Zm1.778-11.614h0l7.877,3.748-.635-5.781a12.451,12.451,0,0,0,4.248-3.787,8.566,8.566,0,0,0,1.533-4.788c0-5.919-6.611-10.735-14.738-10.735s-14.737,4.816-14.737,10.735,6.611,10.735,14.737,10.735a13.646,13.646,0,0,0,1.715-.127Zm6.733,2.1h0l-6.1-3.111a9.493,9.493,0,0,1-1.665.151c-.214,0-.424-.008-.622-.025a16.93,16.93,0,0,1-9.751-2.866c-2.6-1.847-4.034-4.3-4.034-6.917s1.432-5.068,4.034-6.916a16.931,16.931,0,0,1,9.751-2.866,16.931,16.931,0,0,1,9.75,2.866c2.6,1.847,4.034,4.3,4.034,6.916,0,3.162-2.208,6.155-5.908,8l.063.446.444,4.317Zm-3.048-14.546a1.908,1.908,0,0,0-1.906,1.905,1.908,1.908,0,0,0,1.906,1.905,1.907,1.907,0,0,0,1.905-1.905A1.907,1.907,0,0,0-246.683-1864.128Zm-5.717,0a1.908,1.908,0,0,0-1.906,1.905,1.908,1.908,0,0,0,1.906,1.905,1.907,1.907,0,0,0,1.905-1.905A1.907,1.907,0,0,0-252.4-1864.128Zm-5.718,0a1.907,1.907,0,0,0-1.905,1.905,1.907,1.907,0,0,0,1.905,1.905,1.908,1.908,0,0,0,1.906-1.905A1.908,1.908,0,0,0-258.117-1864.128Z" transform="translate(272.092 1880)"/>
      </g>
    </svg>

  );
}

SmsMessageIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
};

SmsMessageIcon.defaultProps = {
  fill: '#40AADE',
  height: 40
};

export default SmsMessageIcon;
