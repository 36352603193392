import React from 'react';
import Radium from 'radium';
import {
  OrangeButton2
} from '../Buttons';

let styles = {
  wrapper: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  container: {
    position: 'relative',
    maxWidth: 1000,
    width: '90%',
    margin: '0 auto',
    // zIndex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 580px)': {
      textAlign: 'center',
      flexDirection: 'column-reverse',
      width: '100%',
      margin: '15px',
    }
  },
  content: {
    position: 'relative',
    width: '47%',
    '@media (max-width: 580px)': {
      width: '100%',
    }
  },
  img: {
    width: 'auto',
    maxHeight: 450,
    height: 'auto',
    flexShrink: 0,
    flex: 1,
    margin: '0 auto',
    display: 'block',
    '@media (max-width: 580px)': {
      paddingBottom: 40
    }
  },
  header: {
    fontSize: 22,
    fontFamily: 'ProximaNova-SemiBold',
    lineHeight: 1,
    color: '#464D5E',
    '@media (max-width: 580px)': {
      fontSize: 20,
      // textAlign: 'center',
    }
  },
  caption: {
    fontSize: 16,
    lineHeight: 1.61803398875,
    marginTop: 20,
    fontFamily: 'ProximaNova-Regular',
    color: '#464D5E',
  },
  btns: {
    marginTop: 40,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '@media (max-width: 580px)': {
      justifyContent: 'center'
      // textAlign: 'center',
    }
  },
}
const Flippy = (props) => {
  let shiftedFlip = {
    width: '100%',
    margin: '0',
  }
  let shiftedFlop = {
    width: '100%',
    // margin: '80px 0',
  }
  styles.wrapper.backgroundColor = props.background ? 'rgba(229,246,245,0.5)' : ''
  styles.container.flexDirection = props.flipped ? 'row-reverse' : '';
  styles.wrapper['@media (max-width: 580px)'] = props.flipped ? shiftedFlip : shiftedFlop;

  return (
    <div style={[styles.wrapper, props.flipped ? styles.wrapperMinFlipped : styles.wrapperMin]}>
      <div style={styles.container}>
        <div style={styles.content}>
          <div style={styles.header}>{props.header}</div>
          <div style={styles.caption}>{props.caption}</div>
          {props.href ? (
            <div style={styles.btns}>
            <OrangeButton2
              title={props.title}
              href={props.href}
            />
          </div>
          ) : ''}
        </div>
        <div style={[styles.content, styles.hidden]}>
          <img style={styles.img} src={props.image} />
        </div>
      </div>
    </div>
  )
}

export default Radium(Flippy);
