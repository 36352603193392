import React from 'react';
import {
    Datagrid,
    NumberField,
} from 'react-admin';
import {
  ListManage,
  ListAvatar,
  ListName,
  ListStatus
} from './../../components';
import { } from './../../services'

const FundraiserList = (props) => {
  return (
    <Datagrid bodyOptions={{ stripedRows: true, showRowHover: true }} {...props}>
      <ListStatus style={{width:20}} label="Active" source="templating.fundraising" />
      <ListAvatar source="templating.logo" label=""/>
      <ListName source="templating.name" maxLength={25} label="Name"/>
      <NumberField source="earnings" options={{ style: 'currency', currency: 'USD' }} label="Funds Raised"/>
      <ListManage resource="fundraisers"/>
    </Datagrid>
  )
}


export default FundraiserList;
