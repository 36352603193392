import React, {Component} from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import CircularProgress from '@material-ui/core/CircularProgress';
import config from '../../config/config.provider';
import { crudGetOne, crudUpdate } from './../../aor-loopback/dataActions';
import Sticky from 'react-sticky-el';
import {toastr} from 'react-redux-toastr'
import _ from 'lodash';
import { reduxForm } from 'redux-form';
import { getDefaultValues } from 'react-admin';
import {
  Card,
  GreenButton,
  DynamicComponents,
  ClayPhone,
  Edit,
  TourWizardRichMedia,
  StandardModal,
  defaultPortal
} from './../../components';
import {
  entityMgrConnect,
  formEnhancer
} from './../../services';


const style = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    font: '18px ProximaNova-SemiBold',
  },
  content: {
    width: '100%',
    display: 'flex',
    // justifyContent: 'space-between',
    marginTop: 20,
  },
  portalCol: {
    maxWidth: 450,
    minWidth: 300,
    width: '100%',
    '@media (max-width: 640px)': {
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  info: {
    lineHeight: 1.5,
    fontSize: 14,
    fontFamily: 'ProximaNova-Regular',
    marginBottom: 20,
  },
  footerSave: {
    marginTop: 20,
    textAlign: 'center',
  },
  previewCol: {
    width: 430,
    marginLeft: 40,
    position: 'relative',
    '@media (max-width: 640px)': {
      display: 'none',
    }
  },
  sticky: {
    position: 'fixed',
    top: 80,
  },
  phone: {
    marginTop: 20,
  },
  saveBtn: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    font: '18px ProximaNova-SemiBold',
    textAlign: 'center',
  }
}

class InAppPortal extends Component {
  state = {
    run: false,
  }

  forceRerender = () =>  {
    this.forceUpdate();
  }

  componentWillReceiveProps(nextProps) {
    if (!nextProps.record.templating.portal) {
      nextProps.record.templating.portal = defaultPortal.get();
    }
  }

  submit = () => {
    const { props } = this;
    let portal = props.record.templating.portal;
    let realm = props.entityMgr.realm == 'entity' ? 'entity' : 'subentity';
    const _defaultPortal = defaultPortal.get();

    for (let tp = 0; tp < portal.components.length; tp++) {
      for (let dp = 0; dp < _defaultPortal.components.length; dp++) {
        let isPortalDefault = _.isEqual(portal.components[tp], _defaultPortal.components[dp]);

        if (isPortalDefault) {
          toastr.error('Failed to Save', 'Please complete all required fields before saving.')
          console.log('is the same: DO NOT SAVE!', portal.components[tp], _defaultPortal.components[dp])
          return;
        }
      }
    }

    // props.record.templating.portal.components.push({"name":"gb-copyright"})
    props.entityMgr[realm].templating = props.record.templating;
    props.save({
      id: props.record.id,
      templating: props.record.templating
    }, `/portal/edit`);
  }

  render() {
    const entityMgr = this.props.entityMgr || {};
    const sponsor = _.get(entityMgr, 'entity.cause.is_sponsor') === true ? true : false;
    const subentity = (_.get(entityMgr, 'realm') === 'subentity')
    const { steps } = this.state;
    let joyrideTrigger;
    if (_.get(entityMgr, 'realm') === 'entity') {
      joyrideTrigger = !_.get(entityMgr, 'entity.templating.portal', '') ? true : false;
    } else {
      joyrideTrigger = !_.get(entityMgr, 'subentity.templating.portal', '') ? true : false;
    }

    return (
      <form>
        <TourWizardRichMedia
          steps={
          [
            {
              content: (
                <>
                  {!sponsor && !subentity ? (
                  <div>
                    <img style={{width: 350, padding: 20}} src="//cloudfront.startupmedia.com/greenback/mgmtportal/My-Cause-Portal.png"/>
                    <div style={{fontSize: 22}}>
                    In-App Cause Portal
                    </div>
                    <div>
                    One of the key benefits of greenback is the ability to integrate your charity into the greenback mobile app. Using the In-App Cause Portal Editor, you can customize the greenback mobile app by providing images, text, and web links relative to your charity and its mission.
                    </div>
                  </div>
                ) : ''}
                {sponsor && !subentity ? (
                <div>
                  <img style={{width: 350, padding: 20}} src="//cdn.startupmedia.com/greenback/mgmtportal/Bringing-Benefit-Portal.png"/>
                  <div style={{fontSize: 22}}>
                  'sponsor'
                  </div>
                  <div style={{fontSize: 16}}>
                    One of the key benefits of greenback is the ability to integrate your organization into the greenback mobile app. Using the In-App Sponsor Portal Editor, you can customize the greenback mobile app by providing images, text, and web links relative to your organization.
                  </div>
                </div>
              ) : ''}
              {subentity ? (
              <div>
                <img style={{width: 350, padding: 20}} src="//cdn.startupmedia.com/greenback/mgmtportal/Bringing-Benefit-Portal.png"/>
                <div style={{fontSize: 22, paddingBottom: 15}}>
                In-App Campaign Portal
                </div>
                <div style={{fontSize: 16}}>
                  One of the key benefits of greenback is the ability to integrate your Campaign into the greenback mobile app. Using the In-App Campaign Portal Editor, you can customize the greenback mobile app by providing images, text, and web links relative to your Campaign and its mission.
                </div>
              </div>
            ) : ''}

                </>
              ),
              target: '.form',
              disableBeacon: true,
              isFixed: true,
              disableScrolling: true,
              placement: 'center',
              disableOverlay: false,
              showSkipButton: true,
            }
          ]
        }
          continuous={true}
          run={joyrideTrigger}
          disableOverlay={true}
        />
        <Card content={style.header}>
        {!sponsor && !subentity && (
          <div style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
          Edit In-App Cause Portal
          <StandardModal
            img="//cdn.startupmedia.com/greenback/mgmtportal/My-Cause-Portal.png"
            header="In-App Cause Portal"
            subHeader="One of the key benefits of greenback is the ability to integrate your charity into the greenback mobile app. Using the In-App Cause Portal Editor, you can customize the greenback mobile app by providing images, text, and weblinks relative to your charity that will be available to your donors."
            title1="Where is it located?"
            caption1={["The content from the In-App Cause Portal Editor is available to your greenback members in the \"My Cause\" section of the greenback app."]}
            title2="Who can see it?"
            caption2="The In-App Cause Portal will be visible to all greenback members who donate to your Cause or are associated with your Cause via a Registered Sponsor."
            title3="Why is this important?"
            caption3="It provides donors immediate access to your charity. Promote your mission, programs, services, and messaging that reminds donors why your Cause is so important."
            />
          </div>
        )}
        {sponsor && !subentity && (
          <div style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
          Edit In-App Sponsor Portal
          <StandardModal
            img="//cdn.startupmedia.com/greenback/mgmtportal/Bringing-Benefit-Portal.png"
            header="In-App Sponsor Portal"
            subHeader="One of the key benefits of greenback is the ability to integrate your organization into the greenback mobile app. Using the In-App Sponsor Portal Editor, you can customize the greenback mobile app by providing images, text, and weblinks relative to your organization that will be available to your members."
            title1="Where is it located?"
            caption1={["The content from the In-App Sponsor Portal Editor is available to your greenback members in the \"Bringing Benefit\" tab of the greenback app."]}
            title2="Who can see it?"
            caption2="The In-App Sponsor Portal will be visible to all greenback members who make a donation through your Sponsor Sites or are delivered a membership via the Membership Delivery System."
            title3="Why is this important?"
            caption3="It provides members immediate access to your organization. Promote your mission, programs, services, and messaging that reminds members why your organization is so important."
            />
          </div>
      )}
        {subentity && (
          <div style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
          Edit In-App Campaign Portal
          <StandardModal
            img="//cdn.startupmedia.com/greenback/mgmtportal/Bringing-Benefit-Portal.png"
            header="In-App Campaign Portal"
            subHeader="One of the key benefits of greenback is the ability to integrate your organization into the greenback mobile app. Using the In-App Sponsor Portal Editor, you can customize the greenback mobile app by providing images, text, and weblinks relative to your organization that will be available to your members."
            title1="Where is it located?"
            caption1={["The content from the In-App Sponsor Portal Editor is available to your greenback members in the \"Bringing Benefit\" tab of the greenback app."]}
            title2="Who can see it?"
            caption2="The In-App Sponsor Portal will be visible to all greenback members who make a donation through your Sponsor Sites or are delivered a membership via the Membership Delivery System."
            title3="Why is this important?"
            caption3="It provides members immediate access to your organization. Promote your mission, programs, services, and messaging that reminds members why your organization is so important."
            />
          </div>
        )}
          <GreenButton
            label="Save"
            small="true"
            onClick={this.props.handleSubmit(this.submit)}
            className="save"
          />
        </Card>
        <div style={style.content}>
          <div style={style.portalCol}>
            <Card root={style.info}>
              Use the interactive portal builder below to create your In-App Portal. Once you are finished you can review your portal to the right, and click "Save".
            </Card>
            <Card content={{padding:0}} root={{overflow:'hidden'}} className="form">
              <DynamicComponents record={this.props.record} forceRerender={this.forceRerender} />
            </Card>
            <Card root={style.footerSave}>
              <GreenButton
                label="Save"
                small="true"
                onClick={this.props.handleSubmit(this.submit)}
                style={style.saveBtn}
              />
            </Card>
          </div>
          <div style={style.previewCol}>
            <Card content={style.title}>
              In-App Portal Preview
            </Card>
            <Sticky  stickyStyle={{ transform: 'translateY(50px)' }}>
              <div style={style.phone} className="preview">
                <ClayPhone header scale={0.8}>
                  <DynamicComponents type="field" record={this.props.record}/>
                </ClayPhone>
              </div>
            </Sticky>
          </div>
        </div>
      </form>
    )
  }
}

const InAppPortalEnhanced = connect((state, props) => ({
  initialValues: getDefaultValues(state, props)
}))(reduxForm({form: 'edit-portal'})(Radium(InAppPortal)));

const InAppPortalEdit = (props) => {
  const entityMgr = _.get(props, 'entityMgr') || {};
  if (!entityMgr.id) {
    return (<CircularProgress />);
  }
  const id = entityMgr.realm === 'entity' ? entityMgr.entity_id : entityMgr.subentity_id;
  const resource = entityMgr.realm === 'entity' ? 'entities' : 'campaigns';
  return (
    <Edit
      {...props}
      crudGetOne={crudGetOne}
      crudUpdate={crudUpdate}
      resource={resource}
      id={id}
      location={{"pathname":"/portal/edit"}}
      basePath="/"
      undoable={false}
    >
    <InAppPortalEnhanced entityMgr={entityMgr}/>
  </Edit>
  )
}
export default entityMgrConnect(InAppPortalEdit);
