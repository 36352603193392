import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { getDefaultValues, translate } from 'react-admin';
import { reduxForm } from 'redux-form';

export default function formEnhancer({
  FormComponent, 
  formName, 
  validate, 
  onSubmit,
  onSubmitFail
}) {
  return compose(
    translate,
		connect((state, props) => {
			const form = state.form[formName];
			if ((state.admin && !state.admin.loading) && form && form.values) {
				return {
					record: form.values
				}
			}
			else {
				return {
					initialValues: getDefaultValues(state, props)
				};
			}
		}),
		reduxForm({
			form: formName,
      validate,
      onSubmit,
      onSubmitFail 
		})
	)(FormComponent);
}
