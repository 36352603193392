export const SET_SELECTED_SUBENTITIES = 'GGB/SET_SELECTED_SUBENTITIES';

export const setSelectedSubentity = data => ({
  type: SET_SELECTED_SUBENTITIES,
  payload: {data}
});

const initialState = {
  record: null,
};

export default function subentitySelectionReducer(state = initialState, action) {
  switch(action.type) {
    case SET_SELECTED_SUBENTITIES:
      return {
        ...state,
        record: action.payload.data
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
