import React from 'react';

let style = {
  root: {
    fontSize: 16,
    color: '#464D5E',
    textAlign: 'center',
    maxWidth: 640,
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
    marginBottom: 20,
    lineHeight: 1.5,
  }
}

const Caption = ({children, ...props}) => {
  return (
    <div style={style.root}>{children}</div>
  )
}


export default Caption;
