import { put, takeEvery, all } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import config from './../config/config.provider';
import loopbackRestClient from './../aor-loopback';
import showNotification from './../actions/customNotification';

const restClient = loopbackRestClient(config);


function updateMany({resource, ids, data}) {
  return Promise.all(ids.map((id) => (restClient('WITH_ROUTE',resource,{
    route: `${id}/${data.status}`,
  }))));
}

//messages in this array will not trigger a toastr notification
const filterableNotificationMessages = [
  "ra.notification.updated",
  "ra.notification.created",
	"ra.notification.deleted",
  "ra.notification.bad_item",
  "ra.notification.item_doesnt_exist",
  "ra.notification.http_error"
];

function* onCrudUpdateMany(action) {
  //if (filterableNotificationMessages.includes(action.payload.message)) return;
  //here we can handle specific message using configuration keys based 
  const {
    resource,
    basePath,
    refresh
  } = action;
  const {
    ids,
    data
  } = action.payload;
  let unsuccessful = [];
  try {
    const result = yield updateMany({resource, ids, data, basePath, refresh});
    unsuccessful = result.filter(r => r.response.status != 200);
    if (unsuccessful.length > 0) throw unsuccessful;
    yield put({
      "type": "RA/CRUD_UPDATE_MANY_SUCCESS",
      payload: action.payload,
      resource,
      meta: action.onSuccess
    });
    yield put({
      "type": "RA/SET_LIST_SELECTED_IDS",
      payload: [],
      meta: {resource}
    });
  }
  catch(err) {
    action.onFailure.notification.body = action.onFailure.notification.body.replace("$QTY$",unsuccessful.length || "");
    yield put({
      "type": "RA/CRUD_UPDATE_MANY_FAILURE",
      payload: action.payload,
      resource,
      meta: action.onFailure
    }); 
  }
}

export default function* bulkActionsSaga() {
  yield all([
      takeEvery('GGB/CUSTOM_CRUD_UPDATE_MANY', onCrudUpdateMany),
	]);
}
