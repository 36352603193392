import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radium from 'radium';

let style = {
  form: {
    position: 'relative',
    width: '100%',
    background: '#F0F0F0',
  },
  content: {
    padding: 20,
  }
}

const FormCard = ({classes, children, ...props}) => {
  return (
    <div style={{...style.form, ...props.root}} {...props}>
      <div style={{...style.content, ...props.content}}>
        {children}
      </div>
    </div>
  )
}

FormCard.propTypes = {
  style: PropTypes.object //Modifies the content style
}

export default withStyles(style)(Radium(FormCard));
