import React, { Component } from 'react';
import { crudGetOne, crudUpdate } from './../../aor-loopback/dataActions';
import {
  Edit,
  FundraiserOverview,
  BoosterList,
  FundraiserPage
 } from '../../components';
import {
  entityMgrConnect,
  getFromNestedSafely as gS,
} from './../../services';

const style = {
}

class FundraiserEditByOwner extends React.Component {

  render() {
    console.log(this.props)
    return (
      <FundraiserPage {...this.props} entityMgr={this.props.entityMgr}/>
    );
  }
}

export default entityMgrConnect(FundraiserEditByOwner);
