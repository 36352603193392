import {
  showNotification,
} from './../actions/customNotification';

export default function iosCopyToClipboard({id, component, cb, successMsg}) { return () => {
  const element = window.document.getElementById(id);
  console.log('el', element)
  const isiOSDevice = navigator.userAgent.match(/ipad|iphone/i);
  if (!element || !element.select) return;

  if (isiOSDevice) {
    let oldContentEditable = element.contentEditable,
        oldReadOnly = element.readOnly,
        range = document.createRange();

    element.contentEditable = true;
    element.readOnly = false;
    range.selectNodeContents(element);

    let selected = window.getSelection();
    selected.removeAllRanges();
    selected.addRange(range);

    element.setSelectionRange(0, 9999999);

    element.contentEditable = oldContentEditable;
    element.readOnly = oldReadOnly;
  } else {
    element.select();
  }

  const copied = window.document.execCommand("copy");
  if (copied && component.props && component.props.dispatch) component.props.dispatch(showNotification(successMsg || "Succesfully Copied!"));
  cb && cb(copied);

}};
