import React, { Component } from 'react';
import Radium from 'radium';

const style = {
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    color: '#777B84',
    font: '12px ProximaNova-Light',
    marginBottom: 6,
  },
  inputLabel: {
    position: 'relative',
    width: 120,
    height: 120,
    borderRadius: 4,
    background: 'rgba(255,255,255,1)',
    border: '1px solid #ADB1BA',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    overflow: 'hidden',
    pointerEvents: 'auto',
    opacity: 1
  },
  inputUserSubmittedImg: {
    // position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    zIndex: 1,
  }
};

class ImageField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    const {variant, disabled} = this.props;
    const inputValue = this.props.value ;

    if (variant == "circle") {
      style.inputLabel.borderRadius = '50%';
    }
    if (inputValue) {
      style.inputUserSubmittedImg.backgroundImage = `url('${inputValue}')`;
    } else {
      if(this.props.defaultValue) {
        style.inputUserSubmittedImg.backgroundImage = `url(${this.props.defaultValue})`
      } else {
        style.inputUserSubmittedImg.backgroundImage = `url(//cloudfront.startupmedia.com/greenback/mgmtportal/circle-upload-background.jpg)`;
      }
    }

    return (
      <div>
        <div style={style.label}>{this.props.label}</div>
        <div style={style.content}>
          <div style={style.inputLabel}>
            <div style={style.inputUserSubmittedImg} />
          </div>
        </div>
      </div>
    )
  }
}

ImageField.defaultProps = {
  variant: null
}

export default Radium(ImageField);
