import React, { Component } from 'react';
import {reduxForm, Field} from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import Radium from 'radium';
import {
  Card,
  FormCard,
  GreenButton,
  LabeledTextInput,
  LabeledNumberInput,
  ImageInput,
  Edit,
  LabeledTextField,
  CopyInput,
  Show,
  SimpleShowLayout,
  ExternalSiteWidget,
 } from '../../components';
import {
  entityMgrConnect,
  getFromNestedSafely as gS,
  formEnhancer,
  clickToCopy,
  cobrandLink
} from './../../services';
import { required } from './../../validators';


const style = {
  content: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: 15,
    '@media (max-width: 640px)': {
      flexDirection: 'column'
    }
  },
  col: {
    width: 'calc(50% - 10px)',
    '@media (max-width: 640px)': {
      width: '100%',
      marginBottom: 20,
    }
  },
  card: {
    marginBottom: 20,
  },
  title: {
    font: '18px ProximaNova-SemiBold',
    marginBottom: 20,
  },
  caption: {
    font: '16px ProximaNova-Regular',
    lineHeight: 1.5,
    marginBottom: 20,
  },
  input: {
    marginBottom: 20,
  },
  campaignSite: {
    borderTop: '1px solid #ADB1BA',
    paddingTop: 20,
    marginTop: 20,
  },
  countWrap: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  countTitle: {
    font: '18px ProximaNova-SemiBold',
  },
  countLine: {
    height: 1,
    borderTop: '1px dashed #979797',
    flex: 1,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 10,
  },
  countNumber: {
    font: '38px ProximaNova-SemiBold',
  },
  save: {
    textAlign: 'center',
    marginTop: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  id: {
    position: 'absolute',
    top: 10,
    right: 10,
    font: '14px ProximaNova-SemiBold',
    color: '#ADB1BA'
  },
  back: {
    position: 'relative',
    marginBottom: 30,
    font: '14px ProximaNova-SemiBold',
    textDecoration: 'none',
    color: '#FF6200',
  }
}

class BoosterEditForm extends React.Component {

  submit(values, action, props) {
    props.save(props.record, `/boosters/${props.record.id}`);
  }

  render() {
    const { classes } = this.props;
    const manager = gS(this.props, 'record.entity_manager') || {};
    const disabledByBoosterSite = gS(this.props, 'record.templating.logo') ? 0 : 1;

    return (
      <div>
      <Link style={style.back} to="/boosters">Back to Booster List</Link>
      <div style={style.content}>
        <Card root={style.col}>
          <div style={style.id}>ID: {gS(this.props, 'record.id')}</div>
          <div style={style.title}>Booster Site Details</div>
          <div style={style.caption}>Edit the Booster’s information below.</div>
          <form>
            <FormCard>
              <Field
                {...this.props}
                component={ImageInput}
                name="templating.logo"
                label="Booster's Image*"
                ratio={1/1}
                variant="circle"
                ratio={1/1}
                validate={required}
                style={style.input}
                defaultValue={'//cloudfront.startupmedia.com/greenback/misc/graphics/upload-face-preview.png'}
                size={300}
              />
              <Field
                {...this.props}
                name="templating.name"
                component={LabeledTextInput}
                label="Booster's Name*"
                validate={required}
                maxLength="50"
                style={style.input}
              />
              <Field
                {...this.props}
                name="templating.fundraising.goal"
                component={LabeledNumberInput}
                label="Booster's Personal Goal*"
                type="number"
                onInput={(e) =>{
                  e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,6)
                }}
                step="1"
                validate={required}
                style={style.input}
              />
            </FormCard>
            <GreenButton
              label="Save"
              small
              onClick={this.props.handleSubmit(this.submit)}
              style={style.save}
            />
          </form>
        </Card>
        <div style={style.col}>
          <Card root={style.card}>
            <div style={style.title}>Booster Contact Information</div>
            <div style={style.input}>
              <LabeledTextField
                label="Name"
                value={manager.first_name + ' ' + manager.last_name}
              />
            </div>
            <div style={style.input}>
              <LabeledTextField
                label="Email"
                value={manager.email}
              />
            </div>
          </Card>
          <ExternalSiteWidget
            root={style.card}
            title="Booster Site"
            caption="View your Booster's Site or copy the link to share it."
            id="booster-site"
            successMsg="Booster Site Link Copied"
            disabled={disabledByBoosterSite}
            url={cobrandLink({entity: gS(this.props, 'entityMgr.entity.cause'), slug: gS(this.props, 'record.slug')})}
          />
          <Card content={style.countWrap} root={{marginBottom: 20}}>
            <div style={style.countTitle}>Funds Raised</div>
            <div style={style.countLine} />
            <div style={{...style.countNumber, ...{color: '#23BFB8'}}}>${gS(this.props, 'record.earnings') || '0'}</div>
          </Card>
          <Card content={style.countWrap} root={{marginBottom: 20}}>
            <div style={style.countTitle}>DIS Invites Sent</div>
            <div style={style.countLine} />
              <div style={{...style.countNumber, ...{color: '#FF6200'}}}>{gS(this.props, 'record.invitations_sent') || '0'}</div>
          </Card>
        </div>
      </div>
    </div>
    );
  }
}

const BoosterEditComponent = formEnhancer({
  FormComponent: Radium(BoosterEditForm),
  formName: 'edit-booster'
});


const BoosterEdit = (props) => {
  return (
    <Edit {...props} undoable={false}>
      <BoosterEditComponent entityMgr={gS(props, 'entityMgr')}/>
    </Edit>
  )
}

export default entityMgrConnect(withStyles(style)(BoosterEdit));
