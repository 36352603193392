import React, {Component} from 'react';
import { connect } from 'react-redux';
import Icon from '@material-ui/core/Icon';
import {
  Card,
  Tooltip,
  GreenLink,
} from './../';
import {
  getFromNestedSafely as gS,
} from './../../services';

let style = {
  root: {
    width: 'calc(100% - 0px)',
    marginBottom: 10,
    marginRight: 5,
    marginLeft: 5,
    padding: 20,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: 0,
  },
  children: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  welcomeMessage: {
    fontSize: 20,
    fontFamily: 'ProximaNova-Regular',
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    '@media (max-width: 740px)': {
      fontSize: 16,
      letterSpacing: 0,
    },
    '@media (max-width: 400px)': {
      fontSize: 14,
      letterSpacing: 0,
    }
  },
  realmBadge: {
    fontSize: 14,
    fontFamily: 'ProximaNova-Regular',
    position: 'absolute',
    top: 6,
    right: 10,
    textAlignLast: 'end',
    color: '#23bfb8',
  },
  caption: {
    fontSize: 34,
    fontFamily: 'ProximaNova-SemiBold',
    lineHeight: 1.5,
    marginBottom: 20,
    '@media (max-width: 740px)': {
      fontSize: 24,
      letterSpacing: 0,
    },
    '@media (max-width: 400px)': {
      fontSize: 18,
      letterSpacing: 0,
    }
  },
  disabled: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: 0,
    top: 0,
    overflow: 'hidden',
    zIndex: 500,
    background: 'rgba(255,255,255,0.8)',
    borderRadius: 4,
  },
  refreshButton: {
    marginRight: 5,
    marginLeft: 5,
    marginBottom: 5,
    alignItems: 'normal',
    border: 0,
    padding: 0
  },
}


class WidgetDashboardHeader extends Component {
  render() {

    const {
      welcomeMessage,
      badge,
      caption,
      icon,
      iconColor,
      children,
      disabled,
      className,
      tipPlacement,
    } = this.props

    return (
      <Card root={style.root} content={style.content} {...this.props}>
        {disabled && (<div style={style.disabled} />)}
        <div style={style.realmBadge}>
        <GreenLink
        label="Refresh"
        href="javascript:history.go(0)"
        small="true"
        icon="refresh"
        style={style.refreshButton}
          >
          </GreenLink>
        </div>
        {welcomeMessage && (<div style={style.welcomeMessage}>
          Welcome, {welcomeMessage}!
        </div>)}
        {caption && (<div style={style.caption}>{caption}</div>)}
        <div style={style.children}>
          {children}
        </div>
      </Card>
    )
  }
}

export default connect()(WidgetDashboardHeader);
