import React, {Component} from 'react';
import {Field} from 'redux-form';
import {
  LabeledTextInput,
  GreenButton,
  Edit,
} from './../';
import {
  formEnhancer,
  entityMgrConnect,
  getFromNestedSafely as gS,
} from './../../services';
import { crudGetOne, crudUpdate } from './../../aor-loopback/dataActions';
import { required, ein, zipCode, phoneNumber } from './../../validators';
const style = {
  save: {
    margin: '30px auto 0',
    textAlign: 'center'
  },
  input: {
    width: 100,
    '@media (max-width: 580px)': {
    width: '100%'
    }
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
  }
}

class LegalEntityForm extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  submitDetails(values, action, props) {
    props.entityMgr.entity.templating = props.record.templating;
    props.save(values, `${props.basePath}`);
  }

  render() {
    const entityMgr = this.props.entityMgr || {};
    const is_sponsor = gS(entityMgr, 'entity.cause.is_sponsor');

    return (
      <form id="profile">
        <Field
            component={LabeledTextInput}
            label="Legal Entity Name"
            placeholder="Your Organization's Legal Entity Name"
            name="templating.legal.name"
            validate={[required]}
          />
          {!is_sponsor && (
            <Field
              component={LabeledTextInput}
              label="EIN"
              placeholder="XX-XXXXXXX"
              name="templating.legal.ein"
              validate={[required,ein]}
            />
          )}
          <Field
            component={LabeledTextInput}
            label="Street Address"
            placeholder="123 Example Street"
            name="templating.legal.street"
            validate={[required]}
          />
          <Field
            component={LabeledTextInput}
            label="City"
            placeholder="Orlando"
            name="templating.legal.city"
            validate={[required]}
          />
          <div style={style.row}>
            <div style={style.col}>
              <Field
                component={LabeledTextInput}
                label="State"
                placeholder="FL"
                name="templating.legal.state"
                validate={[required]}
              />
            </div>
            <div style={style.col}>
              <Field
                component={LabeledTextInput}
                label="Zip Code"
                placeholder="32801"
                name="templating.legal.zip"
                validate={[required,zipCode]}
                type="number"
              />
            </div>
          </div>
          <Field
            component={LabeledTextInput}
            label={"Organization's Phone Number"}
            placeholder="XXX-XXX-XXXX"
            name="templating.legal.phone_number"
            validate={[required,phoneNumber]}
            type="tel"
          />
        <GreenButton
          label="Save Details"
          onClick={this.props.handleSubmit(this.submitDetails)}
          style={style.save}
          small
        />
      </form>
    )
  }
}

const EnhanceLegalEntityForm = formEnhancer({
  FormComponent: LegalEntityForm,
  formName: 'legal'
});

const LegalEntity = (props) => {
  const entityMgr = gS(props, 'entityMgr') || {};
  return (
    <Edit
      crudGetOne={crudGetOne}
      crudUpdate={crudUpdate}
      resource="entities"
      id={entityMgr.entity_id}
      basePath="/account"
      undoable={false}
    >
      <EnhanceLegalEntityForm entityMgr={entityMgr} {...props}/>
    </Edit>
  )
};

export default entityMgrConnect(LegalEntity);
