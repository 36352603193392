import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';
import _ from 'lodash';

let styles = {
  list: {
    display: 'flex',
    listStyleType: 'none'
  },
  circImage: {
    margin: 5,
    height: 40,
    width: 40,
    borderRadius: '50%'
  },
  image: {
    margin: 5,
    height: 'auto',
    width: 'auto',
    maxWidth: 120,
    maxHeight: 40,
    '@media (max-width: 640px)': {
      height: 'auto',
      width: 'auto',
      maxWidth: 60,
      maxHeight: 30,
    }
  }
};

export const ListAvatar = ({
  elStyle = {},
  record,
  source,
  src,
  title,
  props
}) => {

  const sourceValue = _.get(record, source);
  if (!sourceValue) {
    return <img style={styles.circImage} src="//cloudfront.startupmedia.com/greenback/mgmtportal/circle-upload-background.jpg" />;
  }
  if (Array.isArray(sourceValue)) {
    const style = {
      ...styles.list,
      ...elStyle
    };
    return (
      <ul style={style}>
        {
          sourceValue.map((file, index) => {
            const titleValue = _.get(file, title) || title;
            const srcValue = _.get(file, src) || title;

            return (<li key={index}>
              <img alt={titleValue} title={titleValue} src={srcValue} style={styles.circImage}/>
            </li>);
          })
        }
    </ul>
    );
  }

  const titleValue = _.get(record, title) || title;


  return (<div style={elStyle}>
    <img title={titleValue} alt={titleValue} src={sourceValue} style={record.type === 'subentity' ? styles.image : styles.circImage}/>
  </div>);
};

ListAvatar.propTypes = {
  elStyle: PropTypes.object,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
  title: PropTypes.string
};

export default Radium(ListAvatar);
