import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { DateInput } from 'react-admin';
import {
  getFromNestedSafely as gS,
} from './../../services';
import { Tooltip } from './../';

import { DatePicker } from "material-ui-pickers";
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

const style = {
  wrapper: {
    position: 'relative',
    width: '100%',
  },
  input: {
    color: '#464D5E',
    fontFamily: "'ProximaNova-Light'",
    lineHeight: 1.5,
    fontSize: 14,
  },
  underline: {
    '&:before': { borderBottomColor: '#A4ACAF' },
    '&:hover:before': { borderBottom: '2px solid #7C8487 !important' },
    '&:after': { borderBottomColor: '#23BFB8' },
  },
  label: {
    fontFamily: "'ProximaNova-Light'",
    color: '#777B84',
    fontSize: 16,
    '&:hover': { borderBottomColor: '#A4ACAF' },
    '&$labelFocused': { color: '#23BFB8' },
  },
  labelFocused: {},
  tip: {
    position: 'absolute',
    top: 0,
    right: 0,
  }
}

export class LabeledDateInput extends Component {

  constructor(props) {
    super(props);
    this.dateChange = this.dateChange.bind(this);
  }

  dateChange(date) {
    this.props.change(this.props.input.name, date && date.toISOString());
    console.log('date', date)
  }

  render() {
    const endDate = gS(this.props, `record.${this.props.source}.fundraising.end_date`);
    let date = ''
    if (endDate) {
      date = new Date(endDate)
    }

    const {
      meta: { touched, error } = {},
      input: {...inputProps},
      classes
    } = this.props
  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <div style={style.wrapper}>
        <DatePicker
          {...this.props}
          value={date}
          invalidLabel='Select End Date'
          error={touched && error}
          helperText={touched && error}
          fullWidth
          disablePast={true}
          onChange={this.dateChange}
          InputProps={{
            shrink: true,
            classes: {
              root: classes.input,
              underline: classes.underline,
            }
          }}
          InputLabelProps={{
            classes: {
              root: classes.label,
              focused: classes.labelFocused,
            }
          }}
        />
      {this.props.tip &&  (<Tooltip message={this.props.tip} title={this.props.title} tipPlacement={this.props.tipPlacement} target={this.props.className} />)}
    </div>
  </MuiPickersUtilsProvider>

  )
}
}

export default withStyles(style)(LabeledDateInput)
