import React from 'react';
import { Route } from 'react-router-dom';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import {
  CreateAccountFromToken,
  CreateAccountFromCode
} from './CreateAccount';
import InAppPortalEdit from './InAppPortal';
import Account from './Account';
import {DIS} from './DIS';
import FAQ from './FAQ';
import InternalPrivacy from './PrivacyPolicy';
import ExternalPrivacy from './ExternalPrivacy';
import InternalTerms from './Terms';
import ExternalTerms from './TermsExternal';
import AgeDisclaimer from './AgeDisclaimer';
import {Other} from './Other';
import AuthBroker from './AuthBroker';
import ChangeEmailConfirm from './ChangeEmailConfirm';
import ChangeEmailRevert from './ChangeEmailRevert';

export default [
  <Route exact path="/portal/edit" component={InAppPortalEdit} />,
  <Route exact path="/dis" component={DIS} />,
  <Route exact path="/faq" component={FAQ} />,
  <Route exact path="/account" component={Account} />,
  <Route exact path="/other" component={Other} />,
  <Route exact path="/privacy" component={InternalPrivacy} />,
  <Route exact path="/terms" component={InternalTerms} />,
  <Route exact path="/forgot_password" noLayout component={ForgotPassword} />,
  <Route exact path="/reset_password/:token" noLayout component={ResetPassword} />,
  <Route exact path="/reset_password" noLayout component={ResetPassword} />,
  <Route exact path="/create_account/:token" noLayout component={CreateAccountFromToken} />,
  <Route exact path="/create_account" noLayout component={CreateAccountFromToken} />,
  <Route exact path="/create_account_by_code" noLayout component={CreateAccountFromCode} />,
  <Route exact path="/terms-noauth" noLayout component={ExternalTerms} />,
  <Route exact path="/privacy-noauth" noLayout component={ExternalPrivacy} />,
  <Route exact path="/unable-to-create" noLayout component={AgeDisclaimer} />,
  <Route exact path="/authbroker/:token" noLayout component={AuthBroker} />,
  <Route exact path="/email_change_confirm/:entity_manager_id/:token" noLayout component={ChangeEmailConfirm} />,
  <Route exact path="/email_change_revert/:entity_manager_id/:token" noLayout component={ChangeEmailRevert} />,
];
