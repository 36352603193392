import {
  put,
  takeEvery,
  all
} from 'redux-saga/effects';
import {
  reset
} from 'redux-form';

function* onCrudCreateSuccess(action) {
  yield put(reset('invite-entitymanager'));
}

export default function* customNotificationSaga() {
  yield all([
    takeEvery('AOR/CRUD_CREATE_SUCCESS', onCrudCreateSuccess),
  ]);
}