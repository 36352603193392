import React, {Component} from 'react';
import { style, validators } from './shared';
import loopbackRestClient from './../aor-loopback';
import storage from './../aor-loopback/storage';
import config from './../config/config.provider';
import {propTypes, reduxForm, Field, submit, reset, SubmissionError} from 'redux-form';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import Radium from 'radium';
import {
  translate,
  userLogin as userLoginAction,
} from 'react-admin';
import {
  ExternalLayout,
  Card,
  LabeledTextInput,
  GreenButton
} from './../components';
import {Link} from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';

const apiClient = loopbackRestClient(config);
const { required, email } = validators;

class ForgotPasswordForm extends Component {
  render() {
    const {
      error,
      pristine,
      reset,
      submitting,
      dispatch,
      handleSubmit,
      submitSucceeded
    } = this.props;
    return (
      <ExternalLayout>
        <div style={style.content}>
          <Card content={style.cardModifier}>
            <div style={style.title}>Forgot Password?</div>
            <div style={style.caption}>Enter a registered email to be sent a password reset.</div>
            <form onSubmit={handleSubmit} style={style.form}>
              {error && <p style={style.error}>{config.response_messages.forgot_password_error_nonexistent_email}</p>}
              {submitSucceeded && pristine && <p style={style.success}>{config.response_messages.forgot_password_success}</p>}
              <Field
                component={LabeledTextInput}
                name="email"
                label="Email Address"
                hintText="youremail@domain.com"
                style={style.input}
              />
              <GreenButton
                type="submit"
                style={style.submit}
                label="Reset Password"
                small
              />
            <div style={style.links}>
              <Link style={style.href} to="/login">Back to Login</Link>
            </div>
            </form>
          </Card>
        </div>
      </ExternalLayout>
    );
  }
};

const ForgotPassword = reduxForm({
  form: 'requestReset',
  onSubmit: (values, dispatch, props) => {
    return new Promise((resolve, reject) => {
      const resetErr = { _error: 'Reset Password failed!' };
      apiClient("WITH_ROUTE", "EntityManagers", {
        route:"reset",
        data: values,
        method: "POST"
      }).then(({response}) => {
        if (response.status === 204) {
          dispatch(reset('requestReset'));
          resolve();
        }
        else {
          reject(new SubmissionError(resetErr));
        }
      }).catch((err) => {
        console.error(err);
        if (err.message.includes("not found")) {
          resetErr.email = config.response_messages.forgot_password_error_nonexistent;
        }
        reject(new SubmissionError(resetErr));
      });
    });
  }
})(ForgotPasswordForm);

export default ForgotPassword;
