import React, { Component } from 'react';
import { Resource, Admin } from 'react-admin';
import loopbackRestClient, {authClient} from './aor-loopback';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import { createBrowserHistory as createHistory } from 'history';

import Login from './routes/Login';
import Portal from './routes/Portal';
import customRoutes from './routes';

import {
  Dashboard,
  Layout
} from './components';

import { EntityEditByOwner } from './resources/entities';
import { BoosterList, BoosterEdit, BoosterCreate, BoosterEditByOwner } from './resources/boosters';
import { FundraiserList, FundraiserEdit, FundraiserCreate, FundraiserEditByOwner } from './resources/fundraisers';
import { CampaignList, CampaignEdit, CampaignCreate, CampaignEditByOwner } from './resources/campaigns';

import config from './config/config.provider';

import { reducer as toastr } from 'react-redux-toastr'
import routerReducer from './actions/router';
import entityManagerReducer from './actions/entityManager';
import entityManagerSaga from './sagas/entityManager';
import bulkActionsSaga from './sagas/bulkActions';
import customNotificationReducer from './actions/customNotification';
import customNotificationSaga from './sagas/customNotification';
import reduxFormSaga from './sagas/reduxForm';
import fieldArrayDialogSubmissionReducer from './actions/fieldArrayDialogSubmission';
import changeEmailReducer from './actions/changeEmail';
import subentitySelectionReducer from './actions/subentitySelection';
import featureSubentityReducer from './actions/featureSubentity';
import entityTemplatingReducer from './actions/entityTemplating';
//not working?
import theme from './config/theme';

class DefaultApp extends Component {
  render() {

    const history = createHistory();

    return (
      <Admin
        title="Management Portal"
        dataProvider={loopbackRestClient(config)}
        customRoutes={customRoutes}
        customSagas={[entityManagerSaga, customNotificationSaga, bulkActionsSaga, reduxFormSaga]}
        customReducers={{routerReducer,entityManagerReducer,customNotificationReducer,toastr,fieldArrayDialogSubmissionReducer,changeEmailReducer, subentitySelectionReducer, featureSubentityReducer, entityTemplatingReducer}}
        authProvider={authClient(`${config.apiEndpoints.entity_api}/${config.entity_api_auth_endpoint}`)}
        loginPage={Login}
        dashboard={Dashboard}
        appLayout={Layout}
        theme={theme}
        history={history}
      >
      {permissions => [
        <Resource name="entitymanagers" />,
        ["entity"].includes(permissions) ?
          <Resource name="entities"
            options={{ label: 'Causes' }}
            list={EntityEditByOwner}
          /> : <Resource name="entities" />,
        ["entity"].includes(permissions) ?
          <Resource name="campaigns"
            options={{ label: 'Campaigns' }}
            list={CampaignList}
            edit={CampaignEdit}
            create={CampaignCreate}
          /> :
          ["subentity"].includes(permissions) ?
            <Resource name="campaigns" list={CampaignEditByOwner}/> :
          <Resource name="campaigns" />,

        ["entity","subentity"].includes(permissions) ?
          <Resource name="fundraisers"
            options={{ label: 'Fundraisers' }}
            list={FundraiserList}
            edit={FundraiserEdit}
            create={FundraiserCreate}
          /> :
          ["unit"].includes(permissions) ?
            <Resource name="fundraisers" list={FundraiserEditByOwner}/> :
          <Resource name="fundraisers" />,

        ["entity","subentity","unit"].includes(permissions) ?
            <Resource name="boosters"
              options={{ label: 'Boosters' }}
              list={BoosterList}
              edit={BoosterEdit}
              create={BoosterCreate}
            /> :
            ["subunit"].includes(permissions) ?
              <Resource name="boosters" list={BoosterEditByOwner}/> :
            <Resource name="boosters" />,
      ]}
      </Admin>
    );
  }
}

let App;

switch(process.env.REACT_APP_ENTRY) {
  case 'portal':
    App = Portal;
    break;
  default:
    App = DefaultApp;
}

export default App;
