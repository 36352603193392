import config from '../config/config.provider';
export default function cobrandLink({entity = {}, subentity_id, slug, options}) {
  const envString = (config.env === "production") ? "" : `${config.env}.`;
  let hashBang = '#!/';
  let url = `http://${entity.subdomain}.${envString}gogreenback.com/`;
  if (subentity_id) {
    url = `${url}${hashBang}?subentity_id=${subentity_id}`;
    if (options && (typeof options === "object")) url = `${url}&options=` + encodeURIComponent(JSON.stringify(options));
  }
  else if (slug) {
    url = `${url}giveto/${slug}`;
  } else {
    url = `${url}${hashBang}`;
  }
  return url;
}
