import React, {Component} from 'react';
import { style, validators } from './shared';
import loopbackRestClient from './../aor-loopback';
import storage from './../aor-loopback/storage';
import config from './../config/config.provider';
import { ExternalLayout, Card } from './../components';
import {Redirect} from 'react-router-dom';
import { fetchByToken } from './../actions/entityManager';

const apiClient = loopbackRestClient(config);

export class AuthBroker extends Component {
  state = {
    entityMgr: undefined,
    loading: true,
  }

  async componentDidMount() {
    const token = this.props.match.params.token
    fetchByToken({token}).then((entityMgr) => {
      storage.save('lbtoken', {
        id: this.props.match.params.token,
        entityMgr,
        userId: entityMgr.id
      }, 604800);
      this.setState({entityMgr, loading: false});
    }).catch((err) => {
      console.log(err);
      this.setState({entityMgr: undefined, loading: false});
    });
  }

  render() {
    const { loading, entityMgr } = this.state;
    //finished and not found
    if (!loading && !entityMgr) {
      return (
        <Redirect to={{pathname: "/login"}} />
      )
    }
    //finished and found
    else if (!loading && entityMgr) {
      //redirect to dashboard
      return (
        <Redirect to={{pathname: "/"}} />
      )
    } 
    //still loading
    else {
      return (
        <ExternalLayout>
          <div style={style.content}>
            <Card content={style.cardModifier}>
              <div style={style.caption}>Loading...</div>
            </Card>
          </div>
        </ExternalLayout>
      )
    }
  }
};

export default AuthBroker;
