import React, {Component} from 'react';
import { MenuItem, NestedItems } from './';
import { getFromNestedSafely as gS, cobrandLink } from './../../../services';


class BoosterMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: '',
    }
  }

  openNested = e => {
    this.setState({ [e]: !this.state[e] });
  }

  setRouteFromState() {
    const active =  (this.props.location && this.props.location.pathname) || window.location.pathname;
    this.setState({active: active});
  }

  componentDidMount() {
    this.setRouteFromState();
  }

  setActive(to) {
    this.setState({ active: to });
  }
  render() {
    const disabled = gS(this.props, 'entityMgr.subentity.templating.fundraising') ? 0 : 1;

    return (
      <div>
        <MenuItem
          {...this.props}
          active={this.state.active}
          icon="home"
          name="Dashboard"
          href="/"
          target="_self"
          setActive={this.setActive.bind(this, '/')}
        />

        <NestedItems
          {...this.props}
          in={this.state['Booster Site']}
          name="Booster Site"
          icon="computer"
          openNested={this.openNested.bind(this, 'Booster Site')}
        >
          <MenuItem
            {...this.props}
            active={this.state.active}
            icon="edit"
            name="Edit"
            to="/boosters"
            setActive={this.setActive.bind(this, '/boosters')}
            nested="true"
          />
          <MenuItem
            {...this.props}
            active={this.state.active}
            icon="open_in_new"
            name="View"
            href={cobrandLink({entity: gS(this.props, 'entityMgr.entity.cause'), slug: gS(this.props, 'entityMgr.subentity.slug')})}
            nested="true"
            disabled={disabled}
          />
        </NestedItems>

        <MenuItem
          {...this.props}
          active={this.state.active}
          icon="message"
          name="Donor Invitation System"
          to="/dis"
          setActive={this.setActive.bind(this, '/dis')}
          disabled={disabled}
        />

        <MenuItem
          {...this.props}
          active={this.state.active}
          icon="help"
          name="FAQ"
          to="/faq"
          setActive={this.setActive.bind(this, '/faq')}
        />
      </div>
    )
  }
}

export default BoosterMenu
