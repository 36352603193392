import React, { Component } from 'react';
import { crudGetOne, crudUpdate } from './../../aor-loopback/dataActions';
import {reduxForm, Field} from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import AutoScale from 'react-auto-scale';
import _ from 'lodash';
import {
  Card,
  FormCard,
  GreenButton,
  LabeledTextInput,
  LabeledNumberInput,
  ImageInput,
  Edit,
  LabeledTextField,
  CopyInput,
  Show,
  SimpleShowLayout,
  CobrandFundraiser,
  TourWizard
 } from '../../components';
import {
  entityMgrConnect,
  getFromNestedSafely as gS,
  formEnhancer,
  clickToCopy,
  cobrandLink
} from './../../services';
import { required } from './../../validators';


const style = {
  content: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  col: {
    maxWidth: 450,
    minWidth: 330,
    width: '100%',
  },
  card: {
    marginBottom: 20,
  },
  title: {
    font: '18px ProximaNova-SemiBold',
    marginBottom: 20,
  },
  previewTitle: {
    font: '18px ProximaNova-SemiBold',
    textAlign: 'center',
    '@media (max-width: 375px)': {
      display: 'none',
    }
  },
  caption: {
    font: '16px ProximaNova-Regular',
    lineHeight: 1.5,
    marginBottom: 20,
  },
  input: {
    marginBottom: 20,
  },
  campaignSite: {
    borderTop: '1px solid #ADB1BA',
    paddingTop: 20,
    marginTop: 20,
  },
  countWrap: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  countTitle: {
    font: '18px ProximaNova-SemiBold',
  },
  countLine: {
    height: 1,
    borderTop: '1px dashed #979797',
    flex: 1,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 10,
  },
  countNumber: {
    font: '38px ProximaNova-SemiBold',
  },
  save: {
    textAlign: 'center',
    marginTop: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  previewCol: {
    width: '100%',
    marginLeft: 20,
  },
  preview: {
    marginTop: 20,
    width: '100%',
    overflow: 'hidden',
    '@media (max-width: 375px)': {
      display: 'none',
    }
  },
  previewScaled: {
    width: 1200,
  }
}

class BoosterEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: [
        {
          target: '.form',
          title: 'Booster Site Editor',
          content: 'Create your Booster Site by uploading a photo of yourself, adding your name, and a personal fundraising goal!',
          disableBeacon: true,
          isFixed: true,
          placement: 'right',
          showSkipButton: true,
        }
      ]
    }
  }

  submit(values, action, props) {
    props.entityMgr.subentity.templating = props.record.templating;
    props.save(props.record, '/boosters');
  }


  render() {

    const { steps } = this.state;
    const entityMgr = _.get(this.props, 'entityMgr');
    const joyrideTrigger = !_.get(entityMgr, 'subentity.templating.logo', '') ? true : false;
    return (
      <div>
        <TourWizard
          steps={steps}
          run={joyrideTrigger}
          continuous={true}
        />
        <form>
          <div style={style.content}>
            <Card root={style.col} className="form">
              <div style={style.title}>Booster Site</div>
              <div style={style.caption}>Edit your Booster Site information below.</div>
              <FormCard>
                <Field
                  {...this.props}
                  component={ImageInput}
                  name="templating.logo"
                  label="Your Image*"
                  variant="circle"
                  ratio={1/1}
                  style={style.input}
                  validate={required}
                  defaultValue={'//cloudfront.startupmedia.com/greenback/misc/graphics/upload-face-preview.png'}
                  size={300}
                />
                <Field
                  {...this.props}
                  name="templating.name"
                  component={LabeledTextInput}
                  label="Your Name*"
                  validate={required}
                  maxLength="50"
                  style={style.input}
                />
                <Field
                  {...this.props}
                  name="templating.fundraising.goal"
                  component={LabeledNumberInput}
                  label="Personal Fundraiser Goal*"
                  type="number"
                  step="1"
                  validate={required}
                  style={style.input}
                  title="Personal Fundraising Goal"
                  tip="This is the dollar amount you are trying to personally raise for your fundraiser!"
                  className="fund_goal"
                  onInput={(e) =>{
                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,6)
                  }}
                />
              </FormCard>
              <GreenButton
                label="Save"
                small
                onClick={this.props.handleSubmit(this.submit)}
                style={style.save}
                className="save"
              />
            </Card>
            <div style={style.previewCol} id="preview">
              <Card content={style.previewTitle}>
                Booster Site Preview
              </Card>
              <Card root={style.preview} content={{padding:0}} className="preview">
                <AutoScale>
                  <div style={style.previewScaled}>
                    <CobrandFundraiser subentity={this.props.record} entityMgr={this.props.entityMgr}/>
                  </div>
                </AutoScale>
              </Card>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const BoosterEditComponent = formEnhancer({
  FormComponent: BoosterEditForm,
  formName: 'edit-booster'
});


const BoosterEditByOwner = (props) => {
  const entityMgr = gS(props, 'entityMgr') || {};
  return (
    <Edit
      {...props}
      id={entityMgr.subentity_id}
      crudGetOne={crudGetOne}
      crudUpdate={crudUpdate}
      resource="boosters"
      location={{"pathname":"/boosters"}}
      basePath="/"
      undoable={false}
    >
      <BoosterEditComponent {...props}/>
    </Edit>
  )
}

export default entityMgrConnect(BoosterEditByOwner);
