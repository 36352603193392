import React, { Component } from "react";
import loopbackRestClient from './../../aor-loopback';
import config from './../../config/config.provider';
import { Field } from 'redux-form';
import { submit, reset } from 'redux-form';
import { userLogin as userLoginAction } from 'react-admin';
import {Link} from 'react-router-dom';
import {
    formEnhancer
} from './../../services';
import {
  required,
  isValidMoment,
} from './../../validators';
import {
  Card,
  GreenButton,
  LabeledTextInput,
  AgeCheckDateInput
} from './../../components';
import { SubmissionError } from 'redux-form';
import { style } from './../../routes/shared';

const apiClient = loopbackRestClient(config);

class AccountFormFromCode extends Component {

  submit(values, dispatch, props) {
    dispatch(submit('create-account-from-code'))
  }

  render() {
    const {
      error,
      pristine,
      reset,
      submitting,
      dispatch,
      submitSucceeded
    } = this.props;
    return (
      <div style={style.content}>
        <Card content={style.cardModifier}>
          <div style={style.title}>Booster Registration</div>
          <div style={style.caption}>Enter your information and a unique Fundraiser Code below to become a Booster.</div>
          <form style={style.form}>
            {error && <p style={style.error}>{error}</p>}
            <Field
              name="first_name"
              component={LabeledTextInput}
              label="First Name"
              validate={required}
              palceholder="John"
              style={style.input}
            />
            <Field
              name="last_name"
              component={LabeledTextInput}
              label="Last Name"
              validate={required}
              palceholder="Smith"
              style={style.input}
            />
            <Field
              name="email"
              component={LabeledTextInput}
              label="Email"
              validate={required}
              type="email"
              style={style.input}

            />
            <Field
              component={LabeledTextInput}
              name="password"
              label="Password"
              type="password"
              style={style.input}
            />
            <Field
              name="code"
              component={LabeledTextInput}
              label="Fundraiser Code"
              validate={required}
              palceholder="XXXXXX"
              tip="A Fundraiser Code is a unique six-digit code provided by your Fundraiser Manager. This code will associate your account to that particular fundraiser!"
              style={style.input}
              className="fund_code"
              onInput={(e) => e.target.value = ("" + e.target.value).toUpperCase()}
            />
            <Field
              name="confirmAge"
              component={AgeCheckDateInput}
              label="Your Birthday"
              placeholder="mm/dd/yyyy"
              validate={[isValidMoment]}
            />
            <GreenButton
              type="submit"
              style={style.submit}
              disabled={submitting}
              label="Create Account"
              small="true"
              onClick={this.props.handleSubmit(this.submit)}
            />
            <div style={style.links}>
              <Link style={style.href} to="/login">Back to Login</Link>
            </div>
          </form>
        </Card>
      </div>
    );
  }
}

const CreateAccountFormFromCode = formEnhancer({
  FormComponent: AccountFormFromCode,
  formName: 'create-account-from-code',
  onSubmit: (values, dispatch, props) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {response} = await apiClient("WITH_ROUTE", "EntityManagers", {
          route:"createFromCode",
          data: values,
          method: "POST"
        });
        if (response.status === 200 || response.status === 204) {
          dispatch(reset('create-account-from-code'));
          //wait for streaming replication
          setTimeout(() => {
            dispatch(userLoginAction(values, '/'));
          }, 500);
          resolve();
        }
        else {
          reject(new SubmissionError({ _error: config.response_messages.create_from_slug_error}));
        }
      }
      catch(err) {
        if (err.code == "INVALID_DOMAIN") {
          console.log('user needs to use a valid domain');
          reject(new SubmissionError({ _error: err.message}));
        }
        else if (err.code == "AGE_COPPA_NONCOMPLIANCE") {
          console.log('prevent user from submitting form again');
          window.location.replace('/unable-to-create');
        }
        else {
          reject(new SubmissionError({ _error: err.message}));
        }
      }
    });
  }
});

export default CreateAccountFormFromCode
