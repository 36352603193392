const ROUTER_LOCATION_CHANGE = "@@router/LOCATION_CHANGE";

export default function routerReducer(state = null, action) {
  // console.log(action.type);
  switch(action.type) {
    case ROUTER_LOCATION_CHANGE:
      window.scrollTo(0, 0);
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
