import React, {createElement} from 'react';
import PropTypes from 'prop-types';
import config from '../../config/config.provider';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import {
  FacebookIcon,
  TwitterIcon,
  SmsMessageIcon,
  EmailIcon,
  CopyInput
} from './../';
import {
  cobrandLink,
  getFromNestedSafely as gS,
} from './../../services';

let style = {
  body: {
  },
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    marginTop: 15,
  },
  item: {
    maxWidth: 110,
    textAlign: 'center',
    marginLeft: 12,
    marginRight: 12,
  },
  caption: {
    display: 'block',
    fontSize: 12,
    color: '#9B9B9B',
    textAlign: 'center',
    marginTop: 5
  },
  icon: {
    textAlign: 'center',
    cursor: 'pointer',
    textDecoration: 'none',
  },
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const SocialContent = (props) => {

  const envString = (config.env === "production") ? "" : `${config.env}.`;
  const entityMgr = props.entityMgr || {};

  let
  caption,
  facebook,
  sms,
  smsLink,
  isMobile,
  email,
  fundName,
  fundGoal,
  url,
  isSponsor,
  subentityId,
  realm = ''

  realm = gS(props, 'entityMgr.realm');
  isSponsor = _.get(entityMgr, 'entity.cause.is_sponsor', '')
  console.log('is_sponsor', isSponsor)
  url = cobrandLink({entity: gS(props,'entityMgr.entity.cause'), slug: gS(props,'entityMgr.subentity.slug')})
  fundName = gS(props,'entityMgr.subentity.type') === 'subunit' ? gS(props,'entityMgr.subentity.subentity.templating.name', '') : gS(props,'entityMgr.subentity.templating.name', '');
  fundGoal = gS(props,'entityMgr.subentity.type') === 'subunit' ? gS(props,'entityMgr.subentity.templating.fundraising.goal', '') : gS(props,'entityMgr.subentity.templating.fundraising.goal', '');
  subentityId = gS(props,'entityMgr.subentity.id');

  // Facebook

  const shareToFacebookLink  = gS(props,'entityMgr.subentity') ? `https://share.${envString}gogreenback.com/facebook?entity_id=${entityMgr.entity.gb_cause_id}&subentity_id=${subentityId}` : `https://share.${envString}gogreenback.com/facebook?entity_id=${entityMgr.entity.gb_cause_id}`;

  const shareToFacebookLinkEncoded = encodeURIComponent(shareToFacebookLink);
  facebook = (
    <a onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareToFacebookLinkEncoded}`, "Popup","toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0, width=580, height=600")} title="facebook"style={style.icon}><FacebookIcon height={40} /><span style={style.caption}>Facebook</span></a>
  )
  // SMS
  const smsBody = encodeURIComponent(
    `Hey! I am trying to help raise funds for ${fundName}. Help me reach our goal of $${fundGoal} by donating and you'll get greenback. ${url}`
  );
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (/android/i.test(userAgent)) {
    isMobile = true;
    smsLink = "sms:?body=" + smsBody;
  }
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    isMobile = true;
    smsLink = "sms:&body=" + smsBody;
  }
  sms = (
    <a href={smsLink} style={{textDecoration: 'none'}}><SmsMessageIcon height={40} /><span style={style.caption}>Message</span></a>
  )

  // Email
  let emailSubject, emailBody;

  if (realm === 'subunit') {
    emailSubject = encodeURIComponent('Have you seen our new fundraiser yet?');
    emailBody = encodeURIComponent(`Hello, \r\n\r\n I am helping raise funds for ${fundName}. Please help me reach my goal of $${numberWithCommas(fundGoal)} by making a small donation, and you'll get greenback! \r\n\r\n Greenback is a nationwide mobile savings program that has over 350,000 offers everywhere you love to dine, shop and travel! \r\n\r\n Please use the link below to view my fundraiser and learn more! \r\n\r\n ${url}`);
  }

  if (realm === 'unit') {
    emailSubject = encodeURIComponent('Have you seen our new fundraiser yet?');
    emailBody = encodeURIComponent(`Hello, \r\n\r\n I am helping raise funds for ${fundName}. Please help us reach our goal of $${numberWithCommas(fundGoal)} by making a small donation, and you'll get greenback! \r\n\r\n Greenback is a nationwide mobile savings program that has over 350,000 offers everywhere you love to dine, shop and travel! \r\n\r\n Please use the link below to view the fundraiser and learn more! \r\n\r\n ${url}`);
  }

  if (realm === 'subentity') {
    emailSubject = encodeURIComponent('Have you seen this greenback Campaign?');
    emailBody = encodeURIComponent(`Hello, \r\n\r\n I thought you might be interested in donating to this greenback campaign, ${fundName}. \r\n\r\n When you donate through greenback you are rewarded with a membership to the greenback everyday savings program! \r\n\r\n Greenback is a nationwide mobile savings program that has over 350,000 offers everywhere you love to dine, shop and travel! \r\n\r\n Please use the link below to view the campaign and learn more! \r\n\r\n ${url}`);
  }

  if (realm === 'entity' && isSponsor) {
    emailSubject = encodeURIComponent('Checkout this organization and the non-profits it supports!');
    emailBody = encodeURIComponent(`Hello, \r\n\r\n I thought you might be interested in donating to a Cause through greenback! ${gS(props, 'entityMgr.entity.name')} is promoting different causes that you are able to donate to, and when you make a donation you are rewarded with a membership to the greenback everyday savings program! \r\n\r\n Greenback is a nationwide mobile savings program that has over 350,000 offers everywhere you love to dine, shop and travel! \r\n\r\n Please use the link below to learn more! \r\n\r\n ${url}`);
  }

  if (realm === 'entity' && !isSponsor) {
    emailSubject = encodeURIComponent('Checkout this greenback Cause!');
    emailBody = encodeURIComponent(`Hi, \r\n\r\n I thought you might be interested in supporting this greenback Cause, ${gS(props, 'entityMgr.entity.name')}. \r\n\r\n When you donate to a Cause through greenback you are rewarded with a membership to the greenback everyday savings program! \r\n\r\n Greenback is a nationwide mobile savings program that has over 350,000 offers everywhere you love to dine, shop and travel! \r\n\r\n Please use the link below to view the Cause and learn more! \r\n\r\n ${url}`);
  }

  const emailLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;
  email = (
    <a href={emailLink} style={{textDecoration: 'none'}}><EmailIcon height={40} /><span style={style.caption}>Email</span></a>
  )



  return (
    <div style={style.body}>
      <CopyInput
        label="Copy Link"
        id="cobrandUrl"
        successMsg="Link Copied!"
        value={shareToFacebookLink}
      />

      <div style={style.container}>
        {props.facebook ? (<div style={style.item}>{facebook}</div>) : ''}
        {props.email ? (<div style={style.item}>{email}</div>) : ''}
        {props.sms ? isMobile ? (<div style={style.item}>{sms}</div>) : '' : ''}
      </div>

    </div>
  );
}

export default SocialContent;
