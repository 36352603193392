import React, {Component} from 'react';
import { connect } from 'react-redux';

import {
  CauseSitePreview,
  Card,
  DynamicComponents,
  ClayPhone,
} from './../../components';
import {
  entityMgrConnect,
  getFromNestedSafely as gS
} from './../../services';

const style = {
  phone: {
    marginTop: 20,
  },
}

const Other = (props) => {
  return (
    <div style={style.phone}>
      <ClayPhone header scale={0.8}>
        <DynamicComponents type="field" record={gS(props, 'entityMgr.entity')}/>
      </ClayPhone>
    </div>
  )
}

export default entityMgrConnect(Other);
