import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { grid } from './../../style';
import Radium from 'radium';
import style from './style.js';
import _ from 'lodash';
import Icon from '@material-ui/core/Icon';
import {
  getFromNestedSafely as gS,
} from './../../services';
import {
  Widget,
  WidgetDashboardHeader,
  FundsRaised,
  OrangeButton,
  GreenButton,
  GreenLink,
  IconButtons,
  DISFormWidget,
  SocialContent,
  Card,
  TourWizard,
  InvitesSent,
  TotalBoosters,
} from '../';
import { cobrandLink } from './../../services';


const FundraiserDashboard = (props) => {
  const entityMgr = props.entityMgr || {};
  const disableFundraiserView = !_.get(entityMgr, 'subentity.templating.logo') ? true : false;
  const steps = [{
    target: '.appbar',
    title: 'Welcome to your Management Portal!',
    content: 'Through this portal you will be able to create & edit your Fundraiser Site, and invite & manage Boosters! Let\'s get started by creating your Fundraiser Site.',
    disableBeacon: true,
    isFixed: true,
    hideCloseButton: true,
    placement: 'center',
    locale: {next: 'Get Started!',}
  },
  {
    target: '.fundraiser_site',
    title: 'Create Your Fundraiser Site',
    content: 'Click the button to open the Fundraiser Site editor.',
    disableBeacon: true,
    isFixed: true,
    hideFooter: true,
    hideCloseButton: true,
  }]
  const joyrideTrigger = !_.get(entityMgr, 'subentity.templating.fundraising', '') ? true : false;
  const continuous = !_.get(entityMgr, 'subentity.templating.logo', '') ? true : false;
  const fundraiserSetupLink = _.get(entityMgr, 'entity.templating.fundraiserSetupLink');

  let
  unitName = '',

  mName = gS(props, 'entityMgr.first_name');
  unitName = gS(props, 'entityMgr.subentity.templating.name');


  return (
    <div>
      <TourWizard
        steps={steps}
        continuous={continuous}
        run={joyrideTrigger}
      />
      <div style={grid.grid}>
        <div style={grid.row}>
          <div style={grid.col}>
            <Card root={style.marketingCard}>
              <div style={style.marketing}>
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <Icon style={{fontSize:22,color:'#FF6200',marginRight:20}}>info</Icon>
                  <div style={style.guideTitle}>Launch your Fundraiser in minutes by following the steps in our Fundraiser Setup Guide.</div>
                </div>
                <OrangeButton
                  label="Open Setup Guide"
                  small="true"
                  target="_blank"
                  href= {fundraiserSetupLink ? (fundraiserSetupLink) : "http://fundraising.gogreenback.com/#/fundraiser-setup-guide"}
                  icon="open_in_new"
                  style={style.marketingButton}
                />
              </div>
            </Card>
          </div>
        </div>
        <div style={grid.row}>
        <WidgetDashboardHeader
          welcomeMessage={mName}
          caption={unitName}
        >
        <div style={style.buttons}>
          <GreenLink
            label="Edit Fundraiser Site"
            to="/fundraisers"
            component={Link}
            small="true"
            icon="edit"
            className="fundraiser_site"
            style={style.textButton}
          />
          <GreenLink
            label="View Fundraiser Site"
            small="true"
            target="_blank"
            href={cobrandLink({entity: _.get(entityMgr, 'entity.cause'), slug: _.get(entityMgr, 'subentity.slug')})}
            icon="open_in_new"
            disabled={disableFundraiserView}
            style={style.textButton}
          />
          </div>

        </WidgetDashboardHeader>
        </div>
        <div style={grid.row}>
        <div style={grid.col33}>
          <Widget
            icon="monetization_on"
            title="Funds Raised"
          >
            <FundsRaised record={entityMgr.subentity} />
          </Widget>
        </div>
        <div style={grid.col33}>
          <Widget
            icon="email"
            title="Total Invites Sent"
          >
            <InvitesSent entityMgr={props.entityMgr} />
          </Widget>
        </div>
        <div style={grid.col33}>
          <Widget
            icon="supervised_user_circle"
            title="Total Boosters"
          >
            <TotalBoosters entityMgr={props.entityMgr}
              />
          </Widget>
        </div>
        </div>
        <div style={grid.row}>
          <div style={grid.col50}>
            <Widget
              icon="message"
              title="Donor Invitation System"
              caption="Invite a friend or family member to donate."
              tip="The DIS will create a personalized email to send your recipient requesting a donation, explaining what the donation is for, and information about greenback!"
              className="dis"
            >
              <DISFormWidget />
            </Widget>
          </div>
          <div style={grid.col50}>
            <Widget
              icon="share"
              title="Share Fundraiser Site"
              caption="Share your site with others by sending the link directly or using social media."
              tip="Easily share your Fundraiser Site via social media by copying your unique link, or by using one of the social shortcut icons."
              className="social"
            >
              <SocialContent
                entityMgr={entityMgr}
                sms="true"
                facebook="true"
                email="true"
              />
            </Widget>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Radium(FundraiserDashboard);
