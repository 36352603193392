import React, {Component} from 'react';
import {
  ExternalLayout,
  Card,
  GreenButton
} from './../components';

const style = {
  wrapper: {
    position: 'relative',
    maxWidth: 500,
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    alignSelf: 'flex-start',
  },
  title: {
    font: '24px ProximaNova-SemiBold',
    marginBottom: 20,
  },
  content: {
    font: '16px ProximaNova-Light',
    lineHeight: 1.4,
    marginTop: 20,
  },
  href: {
    color: '#2CBBB7',
    textDecoration: 'none',
    fontFamily: "'ProximaNova-SemiBold'",
  },
  button: {
    marginTop: 20,
    width: 'fit-content'
  }
}

const AgeDisclaimer = (props) => {
  return (
    <ExternalLayout>
      <div style={style.wrapper}>
        <Card root={style.content}>
          <div style={style.title}>We were unable to create your account</div>
          <div>
            Due to your age, we could not create your account. To continue, please create an account with your parent or legal guardian. If you received an email invitation, please contact your Fundraiser Manager to invite your parent or guardian.
            <GreenButton
              style={style.button}
              label="I Understand"
              small="true"
              href="/login"
            />
          </div>
        </Card>
      </div>
    </ExternalLayout>
  )
}

export default AgeDisclaimer;
