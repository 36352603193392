import React, { Component } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddPhotoAlternateOutlined from '@material-ui/icons/AddPhotoAlternateOutlined';
import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import {ReactCropper as Cropper} from './../../services';
import Dialog from '@material-ui/core/Dialog';
import 'cropperjs/dist/cropper.css';
import { withStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import {
  GreenButton
} from './../';
import {
  loadImage,
  uploadThumbor,
  formatImage,
  dataURItoBlob,
} from './../../config/thumbor';
import Radium from 'radium';

const style = {
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    color: '#777B84',
    font: '12px ProximaNova-Light',
    marginBottom: 6,
  },
  input: {
    position: 'absolute',
    top: -2000,
    left: -2000,
    opacity: 0,
    display: 'none'
  },
  inputLabel: {
    position: 'relative',
    width: 120,
    height: 120,
    borderRadius: 4,
    background: 'rgba(255,255,255,1)',
    border: '1px solid #ADB1BA',
    // display: 'flex',
    // justifyContent: 'center',
    // alignItems: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
    pointerEvents: 'auto',
    opacity: 1,
    flexShrink: 0,
    ':hover': {
      opacity: 'rgba(0,0,0,.10)'
    }
  },
  inputLoading: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
  },
  loader: {
    color: '#23BFB8',
  },
  inputUserSubmittedImg: {
    // position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    zIndex: 1,
  },
  portalHeaderBackground: {
    position: 'relative',
    width: '100%',
    padding: '12% 0',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  portalHeaderBackgroundLabel: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '55%',
    height: '100%',
    background: 'transparent',
    cursor: 'pointer',
    color: 'transparent',
    fontSize: 24,
    zIndex: 10,
    border: '1px solid transparent',
    transition: 'all 0.2s',
    ':hover': {
      color: '#FFF',
      background: 'linear-gradient(to left, rgba(255,98,0,0.00) 0%, rgba(255,98,0,0.60) 29%)',
    }
  },
  portalAltCircBackground: {
    width: 180,
    height: 180,
    borderRadius: '50%',
    overflow: 'hidden',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  },
  portalAltCircBackgroundLabel: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    background: 'transparent',
    cursor: 'pointer',
    color: 'transparent',
    fontSize: 24,
    transition: 'all 0.2s',
    border: '1px solid transparent',
    ':hover': {
      color: '#FFF',
      background: 'rgba(255,98,0,0.5)',
    }
  },
  cobrandHeaderBackground: {
    position: 'relative',
    width: '100%',
    // padding: '12% 0',
    height: 610,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  cobrandHeaderBackgroundLabel: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '55%',
    height: '100%',
    background: 'transparent',
    cursor: 'pointer',
    border: '1px solid transparent',
    color: 'transparent',
    fontSize: 24,
    zIndex: 10,
    transition: 'all 0.2s',
    ':hover': {
      color: '#FFF',
      background: 'linear-gradient(to left, rgba(255,98,0,0.00) 0%, rgba(255,98,0,0.60) 29%)',
    }
  },
  cobrandSectionBackground: {
    position: 'relative',
    width: '100%',
    // padding: '12% 0',
    height: 480,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  cobrandSectionBackgroundLabel: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    background: 'transparent',
    cursor: 'pointer',
    color: 'transparent',
    border: '1px solid transparent',
    fontSize: 24,
    zIndex: 10,
    transition: 'all 0.2s',
    ':hover': {
      color: '#FFF',
      background: 'rgba(255,98,0,0.6)',
    }
  },
  inputIcon: {
    fontSize: 36,
    color: '#ADB1BA'
  },
  uploadButton: {
    marginLeft: 20,
  },
  errorMessage: {
    fontSize: 12,
    color: '#E00A1F',
    marginTop: 5,
    textAlign: 'center',
  },
  paper: {
    maxHeight: 'calc(100% - 0px) !important',
  },
  editor: {
    position: 'relative',
    // width: '80%',
    // maxWidth: 600,
    padding: 20,
    background: '#FFF',
    '@media (max-width: 780px)': {
      padding: 10,
      width: '90%',
    }
  },
  cropper: {
    maxWidth: 600,
    maxHeight: 600,
    width: '100%',
    height: '70vh'
  },
  editorLoading: {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
    height: '100%',
		zIndex: 900,
    background: 'rgba(255,255,255,0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
	},
  crop: {
    position: 'relative',
		width: 75,
		height: 30,
		border: '1px solid #2CBBB7',
		borderRadius: 3,
		color: '#2CBBB7',
		fontSize: 12,
		marginLeft: 'auto',
		cursor: 'pointer',
		background: '#FFF',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
    '@media (max-width: 780px)': {
      marginTop: 10,
      marginRight: 'auto',
    }
  },
  editorBtn: {
    position: 'relative',
		height: 30,
		border: '1px solid #2CBBB7',
		borderRadius: 3,
		color: '#2CBBB7',
		fontSize: 12,
    marginRight: 10,
		cursor: 'pointer',
		background: '#FFF',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
    '@media (max-width: 780px)': {
      fontSize: 10,
      marginLeft: 5,
      marginRight: 5,
    }
  },
  btnGroup: {
    display: 'flex',
    marginTop: 10,
    flexWrap: 'wrap',
    '@media (max-width: 780px)': {
      display: 'block'
    }
  },
  editorBtns: {
    display: 'flex',
  },
  closeEditor: {
    position: 'absolute',
    top: -7,
    right: -7,
    backgroundColor: '#FFF',
    width: 15,
    height: 15,
    borderRadius: '50%',
    padding: 10,
    zIndex: 100,
    color: 'red',
    fontFamily: 'ProximaNova-SemiBold',
    cursor: 'pointer',
  }
};

class ImageInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // error: null,
      rotation: 0,
      uploadedFile: null,
      loading: false,
      // editor: true,
      image: null,
      editorLoading: false,
    }
  }

  rotateLeft(e) {
    e.preventDefault();
    this.cropper.rotate(-90);
  }
  rotateRight(e) {
    e.preventDefault();
    this.cropper.rotate(90);
  }
  zoomIn(e) {
    e.preventDefault();
    this.cropper.zoom(0.1);
  }
  zoomOut(e) {
    e.preventDefault();
    this.cropper.zoom(-0.1);
  }
  closeEditor(e) {
    e.preventDefault();
    this.cropper.reset().clear();
    this.setState({editor: false, loading: false, image: null})
  }

  submittedImg(e) {
    e.preventDefault();
    // Get image and set state
    let file;
    file = e.target.files[0];
    if (!file) return;
    this.setState({loading: true});
    e.target.value = null

    // thumbor load
    loadImage({file:file})
    .then(file => {
      this.setState({
        editor: true,
        image: file.dataUri,
      })
    })
    .catch(err => {
      console.log('loadImage', err)
      this.setState({
        loading: false,
        editorLoading: false
      })
    })
  }

  uploadCroppedImg(e) {
    e.preventDefault();
    let dataUri = this.cropper.getCroppedCanvas().toDataURL();
    let file = dataURItoBlob(dataUri);
    let size = _.get(this.props, 'size', 600);
    this.setState({editorLoading: true});
    uploadThumbor({file, dataUri, size})
    .then(formatImage)
    .then(({src, dataUri}) => {
      this.cropper.reset();
      this.setState({
        image: null,
        loading: false,
        editor: false,
        editorLoading: false
      });
      this.props.input.onChange(src);
    })
    .catch(err => {
      console.log('uploadCroppedImg', err);
      this.setState({loading: false, editorLoading: false});
    })
  }

  render() {
    const { meta, touched, error, variant, disabled, classes} = this.props;
    const imageFromInput = this.props.input.value || this.props.defaultValue;
    const isErrored = meta.touched && meta.invalid;

    if (variant == "square") {
      style.inputLabel.borderRadius = '0%';
    }
    if (this.state.image && variant == "square") {
      setTimeout(function(){
        document.getElementsByClassName('cropper-view-box')[0].style.borderRadius = '0%'
      }, 200);
    }

    if (variant == "circle") {
      style.inputLabel.borderRadius = '50%';
    }
    if (this.state.image && variant == "circle") {
      setTimeout(function(){
        document.getElementsByClassName('cropper-view-box')[0].style.borderRadius = '50%'
      }, 200);
    }
    if (isErrored) {
      style.inputLabel.borderColor = '#E00A1F';
      style.portalHeaderBackgroundLabel.backgroundColor = 'rgba(244,10,31,0.3)';
      style.portalAltCircBackgroundLabel.backgroundColor = 'rgba(244,10,31,0.3)';
      style.cobrandHeaderBackgroundLabel.backgroundColor = 'rgba(244,10,31,0.3)';
      style.cobrandSectionBackgroundLabel.backgroundColor = 'rgba(244,10,31,0.3)';
    } else {
      style.inputLabel.borderColor = '#ADB1BA';
      style.portalHeaderBackgroundLabel.backgroundColor = 'transparent';
      style.portalAltCircBackgroundLabel.backgroundColor = 'transparent';
      style.cobrandHeaderBackgroundLabel.backgroundColor = 'transparent';
      style.cobrandSectionBackgroundLabel.backgroundColor = 'transparent';
    }
    if (disabled) {
      style.inputLabel.pointerEvents = 'none';
      style.inputLabel.opacity = .5;
    } else {
      style.inputLabel.pointerEvents = 'auto';
      style.inputLabel.opacity = 1;
    }


    return (
      <div style={this.props.style}>
        <Dialog
          open={this.state.editor}
          onClose={(e) => this.closeEditor(e)}
          maxWidth="lg"
          classes={{
            paper: classes.paper
          }}
        >
          <div style={style.editor}>
            <div style={style.closeEditor} onClick={(e) => this.closeEditor(e)}>x</div>
            {this.state.editorLoading && (<div style={style.editorLoading}><CircularProgress size={200} thickness={10}/></div>)}
            <Cropper
              ref={cropper => { this.cropper = cropper; }}
              src={this.state.image}
              style={style.cropper}
              aspectRatio={this.props.ratio}
              guides={true}
            />
            <div style={style.btnGroup}>
              <div style={style.editorBtns}>
                <button style={style.editorBtn} onClick={(e) => this.rotateLeft(e)}>&#10226; Rotate</button>
                <button style={style.editorBtn} onClick={(e) => this.rotateRight(e)}>&#10227; Rotate</button>
                <button style={style.editorBtn} onClick={(e) => this.zoomIn(e)}>Zoom In</button>
                <button style={style.editorBtn} onClick={(e) => this.zoomOut(e)}>Zoom Out</button>
              </div>
              <button style={style.crop} onClick={(e) => this.uploadCroppedImg(e)}>Upload</button>
            </div>
          </div>
        </Dialog>
        {this.props.label && (<div style={style.label}>{this.props.label}</div>)}


          {this.props.type === 'default' ? (
            <div>
              <div style={style.content}>
                <label htmlFor={this.props.input.name} style={style.inputLabel}>

                  {this.state.loading && (<div style={style.inputLoading}><CircularProgress style={style.loader} /></div>)}

                  <div style={[style.inputUserSubmittedImg, {backgroundImage: `url('${imageFromInput}')`}]} />

                  {imageFromInput ? '' : (<AddPhotoAlternateOutlined style={style.inputIcon}/>)}

                  <input id={this.props.input.name} name="input" type="file" style={style.input} accept="image/*" onChange={(e) => this.submittedImg(e)}/>

                </label>
                {this.props.hiddenUploadBtn ? '' : (
                  <label htmlFor={this.props.input.name} style={style.uploadButton}>
                    <GreenButton label={this.props.buttonLabel} type="button" component="span" small disabled={disabled}/>
                  </label>
                )}
              </div>
              {isErrored && (<div style={style.errorMessage}>{this.props.meta.error} </div>)}
            </div>
          ) : this.props.type === 'portalHeader' ? (
            <div>
              <div style={[style.portalHeaderBackground, {backgroundImage: `url('${imageFromInput}')`}]} />
              <label htmlFor={this.props.input.name} style={style.portalHeaderBackgroundLabel}>
                <AddPhotoAlternateIcon style={{fontSize: 32}}/>
                <input id={this.props.input.name} name="input" type="file" style={style.input} accept="image/*" onChange={(e) => this.submittedImg(e)}/>
              </label>
            </div>
          ) : this.props.type === 'portalAltCirc' ? (
            <div>
              <div style={[style.portalAltCircBackground, {backgroundImage: `url('${imageFromInput}')`}]}>
              <label htmlFor={this.props.input.name} style={style.portalAltCircBackgroundLabel}>
                <AddPhotoAlternateIcon style={{fontSize: 32}}/>
                <input id={this.props.input.name} name="input" type="file" style={style.input} accept="image/*" onChange={(e) => this.submittedImg(e)}/>
              </label>
              </div>
              {isErrored && (<div style={style.errorMessage}>{this.props.meta.error} </div>)}
            </div>
          )  : this.props.type === 'cobrandHeader' ? (
            <div>
              <div style={[style.cobrandHeaderBackground, {backgroundImage: `url('${imageFromInput}')`}]} />
              <label htmlFor={this.props.input.name} style={style.cobrandHeaderBackgroundLabel}>
                <AddPhotoAlternateIcon style={{fontSize: 40}}/>
                <input id={this.props.input.name} name="input" type="file" style={style.input} accept="image/*" onChange={(e) => this.submittedImg(e)}/>
              </label>
            </div>
          ) : this.props.type === 'cobrandSectionTop' ? (
            <div>
              <div style={[style.cobrandSectionBackground, {backgroundImage: `url('${imageFromInput}')`}]} />
              <label htmlFor={this.props.input.name} style={style.cobrandSectionBackgroundLabel}>
                <AddPhotoAlternateIcon style={{fontSize: 40, position: 'absolute', top: '25%'}}/>
                <input id={this.props.input.name} name="input" type="file" style={style.input} accept="image/*" onChange={(e) => this.submittedImg(e)}/>
              </label>
            </div>
          ) : this.props.type === 'cobrandSectionBottom' ? (
            <div>
              <div style={[style.cobrandSectionBackground, {backgroundImage: `url('${imageFromInput}')`}]} />
              <label htmlFor={this.props.input.name} style={style.cobrandSectionBackgroundLabel}>
                <AddPhotoAlternateIcon style={{fontSize: 40, position: 'absolute', top: '65%'}}/>
                <input id={this.props.input.name} name="input" type="file" style={style.input} accept="image/*" onChange={(e) => this.submittedImg(e)}/>
              </label>
            </div>
          ) : ''}


      </div>
    )
  }
}

ImageInput.defaultProps = {
  variant: null,
  type: 'default',
  defaultValue: '//cloudfront.startupmedia.com/greenback/mgmtportal/circle-upload-background.jpg',
  ref: "cropper",
  buttonLabel: "Upload Image"
}

ImageInput = Radium(ImageInput);
export default withStyles(style)(ImageInput);
