import React, {Component} from 'react';
import {
  Terms,
} from './../../components';

const InternalTerms = (props) => {
  return (
    <Terms />
  )
}

export default InternalTerms;
