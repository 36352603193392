import React, {Component} from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import config from './../../config/config.provider';
import { crudGetOne, crudUpdate } from './../../aor-loopback/dataActions';
import Sticky from 'react-sticky-el';
import {toastr} from 'react-redux-toastr'
import {
  Card,
  GreenButton,
  DynamicComponents,
  ClayPhone,
  Edit,
  defaultPortal
} from './../';
import {
  entityMgrConnect,
  formEnhancer
} from './../../services';
import _ from 'lodash';
import { reduxForm } from 'redux-form';
import { getDefaultValues } from 'react-admin';

const style = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 18,
    fontFamily: 'ProximaNova-SemiBold',
  },
  content: {
    width: '100%',
    display: 'flex',
    marginTop: 20,
  },
  portalCol: {
    maxWidth: 450,
    minWidth: 300,
    width: '100%',
  },
  info: {
    lineHeight: 1.5,
    fontSize: 14,
    fontFamily: 'ProximaNova-Regular',
    marginBottom: 20,
  },
  footerSave: {
    marginTop: 20,
    textAlign: 'center',
  },
  previewCol: {
    width: 430,
    marginLeft: 40,
    position: 'relative',
    '@media (max-width: 640px)': {
      display: 'none',
    }
  },
  sticky: {
    position: 'fixed',
    top: 80,
  },
  phone: {
    marginTop: 20,
  },
  saveBtn: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  title: {
    fontSize: 18,
    fontFamily: 'ProximaNova-SemiBold',
    textAlign: 'center',
  }
}

class InAppPortal extends Component {

  forceRerender = () => {
    this.forceUpdate();
  }

  componentWillReceiveProps(nextProps, prevProps) {
    if (!_.get(nextProps, 'record.templating.logo') || !_.get(nextProps, 'record.templating.name')) {
      nextProps.submitLogoRequired();
    }
    else if (!_.get(nextProps, 'record.templating.portal')) {
      _.set(nextProps, 'record.templating.portal', defaultPortal.get());
    }
  }

  submit(values, action, props) {
    let portal = props.record.templating.portal;
    let realm = props.entityMgr.realm == 'entity' ? 'entity' : 'subentity';
    const _defaultPortal = defaultPortal.get();

    for (let tp = 0; tp < portal.components.length; tp++) {
      for (let dp = 0; dp < _defaultPortal.components.length; dp++) {
        let isPortalDefault = _.isEqual(portal.components[tp], _defaultPortal.components[dp]);

        if (isPortalDefault) {
          toastr.error('Failed to Save', 'Please complete all required fields before saving.');
          console.log("SAME", portal.components[tp], _defaultPortal.components[dp]);
          return;
        }
      }
    }

    props.entityMgr[realm].templating = props.record.templating;
    props.save({
      id: props.record.id,
      templating: props.record.templating
    }, `/campaigns/${props.record.id}`);
  }

  render() {
    return (
      <form>
        <Card content={style.header}>
        <div style={{display: 'flex', justifyContent: 'end', alignItems: 'center'}}>
          Edit In-App Campaign Portal
          </div>
          <GreenButton
            label="Save"
            small="true"
            onClick={this.props.handleSubmit(this.submit)}
          />
        </Card>
        <div style={style.content}>
          <div style={style.portalCol}>
            <Card root={style.info}>
              Use the interactive portal builder below to create your In-App Portal. Once you are finished you can review your portal to the right, and click "Save".
            </Card>
            <Card content={{padding:0}} root={{overflow:'hidden'}}>
              <DynamicComponents record={this.props.record} forceRerender={this.forceRerender}/>
            </Card>
            <Card root={style.footerSave}>
              <GreenButton
                label="Save"
                small="true"
                onClick={this.props.handleSubmit(this.submit)}
                style={style.saveBtn}
              />
            </Card>
          </div>
          <div style={style.previewCol}>
            <Card content={style.title}>
              In-App Portal Preview
            </Card>
            <Sticky  stickyStyle={{ transform: 'translateY(50px)' }}>
              <div style={style.phone}>
                <ClayPhone header scale={0.8}>
                  <DynamicComponents type="field" record={this.props.record} />
                </ClayPhone>
              </div>
            </Sticky>
          </div>
        </div>
      </form>
    )
  }
}

const CampaignInAppPortalEnhanced = formEnhancer({
  FormComponent: Radium(InAppPortal),
  formName: 'edit-portal'
});

const CampaignInAppPortal = (props) => {
  const entityMgr = _.get(props, 'entityMgr') || {}
  return (
    <Edit
      {...props}
      crudGetOne={crudGetOne}
      crudUpdate={crudUpdate}
      resource="campaigns"
      id={props.record.id}
      basePath="/"
      undoable={false}
    >
    <CampaignInAppPortalEnhanced entityMgr={entityMgr} submitLogoRequired={props.submitLogoRequired} />
  </Edit>
  )
}
export default entityMgrConnect(CampaignInAppPortal);
