import React from 'react';
import PropTypes from 'prop-types';

function TwitterIcon({fill, height}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 40 40">
      <g fill={fill} transform="translate(0 0)">
        <path d="M-142.808-1849.776a20,20,0,0,1-19.946-20,19.814,19.814,0,0,1,1.562-7.749,19.871,19.871,0,0,1,4.266-6.338,19.854,19.854,0,0,1,6.338-4.279,19.847,19.847,0,0,1,7.779-1.57,19.844,19.844,0,0,1,7.753,1.561,19.886,19.886,0,0,1,6.341,4.264,19.843,19.843,0,0,1,4.281,6.335,19.819,19.819,0,0,1,1.571,7.776,19.974,19.974,0,0,1-1.562,7.786,19.9,19.9,0,0,1-4.266,6.357,19.824,19.824,0,0,1-6.338,4.286A19.828,19.828,0,0,1-142.808-1849.776Zm-11.327-10.292h0a15.436,15.436,0,0,0,8.483,2.546,15.613,15.613,0,0,0,6.755-1.468,14.932,14.932,0,0,0,4.935-3.792,16.947,16.947,0,0,0,4.055-10.885c0-.254,0-.5-.014-.735a11.281,11.281,0,0,0,2.759-2.937,10.836,10.836,0,0,1-3.177.893,5.646,5.646,0,0,0,2.433-3.138,10.889,10.889,0,0,1-3.516,1.375,5.487,5.487,0,0,0-4.038-1.791,5.611,5.611,0,0,0-5.532,5.674,5.845,5.845,0,0,0,.142,1.292,15.279,15.279,0,0,1-6.4-1.765,15.781,15.781,0,0,1-5-4.166,5.8,5.8,0,0,0-.749,2.856,5.711,5.711,0,0,0,2.462,4.723,5.463,5.463,0,0,1-2.507-.708v.07a5.652,5.652,0,0,0,4.441,5.564,5.28,5.28,0,0,1-1.458.2,5.4,5.4,0,0,1-1.042-.1,5.549,5.549,0,0,0,5.17,3.939,10.861,10.861,0,0,1-6.873,2.43A11.267,11.267,0,0,1-154.135-1860.069Z" transform="translate(162.754 1889.712)"/>
      </g>
    </svg>
  );
}

TwitterIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
};

TwitterIcon.defaultProps = {
  fill: '#00AAEC',
  height: 40
};

export default TwitterIcon;
