import loopbackRestClient from './../../aor-loopback';
import config from './../../config/config.provider';
const restClient = loopbackRestClient(config)

export const FETCH_ENTITYMANAGER_BEGIN   = 'GGB/FETCH_ENTITYMANAGER_BEGIN';
export const FETCH_ENTITYMANAGER_SUCCESS = 'GGB/FETCH_ENTITYMANAGER_SUCCESS';
export const FETCH_ENTITYMANAGER_FAILURE = 'GGB/FETCH_ENTITYMANAGER_FAILURE';

export const GGB_LOGOUT = 'GGB/LOGOUT';

export const fetchEntitymanagerBegin = () => ({
  type: FETCH_ENTITYMANAGER_BEGIN
});

export const fetchEntitymanagerSuccess = data => ({
  type: FETCH_ENTITYMANAGER_SUCCESS,
  payload: { data }
});

export const fetchEntitymanagerFailure = error => ({
  type: FETCH_ENTITYMANAGER_FAILURE,
  payload: { error }
});

export const logout = () => ({
  type: GGB_LOGOUT
});

const initialState = {
  record: null,
  loading: false,
  error: null
};

export function fetchEntitymanagerById({id, action}) {
  return new Promise((resolve, reject) => {
    if (!id) return reject(new Error("NO_ID"));
    restClient('WITH_ROUTE','entitymanagers',{
      "route": `${id}/getWithParentSubentity`
    }).then(resolve).catch(reject);
  });
}

export function fetchByToken({token}) {
	return new Promise((resolve, reject) => {
		if (!token) return reject(new Error("NO_TOKEN"));
    restClient("WITH_ROUTE", "EntityManagers", {route:`getByAccessToken/${token}`}).then(({response}) => {
      (response.status == 200) ? resolve(response.json) : reject(response);
    }).catch(reject);
	});
}

export function getReporting({id}) {
  return new Promise((resolve, reject) => {
    if (!id) return reject(new Error("NO_ID"));
    restClient('WITH_ROUTE','entitymanagers',{
      "route": `${id}/getReporting`
    }).then(resolve).catch(reject);
  });
}

export default function entitymanagerReducer(state = initialState, action) {
  //console.log(state, action);
  // console.log(action);
  switch(action.type) {
    case FETCH_ENTITYMANAGER_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_ENTITYMANAGER_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: false,
        record: action.payload.data
      };
    case FETCH_ENTITYMANAGER_FAILURE:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere
      // Since it failed, we don't have items to display anymore, so set it empty.
      // This is up to you and your app though: maybe you want to keep the items
      // around! Do whatever seems right.
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        record: null
      };
    case GGB_LOGOUT:
      return {
        ...state,
        loading: true,
        error: null
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
