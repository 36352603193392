import React, {Component} from 'react';
import { Field } from 'redux-form';
import Radium from 'radium';
import config from './../../config/config.provider';
import { crudGetOne, crudUpdate } from './../../aor-loopback/dataActions';
import moment from 'moment';
import AutoScale from 'react-auto-scale';
import _ from 'lodash';
import {
  Card,
  GreenButton,
  FormCard,
  ImageInput,
  LabeledTextInput,
  CobrandedHomePreview,
  LabeledTextField,
  Edit,
  ExternalSiteWidget
} from './../../components';
import {
  entityMgrConnect,
  formEnhancer,
  cobrandLink,
  subscribePreviewToContentHeight
} from './../../services';
import { required } from './../../validators';

const style = {
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 18,
    fontFamily: 'ProximaNova-SemiBold',
  },
  content: {
    width: '100%',
    display: 'flex',
    marginTop: 0,
  },
  card: {
    marginBottom: 20,
  },
  formCol: {
    maxWidth: 450,
    minWidth: 375,
    width: '100%',
    '@media (max-width: 640px)': {
      minWidth: 300,
    }
  },
  views: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  viewBtn: {
    fontSize: 14,
    cursor: 'pointer',
    fontFamily: 'ProximaNova-SemiBold',
  },
  viewBtnDisabled: {
    color: '#23BFB8'
  },
  input: {
    marginBottom: 20,
  },
  footerSave: {
    marginTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  previewCol: {
    width: '100%',
    marginLeft: 20,
    '@media (max-width: 640px)': {
      display: 'none'
    }
  },
  preview: {
    marginTop: 20,
    width: '100%',
    overflow: 'hidden',
  },
  previewScaled: {
    width: 1200,
  },
  title: {
    fontSize: 18,
    fontFamily: 'ProximaNova-SemiBold',
    textAlign: 'center',
    marginBottom: 20,
  },
  id: {
    marginBottom: 10,
    textAlign: 'right',
    fontSize: 14,
    fontFamily: 'ProximaNova-SemiBold',
    color: '#ADB1BA'
  },
}

class CampaignOverviewForm extends Component {

  componentDidMount() {
    subscribePreviewToContentHeight();
  }

  submit(values, action, props) {
    props.save({
      id: props.record.id,
      templating: props.record.templating
    }, `/campaigns/${props.record.id}`);
  }

  render() {
    const entityMgr = this.props.entityMgr || {};
    const disabledByCampaignSite = !(_.get(this.props,'record.templating.logo')) ? true : false
    return (
      <form>
        <div style={style.content} id="content">
          <div style={style.formCol}>
            <Card root={style.card}>
              <div style={style.id}>ID: {_.get(this.props, 'record.id')}</div>
              <FormCard>
                <Field
                  {...this.props}
                  component={ImageInput}
                  name="templating.logo"
                  label="Campaign Logo"
                  variant="square"
                  defaultValue="//cloudfront.startupmedia.com/greenback/mgmtportal/circle-upload-background.jpg"
                  validate={required}
                  style={style.input}
                  size={300}
                />
                <Field
                  {...this.props}
                  name="templating.name"
                  component={LabeledTextInput}
                  label="Campaign Name*"
                  validate={required}
                  maxLength="50"
                />
              </FormCard>
              <GreenButton
                label="Save"
                small={true}
                onClick={this.props.handleSubmit(this.submit)}
                style={style.footerSave}
              />
            </Card>
            <Card root={style.card}>
              <div style={style.title}>Campaign Manager Details</div>
              <div style={style.input}>
                <LabeledTextField
                  label="Manager's Name"
                  value={`${_.get(this.props, 'record.entity_manager.first_name', '')} ${_.get(this.props, 'record.entity_manager.last_name', '')}`}
                />
              </div>
              <div>
                <LabeledTextField
                  label="Manager's Email Address"
                  value={_.get(this.props, 'record.entity_manager.email', '')}
                />
              </div>
            </Card>
            <ExternalSiteWidget
              root={style.card}
              title="Campaign Site"
              caption="View or copy the Campaign Site below."
              id="cobrand-site"
              successMsg="Campaign Site Link Copied"
              disabled={disabledByCampaignSite}
              url={cobrandLink({entity: _.get(this.props, 'entityMgr.entity.cause'), slug: _.get(this.props, 'record.slug')})}
            />
          </div>
          <div style={style.previewCol} id="preview">
            <Card content={style.title}>
              Campaign Site Preview
            </Card>
            <Card root={style.preview} content={{padding:0}}>
              <AutoScale>
                <div style={style.previewScaled}>
                  <CobrandedHomePreview subentity={this.props.record} entityMgr={this.props.entityMgr}/>
                </div>
              </AutoScale>
            </Card>
          </div>
        </div>
      </form>
    )
  }
}

const EnhancedCampaignOverview = formEnhancer({
  FormComponent: Radium(CampaignOverviewForm),
  formName: 'edit-campaign',
  onSubmit: () => {}
});

const CampaignOverview = (props) => {
  const entityMgr = _.get(props, 'entityMgr') || {};
  return (
    <Edit
      {...props}
      crudGetOne={crudGetOne}
      crudUpdate={crudUpdate}
      resource="campaigns"
      id={props.id}
      basePath="/campaigns"
      undoable={false}
    >
    <EnhancedCampaignOverview entityMgr={entityMgr}/>
  </Edit>
  )
}

export default entityMgrConnect(CampaignOverview);
