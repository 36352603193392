import React, { Component } from "react";
import loopbackRestClient from './../../aor-loopback';
import config from './../../config/config.provider';
import { propTypes, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { submit, reset } from 'redux-form';
import compose from 'recompose/compose'

import {
  GreenButton
} from './../../components';

import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  showNotification,
  hideNotification,
} from './../../actions/customNotification';

import { SubmissionError } from 'redux-form';

const apiClient = loopbackRestClient(config);

const style = {
  save: {
    marginTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center'
  },
  caption: {
    lineHeight: 1.5,
    fontSize: 14,
    fontFamily: 'ProximaNova-Regular',
    marginTop: 20,
  },
  error: {
    textAlign: 'center',
    color: '#f33',
    maxWidth: 300,
    fontSize: 14,
    lineHeight: 1.4,
    margin: '0 auto',
  },
  success: {
    textAlign: 'center',
    color: '#019490',
    maxWidth: 300,
    fontSize: 14,
    lineHeight: 1.4,
    margin: '0 auto',
  }
}

class AccountChangePassword extends Component {

  render() {
    const { error, pristine, reset, submitting, dispatch, handleSubmit, submitSucceeded, value } = this.props;

    return (

      <form onSubmit={handleSubmit} >
        {error && <p style={style.error}>{error}</p>}
        <div style={style.caption}>Change your password by using the button below to request a password reset email.</div>
        <GreenButton style={style.save} small="true" type="submit" primary disabled={submitting} label="Change Password" />
      </form>
    );
  }
};

let ChangePassword = reduxForm({
  form: 'change-password',
  onSubmit: (values, dispatch, props) => {
    console.log(values)
    return new Promise((resolve, reject) => {
      const resetErr = { _error: 'Reset Password failed!' };
      apiClient("WITH_ROUTE", "EntityManagers", {
        route:"reset",
        data: values,
        method: "POST"
      }).then(({response}) => {
        if (response.status === 204) {
          dispatch(reset('requestReset'));
          dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
              type: "success",
              title: "Password Change Sent",
              message: "Please check your email for instructions to change your password."
            }
          });
          resolve();
        }
        else {
          reject(new SubmissionError(resetErr));
        }
      }).catch((err) => {
        console.error(err);
        if (err.message.includes("not found")) {
          resetErr.email = config.response_messages.forgot_password_error_nonexistent;
        }
        reject(new SubmissionError(resetErr));
      });
    });
  }
})(AccountChangePassword);

ChangePassword = connect( state => ({
  initialValues: state.entityManagerReducer.record
}))(ChangePassword)

export default ChangePassword;
