import React from 'react';

export const style = {
  content: {
    maxWidth: 640,
    margin: '0 auto',
    textAlign: 'center',
  },
  title: {
    font: '36px ProximaNova-Light',
    '@media (max-width: 640px)': {
      fontSize: 24
    }
  },
  caption: {
    maxWidth: 400,
    width: '90%',
    margin: '30px auto 0 auto',
    font: '18px ProximaNova-Light',
    lineHeight: 1.5,
    '@media (max-width: 640px)': {
      fontSize: 14
    }
  },
  cardModifier: {
    padding: '60px 0',
    '@media (max-width: 640px)': {
      padding: '20px 0',
    }
  },
  cardModifierLogin: {
    padding: '60px 0px 15px 0px',
    '@media (max-width: 640px)': {
      padding: '20px 0',
    }
  },
  form: {
    width: 300,
    margin: '30px auto 0 auto',
  },
  input: {
    marginBottom: 15,
  },
  submit: {
    marginTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  links: {
    marginTop: 30,
  },
  href: {
    font: '14px ProximaNova-Regular',
    color: '#23BFB8',
    textDecoration: 'none',
    margin: '0 5px',
    '&:hover': {
      color: '#167773',
    }
  },
  error: {
    font: '14px ProximaNova-SemiBold',
    textAlign: 'center',
    color: '#f33',
    maxWidth: 300
    },
    success: {
      font: '14px ProximaNova-SemiBold',
    textAlign: 'center',
    color: '#019490',
    maxWidth: 300
    },
}

export const validators = {
  required: value => value ? undefined : 'Required',
  email: value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
'Invalid email address' : undefined
};
