import React from 'react';
import {
    Datagrid,
    NumberField,
    TextField,
    TextInput,
    CreateButton,
    RefreshButton
} from 'react-admin';
import {
  ListManage,
  ListAvatar,
  ListView,
  ListName,
  ListStatus
} from './../../components';
import {
  getFromNestedSafely as gS,
  entityMgrConnect
} from './../../services'

let style = {

}

const BoosterList = (props) => {
  return (
    <Datagrid bodyOptions={{ stripedRows: true, showRowHover: true }} {...props}>
      <ListStatus style={{width:20}} label="Active" source="templating.fundraising" />
      <ListAvatar source="templating.logo" label=""/>
      <ListName source="templating.name" maxLength={25} label="Name"/>
      <NumberField source="invitations_sent" label="Invites Sent"/>
      <NumberField source="earnings" options={{ style: 'currency', currency: 'USD' }} label="Funds Raised"/>
      <ListManage resource="boosters"/>
    </Datagrid>
  )
}


export default BoosterList;
