import storage from './storage';
import getHttpErrorFromResponse from './getHttpErrorFromResponse';

export const authClient = (loginApiUrl, noAccessPage = '/login') => {

  return (type, params) => {
    if (type === 'AUTH_LOGIN') {

      const request = new Request(loginApiUrl, {
        method: 'POST',
        body: JSON.stringify(params),
        headers: new Headers({ 'Content-Type': 'application/json' }),
      });

      return fetch(request)
        .then(response => response.text().then(text => ({
					status: response.status,
					statusText: response.statusText,
					headers: response.headers,
					body: text,
        })))
        .then(({ status, statusText, headers, body }) => {
					let json;
					try {
							json = JSON.parse(body);
					} catch (e) {
							// not json, no big deal
					}
					if (status < 200 || status >= 300) {
            const err = getHttpErrorFromResponse({json, statusText, status})
            throw err;
					}
					return json;
        })
        .then(({ ttl, ...data }) => {
          storage.save('lbtoken', data, ttl);
        });
    }
    if (type === 'AUTH_LOGOUT') {
      storage.remove('lbtoken');
      return Promise.resolve();
    }
    if (type === 'AUTH_ERROR') {
      const { status } = params;
      if (status === 401 || status === 403) {
        storage.remove('lbtoken');
        return Promise.reject();
      }
      return Promise.resolve();
    }
    if (type === 'AUTH_CHECK') {
      const token = storage.load('lbtoken');
      if (token && token.id) {
        return Promise.resolve();
      }
      else {
        storage.remove('lbtoken');
        return Promise.reject({ redirectTo: noAccessPage });
      }
    }
    if (type === 'AUTH_GET_PERMISSIONS') {
      const token = storage.load('lbtoken') || {};
      return Promise.resolve(token.entityMgr && token.entityMgr.realm);
    }
    return Promise.reject('Unkown method');
  };
};
