import React, {lazy, Suspense} from 'react';
import {entityMgrConnect, getFromNestedSafely as gS } from './../../services';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Field, FieldArray } from 'redux-form';
import _ from 'lodash';
import { defaultPortal } from './../';

String.prototype.toPascalCase = function() {
  return _.upperFirst(_.camelCase(this));
}

String.prototype.toTypedName = function(type) {
  return `${this}${_.upperFirst(type)}`;
}

class DynamicComponents extends React.Component {

  lazyLoadedComponents = {};

  render() {

    const templating = gS(this.props, 'record.templating') || {};
    const templatingComponents = gS(templating, 'portal.components') || [];

    const {type = "input"} = this.props;

    const components = templatingComponents.map((c,i) => {
      if (!c.name) return;
      const name = c.name.toPascalCase().toTypedName(type);
      //prevents unecessecary imports
      if (!this.lazyLoadedComponents[name]) {
        console.log(`loading ../InAppPortal/${type}/${name}`);
        this.lazyLoadedComponents[name] = lazy(() => import(`../InAppPortal/${type}/${name}`));
      }
      const Component = this.lazyLoadedComponents[name];
      return (
        <Suspense key={name} fallback={<CircularProgress/>}>
          <Component key={name} index={i} {...c} {...this.props}/>
        </Suspense>
      );
    })
    return <div>{components}</div>;
  }
}

export default DynamicComponents;
