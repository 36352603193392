import React from 'react';
import { connect } from 'react-redux';
import {
  Datagrid,
  Filter,
  List,
  TextInput,
  CreateButton,
  RefreshButton
} from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import {
  ListManage,
  ListAvatar,
  ListName,
  ListStatus,
  Card
} from './../../components';
import _ from 'lodash';
import { entityMgrConnect } from './../../services'

const style= {
  list: {
    minWidth: 495,
  },
  title: {
    marginBottom: 20,
    fontSize: 18,
    fontFamily: 'ProximaNova-SemiBold',
  }
}

const SubentityFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="where.templating.name.like" alwaysOn />
  </Filter>
);

const PostActions = ({ resource, filters, displayedFilters, filterValues, basePath, showFilter }) => (
  <CardActions>
    {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
    <CreateButton basePath={basePath} label="Create Campaign"/>
    <RefreshButton />
  </CardActions>
);

export const CampaignList = (props, record) => {
  return (
  <div style={style.list}>
    <Card root={style.title}>Campaigns</Card>
    <List {...props}
      actions={<PostActions />}
      sort={{ field: 'templating.name', order: 'ASC' }}
      perPage={10}
      bulkActionButtons={false}
      title="Campaigns"
      filters={<SubentityFilter />}
    >
      <Datagrid bodyOptions={{ stripedRows: true, showRowHover: true }}>
        <ListStatus style={{width:20}} label="Active" source="templating.fundraising" />
        <ListAvatar source="templating.logo" label=""/>
        <ListName
          source="templating.name"
          maxLength={50}
          label="Name"
          />
        <ListManage />
      </Datagrid>
    </List>
  </div>
  )
}

export default entityMgrConnect(CampaignList);
