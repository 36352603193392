import React, {Component} from 'react';
import {
  ExternalLayout,
  Privacy
} from './../components';

const ExternalPrivacy = (props) => {
  return (
    <ExternalLayout>
      <Privacy />
    </ExternalLayout>
  )
}

export default ExternalPrivacy;
