import React from 'react';
import PropTypes from 'prop-types';
import Radium from 'radium';

let style = {
  wrapper: {
    position: 'relative',
    height: '100%',
    transformOrigin: 'center top',
    // transform: 'scale(0.8)'
  },
  phone: {
    position: 'relative',
    width: 426,
    zIndex: 10,
    pointerEvents: 'none',

  },
  container: {
    position: 'absolute',
    top: 17,
    left: 32,
    width: 375,
    height: 812,
    borderRadius: 25,
    background: '#FFF',
    zIndex: 0,
    overflowX: 'hidden',
    overflowY: 'scroll',
  },
  header: {
    height: 105,
    background: '#1E5E75',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    height: 35,
    width: 'auto',
    display: 'block',
    marginTop: 30,
  },
  content: {
    position: 'relative',
  }
}

const ClayPhone = ({children, ...props}) => {
  style.wrapper.transform = props.scale ? 'scale('+props.scale+')' : '';
  return (
    <div style={style.wrapper}>
      <img style={style.phone} src="//cloudfront.startupmedia.com/greenback/devices/iphone-x-clay-transparent.png" />
      <div style={style.container}>
        {props.header ? (
          <div style={style.header}>
            <img style={style.logo} src="//cloudfront.startupmedia.com/greenback/logos/gb-logo-icon-light.png" />
          </div>
        ) : ''}
        <div style={style.content}>
          {children}
        </div>
      </div>
    </div>
  )
}

export default Radium(ClayPhone);
