export const SHOW_NOTIFICATION = 'GGB/SHOW_NOTIFICATION';
export const HIDE_NOTIFICATION = 'GGB/HIDE_NOTIFICATION';

const defaultState = {
  text: '',
  type: 'info', // one of 'info', 'confirm', 'warning'
};

export const getDispatchers = ({title}) => ({ 
  success: ({dispatch, message}) => {
    dispatch({
      type: SHOW_NOTIFICATION,
      payload: {
        type: "success",
        title,
        message
      }
    });
  },
  error: ({dispatch, message}) => {
    dispatch({
      type: SHOW_NOTIFICATION,
      payload: {
        type: "error",
        title,
        message
      }
    });
  }
});

export const showNotification = (text, type = 'info') => ({
  type: SHOW_NOTIFICATION,
  payload: { text, type },
});

export const hideNotification = () => ({
  type: HIDE_NOTIFICATION,
});

const customNotificationReducer = (previousState = defaultState, { type, payload }) => {
  switch (type) {
    case SHOW_NOTIFICATION:
      return { text: payload.text, type: payload.type };
    case HIDE_NOTIFICATION:
      return { ...previousState, text: '' };
    default:
      return previousState;
  }
};

export default customNotificationReducer; 
