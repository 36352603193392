import React from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Icon from '@material-ui/core/Icon';
import { MenuItemLink } from 'react-admin';
import {Link} from 'react-router-dom';
import Divider from "@material-ui/core/Divider";

const MenuItem = ({classes, ...props}) => {
  return (
    <ListItem
      button
      className={props.nested ? classes.nestedItem : classes.item}
      disabled={props.disabled}
    >
    {props.to && (
    <Icon className={props.active == props.to ? `${classes.icon} ${classes.activeIcon}` : classes.icon}>{props.icon}</Icon>
    )}
    {props.href && (
    <Icon className={props.active == props.href ? `${classes.icon} ${classes.activeIcon}` : classes.icon}>{props.icon}</Icon>
    )}
    {props.to && (
      <Link
        to={props.to}
        onClick={props.setActive}
        className={props.active == props.to ? `${classes.link} ${classes.activeLink}` : classes.link}
      >
        {props.name}
      </Link>
    )}
    {props.href && (
      <a
        href={props.href}
        className={props.active == props.href ? `${classes.link} ${classes.activeLink}` : classes.link}
        target={props.target ? props.target : '_blank'}
      >
      {props.name}
    </a>
    )}

    </ListItem>
  )
}

export default MenuItem
