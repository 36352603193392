export const FIELDARRAY_DIALOG_SUBMISSION_BEGIN   = 'GGB/FIELDARRAY_DIALOG_SUBMISSION_BEGIN';
export const FIELDARRAY_DIALOG_SUBMISSION_SUCCESS = 'GGB/FIELDARRAY_DIALOG_SUBMISSION_SUCCESS';
export const FIELDARRAY_DIALOG_SUBMISSION_FAILURE = 'GGB/FIELDARRAY_DIALOG_SUBMISSION_FAILURE';
export const FIELDARRAY_DIALOG_SUBMISSION_MUTATE = 'GGB/FIELDARRAY_DIALOG_SUBMISSION_MUTATE';

export const fieldArrayDialogSubmissionBegin = data => ({
  type: FIELDARRAY_DIALOG_SUBMISSION_BEGIN,
  payload: { data }
});

export const fieldArrayDialogSubmissionSuccess = data => ({
  type: FIELDARRAY_DIALOG_SUBMISSION_SUCCESS,
  payload: { data }
});

export const fieldArrayDialogSubmissionFailure = error => ({
  type: FIELDARRAY_DIALOG_SUBMISSION_FAILURE,
  payload: { error }
});

export const fieldArrayDialogSubmissionMutate = data => ({
  type: FIELDARRAY_DIALOG_SUBMISSION_MUTATE,
  payload: { data }
});

const initialState = {
  record: null,
  loading: false,
  error: null,
};

export default function fieldarraydialogReducer(state = initialState, action) {
  switch(action.type) {
    case FIELDARRAY_DIALOG_SUBMISSION_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        //error: null
      };
    case FIELDARRAY_DIALOG_SUBMISSION_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: false,
        record: action.payload.data,
        error: null
      };
    case FIELDARRAY_DIALOG_SUBMISSION_FAILURE:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere
      // Since it failed, we don't have items to display anymore, so set it empty.
      // This is up to you and your app though: maybe you want to keep the items
      // around! Do whatever seems right.
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        record: null
      };
    case FIELDARRAY_DIALOG_SUBMISSION_MUTATE:
      const { data } = action.payload;
      return {
        ...state,
        ...data,
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
