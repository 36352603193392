import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom'
import './index.css';
import App from './App';
import {StyleRoot} from 'radium';

import injectTapEventPlugin from 'react-tap-event-plugin';
import {JssProvider, createGenerateClassName} from 'react-jss'

const generateClassName = createGenerateClassName()

try {
    injectTapEventPlugin();
} catch (e) {
    // do nothing
}

ReactDOM.render(
  <JssProvider generateClassName={generateClassName}>
    <BrowserRouter >
      <StyleRoot>
        <App />
      </StyleRoot>
    </BrowserRouter>
  </JssProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
