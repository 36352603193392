const knownNounMapping = {
  "entity": "entities",
  "subentity": "subentities",
  "unit": "units",
  "subunit": "subunits",
};

const knownNounMappingFlipped = Object.entries(knownNounMapping)
  .map(a => a.reverse())
  .reduce((prev,curr) => {prev[curr[0]]=curr[1];return prev;},{}); 

export function pluralizer(noun, {depluralize = false}) {
  if (depluralize) {
    return knownNounMappingFlipped[noun] || noun;
  }
  else {
    return knownNounMapping[noun] || noun;
  }
}

export function singularizer(noun) {
  return pluralizer(noun, {depluralize: true});
}
