import React, {Component} from 'react';
import loopbackRestClient from './../../aor-loopback';
import config from './../../config/config.provider';
import {connect} from 'react-redux';
import {Field, FieldArray, SubmissionError} from 'redux-form';
import { Link } from 'react-router-dom';
import { submit, reset } from 'redux-form';
import Radium from 'radium';
import Icon from '@material-ui/core/Icon';
import {
  GreenButton,
  FormCard,
  LabeledTextInput
} from './../';
import {
  getFromNestedSafely as gS,
  formEnhancer,
  entityMgrConnect
} from './../../services';
import { required, email } from './../../validators';

const apiClient = loopbackRestClient(config);

const termsAgreementValidator = value => {
  return !value
    ? 'Must agree to the terms.'
    : undefined;
}

const style = {
  error: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: 14,
    color: '#f33',
    maxWidth: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 0,
    marginBottom: 0,
  },
  success: {
    fontWeight: 'bold',
    fontSize: 14,
    textAlign: 'center',
    color: '#019490',
    maxWidth: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 0,
    marginBottom: 0,
  },
  input: {
    marginBottom: 10,
  },
  link: {
    color: '#2CBBB7',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
  },
  linkIcon: {
    marginLeft: 5,
    cursor: 'pointer',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
    fontSize: 14,
    fontFamily: 'ProximaNova-SemiBold',
    color: '#2CBBB7',
    justifyContent: 'space-between',
  },
}

class DISFormWidgetForm extends Component {

  render() {
    const entityMgr = this.props.entityMgr || {};
    const {handleSubmit, submitting, error, submitSucceeded, disabled} = this.props;
    return (
      <form onSubmit={handleSubmit}>
          <Field component={LabeledTextInput} style={style.input} label="Recipient’s Name" name="leads[0].first_name" validate={required}/>
          <Field component={LabeledTextInput} style={style.input} label="Recipient’s Email" type="email" name="leads[0].email" validate={[required, email]}/>

          {error && <p style={style.error}>{error}</p>}
          {submitSucceeded && !error && <p style={style.success}>Hooray! Your invite is on its way. Click Invite More to send multiple invitations at the same time!</p>}


        <div style={style.footer}>
          <GreenButton label="Send Invite" small="true" type="submit" disabled={this.props.isLoading}/>
          <Link to='/dis' style={style.link}>
            Invite More
            <Icon style={style.linkIcon}>keyboard_arrow_right</Icon>
          </Link>
        </div>
      </form>
    )
  }
}

const DISFormWidget = formEnhancer({
  FormComponent: Radium(DISFormWidgetForm),
  formName: 'dis-widget',
  onSubmit: (data, dispatch, props) => {
    return new Promise((resolve, reject) => {
      let defaultErr = {_error: "Error with submission"};
      apiClient("CREATE", "EntityLeads", {data}).then((args) => {
        const { response } = args;
        if (response.status === 200) {
          dispatch(reset('dis-widget'));
          resolve();
        }
        else {
          console.log(data)
          reject(new SubmissionError(defaultErr));
        }
      }).catch((err) => {
        console.log(data)
        reject(new SubmissionError({_error: err.message}));
      });
    });
  }
});

export default DISFormWidget;
