import React from 'react';
import Joyride from 'react-joyride';


const TourWizard = (props) => {
  return (
    <Joyride
      {...props}
      disableCloseOnEsc={true}
      disableOverlayClose={true}
      spotlightClicks={true}
      floaterProps= {{
        disableAnimation: 'true',
      }}
      disableScrolling='true'
      locale={{ back: 'Back', close: 'Close', last: 'Okay!', next: 'Next', skip: 'Skip' }}
      styles={{
        options: {
          primaryColor: '#FF6200',
          spotlightShadow: '2px 2px 6px rgba(0,0,0,0.2)',
          filter: 'drop-shadow(rgba(255, 98, 0, 0.5) 0px 0px 3px)',
          // zIndex: 600,
        },
        overlay: {
          // pointerEvents: 'auto'
        },
        tooltip: {
          filter: 'drop-shadow(rgba(44, 187, 183, 0.5) 0px 0px 3px)'
        },
        tooltipTitle: {
          fontSize: 18,
          fontFamily: 'ProximaNova-SemiBold'
        },
        tooltipContent: {
          fontSize: 14,
          lineHeight: 1.5,
          fontFamily: 'ProximaNova-Regular'
        },
        buttonNext: {
          paddingLeft: 20,
          paddingRight: 20,
          fontFamily: "'ProximaNova-SemiBold'",
          fontSize: 12,
          letterSpacing: 1,
          textTransform: 'uppercase',
          boxShadow: '0 8px 8px 0 rgba(255,98,0,0.10)',
          borderRadius: 20,
          outline: 'none',
        },
        buttonBack: {
          paddingLeft: 20,
          paddingRight: 20,
          fontFamily: "'ProximaNova-SemiBold'",
          fontSize: 12,
          letterSpacing: 1,
          textTransform: 'uppercase',
          outline: 'none',
        },
        buttonClose: {
          outline: 'none',
        },
        buttonSkip: {
          // paddingLeft: 20,
          // paddingRight: 20,
          // fontFamily: "'ProximaNova-SemiBold'",
          // fontSize: 12,
          // letterSpacing: 1,
          // textTransform: 'uppercase',
          outline: 'none',
        }
      }}
    />
  )
}

export default TourWizard;
