import mapAgeCleaner from './mapAgeCleaner'; 

const isPromise = input => (
  input instanceof Promise ||
  (
   input !== null &&
   typeof input === 'object' &&
   typeof input.then === 'function' &&
   typeof input.catch === 'function'
  )
);

const mimicFn = (to, from) => {
  for (const prop of Reflect.ownKeys(from)) {
    Object.defineProperty(to, prop, Object.getOwnPropertyDescriptor(from, prop));
  }

  return to;
};

const cacheStore = new WeakMap();

const defaultCacheKey = (...arguments_) => {
  if (arguments_.length === 0) {
    return '__defaultKey';
  }

  if (arguments_.length === 1) {
    const [firstArgument] = arguments_;
    if (
      firstArgument === null ||
      firstArgument === undefined ||
      (typeof firstArgument !== 'function' && typeof firstArgument !== 'object')
    ) {
      return firstArgument;
    }
  }

  return JSON.stringify(arguments_);
};

function mem(fn, options) {
  options = Object.assign({
    cacheKey: defaultCacheKey,
    cache: new Map(),
    cachePromiseRejection: false
  }, options);

  if (typeof options.maxAge === 'number') {
    mapAgeCleaner(options.cache);
  }

  const {cache} = options;
  options.maxAge = options.maxAge || 0;

  const setData = (key, data) => {
    cache.set(key, {
      data,
      maxAge: Date.now() + options.maxAge
    });
  };

  const memoized = function (...arguments_) {
    const key = options.cacheKey(...arguments_);

    if (cache.has(key)) {
      return cache.get(key).data;
    }

    const cacheItem = fn.call(this, ...arguments_);

    setData(key, cacheItem);

    if (isPromise(cacheItem) && options.cachePromiseRejection === false) {
      // Remove rejected promises from cache unless `cachePromiseRejection` is set to `true`
      cacheItem.catch(() => cache.delete(key));
    }

    return cacheItem;
  };

  try {
    // The below call will throw in some host environments
    // See https://github.com/sindresorhus/mimic-fn/issues/10
    mimicFn(memoized, fn);
  } catch (_) {}

  cacheStore.set(memoized, options.cache);

  return memoized;
};

mem.prototype.clear = fn => {
  const cache = cacheStore.get(fn);

  if (cache && typeof cache.clear === 'function') {
    cache.clear();
  }
};

const memoizedFunctions = new WeakMap();


function pMemoize(fn, options) {
  const memoized = mem(fn, options);

  const memoizedAdapter = function (...arguments_) {
    return memoized.apply(this, arguments_);
  };

  mimicFn(memoizedAdapter, fn);
  memoizedFunctions.set(memoizedAdapter, memoized);

  return memoizedAdapter;
};

pMemoize.prototype.clear = memoized => {
  if (!memoizedFunctions.has(memoized)) {
    throw new Error('Can\'t clear a function that was not memoized!');
  }
  mem.clear(memoizedFunctions.get(memoized));
};

export default pMemoize;
