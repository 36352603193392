import React, {Component} from 'react';
import { style, validators } from './shared';
import loopbackRestClient from './../aor-loopback';
import storage from './../aor-loopback/storage';
import config from './../config/config.provider';
import {propTypes, reduxForm, Field, submit, reset, SubmissionError} from 'redux-form';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import Radium from 'radium';
import {
  translate,
  userLogin as userLoginAction,
} from 'react-admin';
import {
  ExternalLayout,
  Card,
  LabeledTextInput,
  GreenButton
} from './../components';
import {Link} from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import { fetchByToken } from './../actions/entityManager';

const apiClient = loopbackRestClient(config);
const { required } = validators;

class ResetPassword extends Component {

  state = {
    entityMgr: undefined,
    loading: true,
  }

  async componentDidMount() {
    const token = this.props.match.params.token
    fetchByToken({token}).then((entityMgr) => {
      storage.save('lbtoken', {id: this.props.match.params.token}, 604800);
      this.setState({entityMgr, loading: false});
    }).catch((err) => {
      console.log(err);
      this.setState({entityMgr: undefined, loading: false});
    });
  }

  onSubmit(e, dispatch) {
    e.nativeEvent.preventDefault();
    dispatch(submit('reset'))
  }

  render() {
    if (this.state.entityMgr) {
      const {
        error,
        pristine,
        reset,
        submitting,
        dispatch,
        handleSubmit,
        submitSucceeded
      } = this.props;
      return (
        <ExternalLayout>
          <div style={style.content}>
            <Card content={style.cardModifier}>
              <div style={style.title}>Create New Password</div>
              <div style={style.caption}>Create and confirm your new password below.</div>
              <form style={style.form}>
                {error && <p style={style.error}>{error}</p>}
                {submitSucceeded && pristine && <p style={style.success}>{config.response_messages.reset_password_success}</p>}
                <Field
                  component={LabeledTextInput}
                  name="newPassword"
                  label="New Password"
                  type="password"
                  style={style.input}
                />
                <Field
                  component={LabeledTextInput}
                  name="newPassword_confirm"
                  label="Confirm New Password"
                  type="password"
                  style={style.input}
                />
                <GreenButton
                  type="submit"
                  style={style.submit}
                  disabled={submitting}
                  label="Create Password"
                  small
                  onClick={(e) => { this.onSubmit(e, dispatch) } }
                />
              <div style={style.links}>
                <Link style={style.href} to="/login">Back to Login</Link>
              </div>
              </form>
            </Card>
          </div>
        </ExternalLayout>
      )
    } else if (!this.state.loading) {
      return (
        <ExternalLayout>
          <div style={style.content}>
            <Card content={style.cardModifier}>
              <div style={style.title}>Uh oh! This password reset link has expired.</div>
              <div style={style.caption}>Please request a new link below.</div>
              <div style={style.links}>
                <Link style={style.href} to="/forgot_password">Reset Password</Link>
              </div>
            </Card>
          </div>
        </ExternalLayout>
      )
    } else {
      return (
        <ExternalLayout>
          <div style={style.content}>
            <Card content={style.cardModifier}>
              <div style={style.caption}>Loading...</div>
            </Card>
          </div>
        </ExternalLayout>
      )
    }
  }
};

export default reduxForm({
  form: 'reset',
  validate: (values, props) => {
    const errors = {};
    if (!values.newPassword)
      errors.newPassword = "Required.";
    else if (!values.newPassword_confirm)
      errors.newPassword_confirm = "Required.";
    else if (values.newPassword !== values.newPassword_confirm)
      errors.newPassword_confirm = "Passwords do not match.";
    return errors;
  },
  onSubmit: (values, dispatch, props) => {
    return new Promise(async (resolve, reject) => {
      const {response} = await apiClient("WITH_ROUTE", "EntityManagers", {
        route:"reset-password",
        data: values,
        method: "POST"
      });
      if (response.status === 204) {
        dispatch(reset('reset'));
        resolve();
      }
      else {
        reject(new SubmissionError({ _error: config.response_messages.reset_password_error}));
      }
    });
  }
})(ResetPassword);
