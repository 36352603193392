import React from 'react';
import PropTypes from 'prop-types';

function EmailIcon({fill, height}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 255.588 255.59">
      <g id="email" transform="translate(0)">
        <g id="Group_745" data-name="Group 745" transform="translate(0)">
          <g id="Group_743" data-name="Group 743">
            <path id="Path_298" data-name="Path 298" d="M274.688,127.592c0-70.3-57.294-127.592-128-127.592C76.394,0,19.1,57.294,19.1,127.592c0,70.7,57.294,128,127.591,128A127.982,127.982,0,0,0,274.688,127.592Z" transform="translate(-19.1)" fill={fill}/>
          </g>
          <g id="Group_744" data-name="Group 744" transform="translate(39.821 71.402)">
            <path id="Path_299" data-name="Path 299" d="M196.719,16.6H28.9L91.476,79.99l22.755,86.551L199.969,22.289,203.22,16.6Zm-11.784,6.5L94.727,74.3,44.747,23.1ZM116.67,149.881,98.384,80.4l89.8-51.606Z" transform="translate(-28.9 -16.486)" fill="#fff"/>
            <path id="Path_300" data-name="Path 300" d="M153.535,16.572,44.991,77.54Z" transform="translate(20.392 -16.572)" fill="#fff"/>
          </g>
        </g>
      </g>
    </svg>


  );
}

EmailIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
};

EmailIcon.defaultProps = {
  fill: '#FF4C61',
  height: 40
};

export default EmailIcon;
