import HttpError from './HttpError';
import config from './../config/config.provider';

const getHttpErrorFromResponse = ({json, statusText, status}) => {
  console.log(json.error.code, config.error_code_translations, config.error_code_translations[json.error.code]);
  if(json &&
    json.error &&
    json.error.code &&
    config.error_code_translations &&
    config.error_code_translations[json.error.code]) {
    //allows configuration translatable errors to remain logged in
    //401,403 codes automatically log user out
    if (status == 401) status = 422;
    console.log(json.error.code);
    return new HttpError(config.error_code_translations[json.error.code], status, json.error.code);
  }
  else {
    return new HttpError(statusText, status);
  }
}

export default getHttpErrorFromResponse;
