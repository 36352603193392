import React, {Component} from 'react';
import {Field} from 'redux-form';
import {
  LabeledTextInput,
  GreenButton,
  Edit,
  ImageInput
} from './../';
import {
  formEnhancer,
  entityMgrConnect,
  getFromNestedSafely as gS,
} from './../../services';
import { crudGetOne, crudUpdate } from './../../aor-loopback/dataActions';
import { required, url } from './../../validators';

const style = {
  save: {
    margin: '30px auto 0',
    textAlign: 'center'
  },
  input: {
    marginBottom: 20,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  form: {
    width: '100%',
    display: 'block',
    justifyContent: 'space-around',
  }
}

class ProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  submitDetails(values, action, props) {
    props.entityMgr.entity.templating = props.record.templating;
    props.save(values, `${props.basePath}`);
  }

  render() {
    const entityMgr = this.props.entityMgr || {};
    const is_sponsor = gS(entityMgr, 'entity.cause.is_sponsor');

    return (
      <div style={style.form}>
      <form id="profile">
        <Field
          {...this.props}
          name="templating.logo"
          component={ImageInput}
          label="Logo"
          validate={required}
          style={style.input}
          variant="square"
          buttonLabel="Upload Logo"
          defaultValue="//cloudfront.startupmedia.com/greenback/mgmtportal/circle-upload-background.jpg"
          size={300}
        />
        <Field
          {...this.props}
          name="gb_cause.name"
          component={LabeledTextInput}
          label="Name"
          validate={required}
        />
        <Field
          {...this.props}
          name="gb_cause.description"
          component={LabeledTextInput}
          multiline={true}
          label="Description or Mission"
          validate={required}
        />
        <Field
          {...this.props}
          name="gb_cause.website"
          component={LabeledTextInput}
          label="Organization's Website"
          validate={url}
        />
        {!is_sponsor && (
          <Field
            {...this.props}
            name="gb_cause.paypal"
            component={LabeledTextInput}
            label="PayPal Email Address"
            validate={required}
            disabled
          />
        )}
        <GreenButton
          label="Save Profile"
          onClick={this.props.handleSubmit(this.submitDetails)}
          style={style.save}
          small
        />
      </form>
      </div>
    )
  }
}

const EnhanceProfileForm = formEnhancer({
  FormComponent: ProfileForm,
  formName: 'profile'
});

const AccountProfile = (props) => {
  const entityMgr = gS(props, 'entityMgr') || {};
  return (
    <Edit
      crudGetOne={crudGetOne}
      crudUpdate={crudUpdate}
      resource="entities"
      id={entityMgr.entity_id}
      basePath="/account"
      undoable={false}
    >
      <EnhanceProfileForm entityMgr={entityMgr}/>
    </Edit>
  )
};

export default entityMgrConnect(AccountProfile);
