import React from "react";
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Icon from '@material-ui/core/Icon';
import LinearProgress from '@material-ui/core/LinearProgress';
import PropTypes from "prop-types";
import IconsStorage from "./IconsStorage";
import Radium from "radium";
import { GreenButton } from './../';

let style={
  iconButton: {

  },
  header: {

  },
  title: {
    font: '26px ProximaNova-Light',
    textAlign: 'center',
  },
  search: {
    marginTop: 20,
    border: '1px solid #ADB1BA',
    borderRadius: 3,
    padding: 5,
    display: 'flex',
    alignItems: 'center',
  },
  searchIcon: {
    fontSize: 20,
    marginRight: 10,
  },
  searchInput: {
    outline: 'none',
    border: 'none',
    font: '16px ProximaNova-Regular',
    width: '100%',
  },
  clearIcon: {
    cursor: 'pointer',
  },
  iconsGrid: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  icon: {
    position: 'relative',
    textAlign: 'center',
    width: '25%',
    flexGrow: 1,
    marginBottom: 10,
    height: 120,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  iconItem: {
    color: '#1E5E75',
    fontSize: 48,
    width: 48,
    height: 48,
    marginBottom: 10,
    zIndex: 10,
  },
  iconCaption: {
    position: 'relative',
    fontSize: 14,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    zIndex: 2,
    maxWidth: 100,
  },
  box: {
    backgroundColor: '#E5F6F5',
    borderRadius: 3,
    height: 120,
    opacity: 0,
    position: 'absolute',
    top: 0,
    transitionProperty: 'opacity',
    transitionDuration: '150ms',
    transitionTimingFunction: 'ease-out',
    width: 112,
    marginLeft: 'calc(112px / 2)',
    transform: 'translateX(-25%)',
    transitionDelay: 'initial'
  },
  selectedBox: {
    opacity: 1
  },
  noIcons: {
    width: '70%',
    marginTop: 40,
    marginBottom: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
    font: '32px ProximaNova-Regular',
    lineHeight: 1.4,
  },
  actions: {
    marginBottom: 10,
    marginTop: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  dialogClose: {
    position: 'absolute',
    top: 10,
    right: 10,
    fontFamily: 'ProximaNova-Regular',
    fontSize: 18,
    color: '#E00A1F',
    textTransform: 'uppercase',
    cursor: 'pointer'
  },
  error: {
    position: 'absolute',
    left: -10,
    top: -10,
    width: 'calc(100% + 20px)',
    height: 'calc(100% + 25px)',
    outline: '2px solid #E00A1F',
    fontSize: 16,
    color: '#E00A1F',
    marginTop: 10,
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    paddingBottom: 10,
    background: 'rgba(255, 0, 0, 0.05)'
  }
}

class IconPicker extends React.Component {
	constructor(props) {
		super(props);

		 this.state = {
			pickerDialogOpen: false,
			_icons: [],
			icons: [],
			icon: null,
      selected: this.props.input.value || null
		};
    this.iconsStorage = new IconsStorage();
	}

	componentDidMount() {
		this.iconsPromise = this.iconsStorage.getIcons();
		this.iconsPromise.then(icons => this.showIcons(icons));
	}

	showIcons(icons) {
		this.setState({
			pickerDialogOpen: this.state.pickerDialogOpen,
			_icons: icons,
			icons: icons
	   });
	}

	handleOpen() {
	   this.setState({
			pickerDialogOpen: true,
			_icons: this.state._icons,
			icons: this.state.icons,
			selected: this.state.selected,
			didSearch: this.state.didSearch
		});
	}

	handleClose() {
		this.setState({
			pickerDialogOpen: false,
			_icons: this.state._icons,
			icons: this.state._icons,
			selected: null,
			didSearch: false
		});
	}

	pickAndClose() {
    try {
      this.props.input.onChange(this.state.selected);
      this.setState({
  			pickerDialogOpen: false,
  			_icons: this.state._icons,
  			icons: this.state._icons,
  			selected: this.state.selected,
  			didSearch: false
  		});
    }
    catch(err) {
      console.log('Component must be rendered within Redux Field', err.message)
    }

	}

	select(icon) {
		this.setState({
			pickerDialogOpen: this.state.pickerDialogOpen,
			icons: this.state.icons,
			_icons: this.state._icons,
			selected: icon.name,
			didSearch: this.state.didSearch
		});
	}

	filterList(event) {
		if (event.target.value.toLowerCase().length === 0) {
			this.clearSearch();
		} else {
			let updatedList = this.state._icons;
			updatedList = updatedList.filter(function(item) {
				const searches = item.name.split("_").map(
          namePiece => namePiece.search(event.target.value.toLowerCase()) !== -1
				);
				return searches.indexOf(true) > -1;
			});

			this.setState({
				pickerDialogOpen: this.state.pickerDialogOpen,
				_icons: this.state._icons,
				icons: updatedList,
				selected: this.state.selected,
				didSearch: true
			});
		}
	}

	clearSearch() {
		this._searchInput.value = "";

		this.setState({
			pickerDialogOpen: this.state.pickerDialogOpen,
			_icons: this.state._icons,
			icons: this.state._icons,
			selected: this.state.selected,
			didSearch: false
		});
	}

	render() {
    const { meta: { touched, error } = {}, ...props } = this.props;
		const icons = this.state.icons.map((icon, index) => {
			return (
				<div key={index} style={style.icon} onClick={() => this.select(icon)}>
					<div
						style={
							this.state.selected && this.state.selected === icon.name
								? {...style.box, ...style.selectedBox}
								: style.box
						}
					/>
          <Icon style={style.iconItem}>{icon.name}</Icon>
          <div style={style.iconCaption}>{icon.name.split("_").join(" ")}</div>
				</div>
			);
		});

		return (
			<div>
        <div onClick={this.handleOpen.bind(this)} style={{position:'relative'}}>
          {this.state.selected ?
            (<div style={this.props.iconSelected}><Icon style={{fontSize: this.props.iconSize}}>{this.state.selected}</Icon></div>)
          : (<div style={this.props.iconButton}><Icon>add</Icon> {this.props.label}</div>)}
          {touched && error ? <div style={style.error}>{error}</div> : ''}
        </div>

				<Dialog
          fullWidth
          maxWidth="sm"
					modal={false}
					open={this.state.pickerDialogOpen}
					onClose={this.handleClose.bind(this)}
				>
          <div style={style.dialogClose} onClick={this.handleClose.bind(this)}>x</div>
          <DialogTitle>
            <div style={style.header}>
							<div style={style.title}>
                {this.props.title}
              </div>
							<div style={style.search}>
								<Icon style={style.searchIcon}>search</Icon>
								<input
									ref={el => (this._searchInput = el)}
									type="text"
									style={style.searchInput}
									placeholder="Search for Icon"
									onChange={this.filterList.bind(this)}
								/>
								{this.state.didSearch ? (
									<Icon
										style={style.clearIcon}
										onClick={this.clearSearch.bind(this)}
									>close</Icon>
								) : null}
							</div>
						</div>
          </DialogTitle>

					{this.state.icons.length > 0 ? (
            <DialogContent>
						  <div style={style.iconsGrid}>{icons}</div>
            </DialogContent>
					) : (
            <DialogContent>
						  <div style={style.noIcons}>Sorry, no icon with that name was found.  Please try your search again.</div>
            </DialogContent>
					)}

          <DialogActions>
            <div style={style.actions}>
              <GreenButton
                label={this.props.cancelLabel}
                onClick={this.handleClose.bind(this)}
                small={true}

              />
              <GreenButton
                label={this.props.pickLabel}
                disabled={!this.state.selected}
                onClick={this.pickAndClose.bind(this)}
                small={true}
                icon={
                  this.state.selected ?
                    this.state.selected
                  : null
                }
                style={{marginRight: 10, marginLeft: 20}}
              />
            </div>
          </DialogActions>

				</Dialog>
			</div>
		);
	}
}

IconPicker.propTypes = {
	cancelLabel: PropTypes.string,
	label: PropTypes.string,
	title: PropTypes.string,
	onPick: PropTypes.func.isRequired,
	pickLabel: PropTypes.string
};

IconPicker.defaultProps = {
	cancelLabel: "Cancel",
	label: "Pick icon",
	title: "Select Icon",
	pickLabel: "Choose Icon"
};

export default IconPicker;
