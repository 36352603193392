import React, {Component} from 'react';
import { style, validators } from './shared';
import loopbackRestClient from './../aor-loopback';
import storage from './../aor-loopback/storage';
import config from './../config/config.provider';
import { ExternalLayout, Card, CreateAccountFormFromToken, CreateAccountFormFromCode } from './../components';
import {Link} from 'react-router-dom';
import { fetchByToken } from './../actions/entityManager';

const apiClient = loopbackRestClient(config);

export class CreateAccountFromToken extends Component {
  state = {
    entityMgr: undefined,
    loading: true,
  }

  async componentDidMount() {
    const token = this.props.match.params.token
    fetchByToken({token}).then((entityMgr) => {
      storage.save('lbtoken', {id: this.props.match.params.token}, 604800);
      this.setState({entityMgr, loading: false});
    }).catch((err) => {
      console.log(err);
      this.setState({entityMgr: undefined, loading: false});
    });
  }

  render() {
    if (!this.state.loading && !this.state.entityMgr) {
      return (
        <ExternalLayout>
          <div style={style.content}>
            <Card content={style.cardModifier}>
              <div style={style.title}>The link you clicked is invalid or the session has expired.</div>
              <div style={style.links}>
                <Link style={style.href} to="/login">Back to Login</Link>
              </div>
            </Card>
          </div>
        </ExternalLayout>
      )
    } else if (!this.state.loading && this.state.entityMgr) {
      return (
        <ExternalLayout>
          <CreateAccountFormFromToken entityMgr={this.state.entityMgr} />
        </ExternalLayout>
      )
    } else {
      return (
        <ExternalLayout>
          <div style={style.content}>
            <Card content={style.cardModifier}>
              <div style={style.caption}>Loading...</div>
            </Card>
          </div>
        </ExternalLayout>
      )
    }
  }
};


export const CreateAccountFromCode = () => {
  return (
    <ExternalLayout>
      <CreateAccountFormFromCode />
    </ExternalLayout>
  )
}
