import React, {Component} from 'react';
import PropTypes from "prop-types";
import Floater from 'react-floater';
import _ from 'lodash';

let style = {
  trigger: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 15,
    height: 15,
    backgroundColor: '#2196F3',
    color: '#FFF',
    borderRadius: '50%',
    fontSize: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'ProximaNova-SemiBold',
    zIndex: 80,
    userSelect: 'none',
    cursor: 'pointer',
  },
  overlay: {
    cursor: 'pointer',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    overflow: 'hidden',
    pointerEvents: 'auto',
    zIndex: 100,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    mixBlendMode: 'hard-light',
  },
}

class Tooltip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      parent: [],
      isScrolling: false,
      showSpotlight: true,
    }
  }

  componentDidMount() {
    this.setState({ parent: this.refs.tooltip.parentNode });
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('scroll', this.handleScroll, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
    clearTimeout(this.resizeTimeout);
    clearTimeout(this.scrollTimeout);
  }

  handleScroll = () => {
    const { target } = this.props;
    const element = this.state.parent

    if (this.scrollParent !== document) {
      const { isScrolling } = this.state;

      if (!isScrolling) {
        this.setState({ isScrolling: true, showSpotlight: false });
      }

      clearTimeout(this.scrollTimeout);

      this.scrollTimeout = setTimeout(() => {
        this.setState({ isScrolling: false, showSpotlight: true });
      });
    } else if (element.style.position = 'sticky') {
      // this.updateState({});
    }
  };

  handleResize = () => {
    clearTimeout(this.resizeTimeout);

    this.resizeTimeout = setTimeout(() => {
      this.forceUpdate();
    });
  };

  handleClick = () => {
    const { open } = this.state;
    this.setState({
      open: !open
    });
  }

  get spotlightStyle() {
    const { parent } = this.state;
    const target = this.props.target;
    const parentPositioning = parent.getBoundingClientRect();
    const element = document.getElementsByClassName(`${target}`)

    return {
      borderRadius: 4,
      position: 'absolute',
      backgroundColor: 'gray',
      opactiy: 1,
      pointerEvents: 'none',
      transition: 'opacity 0.2s ease 0s',
      height: Math.round(parent.clientHeight + 30 * 2),
      width: Math.round(parent.clientWidth + 30 * 2),
      left: Math.round(parentPositioning.left - 30),
      top: Math.round(parentPositioning.top - 30),
    }
  }

  render() {
    const { open, parent } = this.state;
    let { message, target, tipPlacement, title } = this.props;

    return (
      <div ref="tooltip">
        <Floater
          title={title}
          open={open}
          content={message}
          placement={tipPlacement}
          target={`.${target}`}
          styles={{
            wrapper: {
              position: 'absolute',
              top: 0,
              right: 0,
            },
            body: {
              borderRadius: 5,
            },
            floater: {
              zIndex: 200,
            },
            container: {
              lineHeight: 1.7,
              borderRadius: 5,
              color: '#464D5E',
            },
            title: {
              fontFamily: 'ProximaNova-Regular',
            },
            content: {
              fontSize: 14,
              fontFamily: 'ProximaNova-Regular'
            }
          }}
        >
        <div style={style.trigger} onClick={this.handleClick}>?</div>
        </Floater>

        {open &&
          <div onClick={this.handleClick} style={style.overlay}>
            <div style={this.spotlightStyle}/>
          </div>
        }
      </div>
    )
  }
}


export default Tooltip
