import React from 'react';

let style = {
  root: {
    fontSize: 26,
    color: '#464D5E',
    textAlign: 'center',
    maxWidth: 540,
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
  }
}

const Title = ({children, ...props}) => {
  return (
    <div style={style.root}>{children}</div>
  )
}


export default Title;
