import React from 'react';
import Radium from 'radium';

let style = {
  container: {
    display: 'flex',
  },
  input: {
    height: 40,
    border: '1px solid #ADB1BA',
    borderRadius: '20px 0 0 20px',
    font: '12px ProximaNova-SemiBold',
    letterSpacing: 1,
    paddingLeft: 20,
    color: '#525868',
    outline: 'none',
    flex: 1,
  },
  btn: {
    position: 'relative',
    height: 44,
    border: '1px solid #23BFB8',
    backgroundColor: '#23BFB8',
    color: '#FFF',
    font: '12px ProximaNova-SemiBold',
    letterSpacing: 1,
    outline: 'none',
    paddingLeft: 30,
    paddingRight: 30,
    borderRadius: '0 20px 20px 0',
    cursor: 'pointer',
    textTransform: 'uppercase',
    ':hover': {
      backgroundColor: '#167773',
      border: '1px solid #167773',
    }
  }
}

const OutlinedButtonInput = (props) => {

  return (
    <form>
      <div style={style.container}>
        <input
          {...props}
          style={style.input}
          defaultValue="this is the input"
          readOnly={props.readonly}
        />
      <input type="button" style={style.btn} value={props.label} onClick={props.btnOnClick}/>
      </div>
    </form>
  )
}

export default Radium(OutlinedButtonInput)
