import React, {Component} from 'react';
import { MenuItem, NestedItems } from './';
import { getFromNestedSafely as gS, cobrandLink } from './../../../services';


class EntityMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: '',
    }
  }

  openNested = e => {
    this.setState({ [e]: !this.state[e] });
  }

  setRouteFromState() {
    const active =  (this.props.location && this.props.location.pathname) || window.location.pathname;
    this.setState({active: active});
  }

  componentDidMount() {
    this.setRouteFromState();
  }

  setActive(to) {
    this.setState({ active: to });
  }
  render() {
    const disabled = gS(this.props, 'entityMgr.entity.templating.cobrand') ? 0 : 1;
    const disabledByNullPortal = gS(this.props, 'entityMgr.entity.templating.portal') ? 0 : 1;
    const disabledByNullCauseDetails = (gS(this.props,'entityMgr.entity.templating.cobrand')) ? false : true;
    const is_sponsor = gS(this.props, 'entityMgr.entity.cause.is_sponsor');


    return (
      <div>
        <MenuItem
          {...this.props}
          active={this.state.active}
          icon="home"
          name="Dashboard"
          href="/"
          target="_self"
          setActive={this.setActive.bind(this, '/')}
        />
        {is_sponsor && (
          <NestedItems
            {...this.props}
            in={this.state['Sponsor Site']}
            name="Sponsor Site"
            icon="computer"
            openNested={this.openNested.bind(this, 'Sponsor Site')}
          >
            <MenuItem
              {...this.props}
              active={this.state.active}
              icon="open_in_new"
              name="View"
              target="_blank"
              href={cobrandLink({entity: gS(this.props, 'entityMgr.entity.cause'), slug: gS(this.props, 'record.slug')})}
              nested="true"
              disabled={disabledByNullPortal}
            />
          </NestedItems>
          )}

      {!is_sponsor && (
        <NestedItems
          {...this.props}
          in={this.state['Cause Site']}
          name="Cause Page"
          icon="computer"
          openNested={this.openNested.bind(this, 'Cause Site')}

        >
          <MenuItem
            {...this.props}
            active={this.state.active}
            icon="edit"
            name="Edit"
            to="/entities"
            setActive={this.setActive.bind(this, '/entities')}
            nested="true"
          />
          <MenuItem
            {...this.props}
            active={this.state.active}
            icon="open_in_new"
            name="View"
            href={cobrandLink({entity: gS(this.props, 'entityMgr.entity.cause'), slug: gS(this.props, 'record.slug')})+"cause"}
            nested="true"
            disabled={disabledByNullPortal}
          />
        </NestedItems>
      )}
      {!is_sponsor && (
        <NestedItems
          {...this.props}
          in={this.state['In-App Portal']}
          name="In-App Cause Portal"
          icon="phone_iphone"
          openNested={this.openNested.bind(this, 'In-App Portal')}
          disabled={disabledByNullCauseDetails}
        >
          <MenuItem
            {...this.props}
            active={this.state.active}
            icon="edit"
            name="Edit"
            to="/portal/edit"
            setActive={this.setActive.bind(this, '/portal/edit')}
            nested="true"
          />
        </NestedItems>
      )}

      {is_sponsor && (
        <NestedItems
          {...this.props}
          in={this.state['In-App Portal']}
          name="In-App Sponsor Portal"
          icon="phone_iphone"
          openNested={this.openNested.bind(this, 'In-App Portal')}
        >
          <MenuItem
            {...this.props}
            active={this.state.active}
            icon="edit"
            name="Edit"
            to="/portal/edit"
            setActive={this.setActive.bind(this, '/portal/edit')}
            nested="true"
          />
        </NestedItems>
        )}

        <NestedItems
          {...this.props}
          in={this.state['Campaigns']}
          name="Campaigns"
          icon="flag"
          openNested={this.openNested.bind(this, 'Campaigns')}
          disabled={disabledByNullPortal}
        >
          <MenuItem
            {...this.props}
            active={this.state.active}
            icon="group"
            name="Manage"
            to="/campaigns"
            setActive={this.setActive.bind(this, '/campaigns')}
            nested="true"
          />
          <MenuItem
            {...this.props}
            active={this.state.active}
            icon="add_circle"
            name="Create Campaign"
            to="/campaigns/create"
            setActive={this.setActive.bind(this, '/campaigns/create')}
            nested="true"
          />
        </NestedItems>

        <NestedItems
          {...this.props}
          in={this.state['Fundraisers']}
          name="Fundraisers"
          icon="star"
          openNested={this.openNested.bind(this, 'Fundraisers')}
          disabled={disabledByNullPortal}
        >
          <MenuItem
            {...this.props}
            active={this.state.active}
            icon="group"
            name="Manage"
            to="/fundraisers"
            setActive={this.setActive.bind(this, '/fundraisers')}
            nested="true"
          />
        </NestedItems>

        <NestedItems
          {...this.props}
          in={this.state['Boosters']}
          name="Boosters"
          icon="supervised_user_circle"
          openNested={this.openNested.bind(this, 'Boosters')}
          disabled={disabledByNullPortal}
        >
          <MenuItem
            {...this.props}
            active={this.state.active}
            icon="group"
            name="Manage"
            to="/boosters"
            setActive={this.setActive.bind(this, '/boosters')}
            nested="true"
          />
        </NestedItems>

        <MenuItem
          {...this.props}
          active={this.state.active}
          icon="message"
          name="Donor Invitation System"
          to="/dis"
          setActive={this.setActive.bind(this, '/dis')}
          disabled={disabledByNullPortal}
        />

        <MenuItem
          {...this.props}
          active={this.state.active}
          icon="help"
          name="FAQ"
          to="/faq"
          setActive={this.setActive.bind(this, '/faq')}
        />
        {!is_sponsor && (
            <MenuItem
              {...this.props}
              active={this.state.active}
              icon="description"
              name="Documentation"
              href="https://docs.startupgreenback.com"
            />
        )}
      </div>
    )
  }
}

export default EntityMenu
