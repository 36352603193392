import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import loopbackRestClient from './../../aor-loopback';
import config from './../../config/config.provider';
import {connect} from 'react-redux';
import {Field, FieldArray, SubmissionError} from 'redux-form';
import { submit, reset } from 'redux-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Icon from '@material-ui/core/Icon';

import {
  LabeledTextInput,
  GreenButton,
  CheckboxInput,
  Card
} from './../../components';
import {
  getFromNestedSafely as gS,
  formEnhancer,
  entityMgrConnect
} from './../../services';
import { required } from './../../validators';

const apiClient = loopbackRestClient(config);

const style = {
  wrapper: {
    padding: 0,
    margin: 0,
    height: '100%'
  },
  container: {
    height: '100%',
    width: '100%',
    fontFamily: 'ProximaNova-Light',
    color: '#464D5E',
    position: 'relative',
    minWidth: 290
  },
  instructions: {
    fontSize: 16,
    fontFamily: 'ProximaNova-SemiBold',
    marginLeft: 15,
    marginRight: 15,
    lineHeight: 1.2
  },
  toolTip: {
    margin: 15,
    fontSize: 12
  },
  leadInputs: {
    backgroundColor: '#F3F5F6',
    borderRadius: '50px',
    padding: '10px',
    paddingLeft: '40px',
    margin: '0 auto 10px auto',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  input40: {
    width: '90%',
    marginRight: 10,
  },
  save: {
    margin: '15px auto 0',
    textAlign: 'center',
    fontSize: 12,
    maxWidth: 450,
    lineHeight: 1.4,
    fontFamily: 'ProximaNova-Light'
  },
  send: {
    marginTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  remBtn: {
    outline: 'none',
    border: 'none',
    color: '#f33',
    fontFamily: 'ProximaNova-SemiBold',
    background: 'transparent',
    marginBottom: 5,
    marginTop: 0,
    paddingRight: 30,
    cursor: 'pointer',
    display: 'block',
    marginLeft: 'auto',
  },
  addBtn: {
    outline: 'none',
    border: 'none',
    color: '#2CBBB7',
    fontSize: 16,
    fontFamily: 'ProximaNova-SemiBold',
    background: 'transparent',
    marginBottom: 5,
    padding: 0,
    cursor: 'pointer',
    marginLeft: 10,
    display: 'flex',
    alignItems: 'center',
  },
  addIcon: {
    fontSize: 18,
  },
  addTxt: {
    marginLeft: 10,
  },
  error: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#f33',
    maxWidth: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
  },
  success: {
    fontWeight: 'bold',
    textAlign: 'center',
    color: '#019490',
    maxWidth: 400,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
  },
  formLabel: {
    display: 'inline-flex',
    marginLeft: 'auto',
    marginRight: 'auto',
  }
}

const termsAgreementValidator = value => {
  return !value
    ? 'Required'
    : undefined;
}

class RenderLeads extends React.Component {

  addLead() {
    this.props.fields.push({});
  }

  removeLead(index) {
    this.props.fields.remove(index);
  }

  componentDidMount() {
    this.addLead();
  }

  componentWillReceiveProps(nextProps) {
    if (gS(nextProps, 'fields.length', 0) === 0) {
      this.addLead();
    }
  }

  render() {
    const { fields, meta: { touched, error, submitFailed } } = this.props;
    const addLead = this.addLead.bind(this);
    const removeLead = this.removeLead.bind(this);
    return (
      <div>
        {fields.map((lead, index) => (
          <div>
            <div style={style.leadInputs} key={`lead_${index}`}>
              <Field
                name={`${lead}.first_name`}
                component={LabeledTextInput}
                label="Name*"
                hintText="John"
                validate={[required]}
                style={style.input40}
                maxLength="25"
              />
              <Field
                name={`${lead}.email`}
                component={LabeledTextInput}
                label="Email*"
                type="email"
                placeholder="youremail@domain.com"
                validate={[required]}
                style={style.input40}
              />
            </div>
            {index >= 1 ? <button type="button" style={style.remBtn} onClick={() => removeLead(index)}>Remove Recipient</button> : ''}
          </div>
        ))}
        <button type="button" style={style.addBtn} onClick={addLead}><Icon style={style.addIcon}>person_add</Icon><span style={style.addTxt}>Add Recipient</span></button>
        {(touched || submitFailed) && error && <span>{error}</span>}
      </div>
    );
  }
}


class DISForm extends Component {
  state = {}

  handleChange = name => event => {
    this.setState({[name]: event.target.checked})
  }

  render() {
    const {handleSubmit, submitting, error, submitSucceeded, location, classes} = this.props;
    return (
        <div style={style.wrapper}>
          <div style={style.container}>
            <Card>
              <div style={style.contentWrapper}>
                <div style={style.instructions}>
                  Enter your recipient's name & email address below to invite them to donate to your fundraiser.
                </div>
                <div style={style.toolTip}>
                  *indicates a required field
                </div>
                <form onSubmit={handleSubmit}>
                  <div style={style.form}>
                    <FieldArray name="leads" component={RenderLeads} />
                  </div>
                  <div style={style.save}>
                    <FormControlLabel
                      classes={{
                        root: classes.formLabel
                      }}
                      control={
                        <Field
                          {...this.props}
                          name="terms_agreed_to"
                          component={CheckboxInput}
                          validate={[termsAgreementValidator]}
                        />
                      }
                      label="I acknowledge that these recipients are known to me and the email will be sent with me as the sender."
                    />
                  </div>
                  {error && <p style={style.error}>{error}</p>}
                  {submitSucceeded && !error && <p style={style.success}>Woosh! Invitations are on their way!</p>}
                  <GreenButton style={style.send} label="Send" type="submit" small/>
                </form>
              </div>
            </Card>
          </div>
        </div>
    )
  }
}

export const DIS = formEnhancer({
  FormComponent: withRouter(withStyles(style)(DISForm)),
  formName: 'dis-invite',
  onSubmit: (data, dispatch, props) => {
    return new Promise((resolve, reject) => {
      let defaultErr = {_error: "Error with submission"};
      apiClient("CREATE", "EntityLeads", {data}).then((args) => {
        const { response } = args;
        if (response.status === 200) {
          dispatch(reset('dis-invite'));
          resolve();
        }
        else {
          reject(new SubmissionError(defaultErr));
        }
      }).catch((err) => {
        console.log(err)
        reject(new SubmissionError({_error: err.message}));
      });
    });
  }
});
