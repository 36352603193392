import React from 'react';
import { connect } from 'react-redux';
import {
    Datagrid,
    Filter,
    List,
    NumberField,
    TextInput,
    CreateButton,
    RefreshButton
} from 'react-admin';
import CardActions from '@material-ui/core/CardActions';
import {
  ListManage,
  ListAvatar,
  ListName,
  ListStatus,
  Card
} from './../../components';
import _ from 'lodash';
import { entityMgrConnect } from './../../services'

const style = {
  list: {
    minWidth: 445,
  },
  title: {
    marginBottom: 20,
    fontSize: 18,
    fontFamily: 'ProximaNova-SemiBold',
  }
}

const SubentityFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="where.templating.name.like" alwaysOn />
  </Filter>
);


const PostActions = ({ entityMgr, resource, filters, displayedFilters, filterValues, basePath, showFilter }) => (
  <CardActions>
    {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
    {_.get(entityMgr, 'realm') === 'subentity' && (<CreateButton basePath="/fundraisers" label="Create Fundraiser"/>)}
    <RefreshButton />
  </CardActions>
);

const BoosterList = (props, record) => {
  return (
    <div style={style.list}>
      <Card root={style.title}>Fundraisers</Card>
      <List {...props}
        actions={<PostActions entityMgr={props.entityMgr}/>}
        sort={{ field: 'templating.name', order: 'ASC' }}
        perPage={10}
        bulkActionButtons={false}
        title="Fundraisers"
        filters={<SubentityFilter />}
      >
          <Datagrid bodyOptions={{ stripedRows: true, showRowHover: true }}>
            <ListStatus style={{width:20}} label="Active" source="templating.fundraising" />
            <ListAvatar source="templating.logo" label=""/>
            <ListName source="templating.name" maxLength={25} label="Name"/>
            <NumberField source="earnings" options={{ style: 'currency', currency: 'USD' }} label="Funds Raised"/>
            <ListManage />
          </Datagrid>
      </List>
    </div>
  )
}

export default entityMgrConnect(BoosterList);
