import React from 'react';
import PropTypes from 'prop-types';

const styles = {
  active: {
    height: 10,
    width: 10,
    borderRadius: '50%',
    background: '#23BF35',
    margin: '0 auto',
    filter: 'drop-shadow(0px 0px 6px #23BF35)'
  },
  inactive: {
    height: 10,
    width: 10,
    borderRadius: '50%',
    background: '#DA3B16',
    margin: '0 auto',
    filter: 'drop-shadow(0px 0px 6px #DA3B16)'
  }
}

const ListStatus = (props) => (

    props.record.type == 'subentity'
      ?
      (<div style={props.record.templating && props.record.templating.portal ? styles.active : styles.inactive} />)
      :
      (<div style={props.record.templating && props.record.templating.fundraising ? styles.active : styles.inactive} />)

  );

ListStatus.propTypes = {
  record: PropTypes.object
};

export default ListStatus;
