import loopbackRestClient from './../../aor-loopback';
import config from './../../config/config.provider';
const restClient = loopbackRestClient(config)

export const FEATURE_SUBENTITY_BEGIN   = 'GGB/FEATURE_SUBENTITY_BEGIN';
export const FEATURE_SUBENTITY_SUCCESS = 'GGB/FEATURE_SUBENTITY_SUCCESS';
export const FEATURE_SUBENTITY_FAILURE = 'GGB/FEATURE_SUBENTITY_FAILURE';

export const featureSubentityBegin = () => ({
  type: FEATURE_SUBENTITY_BEGIN
});

export const featureSubentitySuccess = data => ({
  type: FEATURE_SUBENTITY_SUCCESS,
  payload: { data }
});

export const featureSubentityFailure = error => ({
  type: FEATURE_SUBENTITY_FAILURE,
  payload: { error }
});

const initialState = {
  record: null,
  loading: false,
  error: null
};

export function featureSubentity({subentity_id}) {
  return new Promise((resolve, reject) => {
    restClient("WITH_ROUTE", "subentities", {route:`${subentity_id}/feature`}).then(({response}) => {
      (response.status == 200) ? resolve(response.json) : reject(response);
    }).catch(reject);
  });
}

export function unfeatureSubentity({subentity_id}) {
  return new Promise((resolve, reject) => {
    restClient("WITH_ROUTE", "subentities", {route:`${subentity_id}/unfeature`}).then(({response}) => {
      (response.status == 200) ? resolve(response.json) : reject(response);
    }).catch(reject);
  });
}

export default function featureSubentityReducer(state = initialState, action) {
  switch(action.type) {
    case FEATURE_SUBENTITY_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };
    case FEATURE_SUBENTITY_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: false,
        record: action.payload.data
      };
    case FEATURE_SUBENTITY_FAILURE:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere
      // Since it failed, we don't have items to display anymore, so set it empty.
      // This is up to you and your app though: maybe you want to keep the items
      // around! Do whatever seems right.
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        record: null
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
