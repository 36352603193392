import React, {Component} from 'react';
import Radium from 'radium';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Card,
  LabeledTextInput,
  GreenButton,
  CheckboxInput,
  AccountDetails,
  SettingsTab,
  ChangePassword,
  ChangeEmail,
  AccountProfile,
  LegalEntity
} from './../../components';
import {
  formEnhancer,
  entityMgrConnect
} from './../../services';
import _ from 'lodash';

const style = {
  wrapper: {
    position: 'relative',
  },
  header: {
    font: '18px ProximaNova-SemiBold',
  },
  title: {
    font: '18px ProximaNova-SemiBold',
    marginBottom: 20,
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems:' flex-start',
    alignContent: 'flex-start',
    marginTop: 20,
  },
  item: {
    width: 'calc(50% - 10px)',
    minWidth: 290,
    marginBottom: 20,
    overflow: 'auto',
    '@media (max-width: 1078px)': {
      width: '100%',
    }
  },
  row: {
    marginBottom: 20,
    width: '100%',
    flexShrink: 0,
  },
  attention: {
    boxShadow: `0px 0px 6px rgba(224,10,31,0.8)`
  }
}

const Account = (props) => {
  const entityMgr = _.get(props, 'entityMgr') || {};
  const is_sponsor = _.get(entityMgr, 'entity.cause.is_sponsor');

  if (!entityMgr.id) {
    return (
      <div style={style.wrapper}>
        <div style={style.content}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  return (
    <div style={style.wrapper}>
<SettingsTab></SettingsTab>
    </div>
  )
}

export default entityMgrConnect(Radium(Account));
