import React, {Component} from 'react';
import { style } from './shared';
import {propTypes, reduxForm, Field} from 'redux-form';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import Radium from 'radium';
import {
  translate,
  userLogin as userLoginAction,
} from 'react-admin';

import _ from 'lodash';

import {
  ExternalLayout,
  Card,
  LabeledTextInput,
  GreenButton
} from './../components';

class Login extends Component {

  login = ({email, password}) => {
    const {userLogin, location} = this.props;
    userLogin({
      email: _.trim(email),
      password
    },
    location.state ? location.state.nextPathname : '/');
  }

  render() {
    const {handleSubmit, invalid} = this.props;
    return (
      <ExternalLayout>
        <div style={style.content}>
          <Card content={style.cardModifierLogin}>
            <div style={style.title}>Log In</div>
            <div style={style.caption}>Enter your email and password to log in.</div>
            <form onSubmit={handleSubmit(this.login)} style={style.form}>
              <Field
                component={LabeledTextInput}
                name="email"
                label="Email"
                hintText="youremail@domain.com"
                style={style.input}
              />
              <Field
                component={LabeledTextInput}
                name="password"
                label="Password"
                hintText="*********"
                type="password"
                style={style.input}
              />
              <GreenButton
                type="submit"
                style={style.submit}
                disabled={invalid}
                label="Log In"
                small
              />
            <div style={style.links}>
              <Link style={style.href} to="/forgot_password">Forgot Password?</Link>
            </div>
            <div style={style.links}>
              <Link style={style.href} to="/create_account_by_code">Have a Fundraiser Code? Become a Booster</Link>
            </div>
            </form>
          </Card>
        </div>
      </ExternalLayout>
    );
  }
}

Login.propTypes = {
  ...propTypes,
  authClient: PropTypes.func,
  previousRoute: PropTypes.string,
  translate: PropTypes.func.isRequired,
  userLogin: PropTypes.func.isRequired
};

Login.defaultProps = {};

const enhance = compose(translate, reduxForm({
  form: 'signIn',
  validate: (values, props) => {
    const errors = {};
    const {translate} = props;
    if (!values.email)
      errors.email = "Required";
    if (!values.password)
      errors.password = "Required";
    return errors;
  }
}), connect(null, {userLogin: userLoginAction}));

export default enhance(Radium(Login));
