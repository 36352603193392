import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

const style = {
  root: {
    color: '#FFF',
    backgroundColor: '#FF6200',
    paddingLeft: 20,
    paddingRight: 20,
    fontFamily: "'ProximaNova-SemiBold'",
    fontSize: 16,
    letterSpacing: 1,
    boxShadow: '0 8px 8px 0 rgba(255,98,0,0.10)',
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: '#AA4100'
    }
  },
  disabled: {
    opacity: '0.4',
    backgroundColor: '#FF6200 !important',
    color: '#FFF !important',
  },
  outlined: {
    color: '#FF6200',
    background: 'transparent',
    paddingLeft: 20,
    paddingRight: 20,
    fontFamily: "'ProximaNova-SemiBold'",
    border: '1px solid #FF6200',
    fontSize: 16,
    letterSpacing: 1,
    boxShadow: '0 8px 8px 0 rgba(255,98,0,0.10)',
    borderRadius: 20,
    '&:hover': {
      color: '#0D2831',
      border: '1px solid #AA4100',
      background: 'transparent',
    }
  },
  outlineDisabled: {
    opacity: '0.4',
    border: '1px solid #FF6200 !important',
    color: '#FF6200 !important',
  },
  small: {
    fontSize: '12px !important',
    paddingTop: 8,
  },
  icon: {
    fontSize: 14,
    marginRight: 5,
  }
}

const OrangeButton = ({classes, ...props}) => {
  return (
    <Button
      {...props}
      variant={props.outlined ? 'outlined' : 'contained'}
      size={props.small ? 'small' : ''}
      classes={{
        contained: classes.root,
        disabled: props.outlined ? classes.outlineDisabled : classes.disabled,
        outlined: classes.outlined,
        sizeSmall: classes.small,
      }}
      >
        {props.icon && (<Icon style={style.icon}>{props.icon}</Icon>)}
        {props.label}
      </Button>
  )
}

export default withStyles(style)(OrangeButton)
