import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {entityMgrConnect, getFromNestedSafely as gS } from './../../services';
import EntityCauseDashboard from './EntityCauseDashboard';
import EntitySponsorDashboard from './EntitySponsorDashboard';
import CampaignDashboard from './CampaignDashboard';
import FundraiserDashboard from './FundraiserDashboard';
import BoosterDashboard from './BoosterDashboard';


const Dashboard = (props) => {
  const entityMgr = _.get(props, 'entityMgr') || {}
  const is_sponsor = _.get(entityMgr, 'entity.cause.is_sponsor', '');

  return (
    <div>
      {entityMgr.realm == 'entity' && is_sponsor ?
        (<EntitySponsorDashboard entityMgr={entityMgr} />)
      : entityMgr.realm == 'entity' && !is_sponsor ?
        (<EntityCauseDashboard entityMgr={entityMgr} />)
      : entityMgr.realm == 'subentity' ?
        (<CampaignDashboard entityMgr={entityMgr} />)
      : entityMgr.realm == 'unit' ?
        (<FundraiserDashboard entityMgr={entityMgr} />)
      : entityMgr.realm == 'subunit' ?
        (<BoosterDashboard entityMgr={entityMgr} />)
      : (<div />)}
    </div>
  );
}

export default entityMgrConnect(Dashboard);
