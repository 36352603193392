import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { grid } from './../../style';
import Radium from 'radium';
import style from './style.js';
import _ from 'lodash';
import {
  getFromNestedSafely as gS,
} from './../../services';
import {
  Widget,
  FundsRaised,
  Card,
  OrangeButton,
  WidgetDashboardHeader,
  GreenButton,
  GreenLink,
  IconButtons,
  DISFormWidget,
  SocialContent,
  TourWizard,
} from '../';
import { cobrandLink } from './../../services';

const CampaignDashboard = (props) => {
  const entityMgr = props.entityMgr || {};
  const disableCauseView = !_.get(entityMgr, 'subentity.templating.logo') ? true : false;
  const steps = [{
    target: '.appbar',
    title: 'Welcome to your Management Portal!',
    content: 'Through this portal you will be able to fully manage your Campaign. Let\'s start with a quick tour, and finish creating your Campaign!',
    disableBeacon: true,
    isFixed: true,
    hideCloseButton: true,
    placement: 'center',
    locale: {close: 'Get Started!',}
  },
  {
    target: '.campaign_site',
    title: 'Create Your Campaign Site',
    content: 'Click the EDIT button to open the Campaign Site editor.',
    disableBeacon: true,
    isFixed: true,
    hideFooter: true,
    hideCloseButton: true,
  },
  {
    target: '.in_app_portal',
    title: 'Create Your In-App Campaign Portal',
    content: 'Click EDIT to create a Campaign Portal that will be seen by all of your greenback members through the greenback mobile app.',
    disableBeacon: true,
    isFixed: true,
    hideFooter: true,
    hideCloseButton: true,
  }]
  const stepIndex = _.get(entityMgr, 'subentity.templating.logo') ? 2 : null;
  const joyrideTrigger = !_.get(entityMgr, 'subentity.templating.portal', '') ? true : false;
  const continuous = !_.get(entityMgr, 'subentity.templating.logo', '') ? true : false;

  let
  subentityName = '',

  mName = gS(props, 'entityMgr.first_name');
  subentityName = gS(props, 'entityMgr.subentity.templating.name');

  return (
    <div>
      <TourWizard
        steps={steps}
        continuous={continuous}
        stepIndex={stepIndex}
        run={joyrideTrigger}
      />
      <div style={grid.grid}>
        <div style={grid.row}>
        <WidgetDashboardHeader
          welcomeMessage={mName}
          caption={subentityName}
        >
        <div style={style.buttons}>
          <GreenLink
            label="Edit Campaign Site"
            to="/campaigns"
            component={Link}
            small="true"
            icon="edit"
            className="campaign_site"
            style={style.textButton}
          />
          <GreenLink
            label="View Campaign Site"
            small="true"
            target="_blank"
            href={cobrandLink({entity: _.get(entityMgr, 'entity.cause'), slug: _.get(entityMgr, 'subentity.slug')})}
            icon="open_in_new"
            disabled={disableCauseView}
            style={style.textButton}
          />
          <GreenLink
            label="Edit In-App Campaign Portal"
            to="/portal/edit"
            component={Link}
            small="true"
            icon="phone_iphone"
            className="in_app_portal"
            style={style.textButton}
          />
          </div>
        </WidgetDashboardHeader>
        </div>
        <div style={grid.row}>
          <div style={grid.col50}>
            <Widget
              icon="message"
              title="Donor Invitation System"
              caption="Invite a friend or family member to donate."
              tip="The DIS will create a personalized email to send your recipient requesting a donation, explaining what the donation is for, and information about greenback!"
              className="dis"
            >
              <DISFormWidget />
            </Widget>
          </div>
          <div style={grid.col50}>
            <Widget
              icon="share"
              title="Share Campaign Site"
              caption="Share your site with others by sending the link directly or using social media."
              tip="Easily share your Campaign Site via social media by copying your unique link, or by using one of the social shortcut icons."
              className="social"
            >
              <SocialContent
                entityMgr={entityMgr}
                sms="true"
                facebook="true"
                email="true"
              />
            </Widget>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Radium(CampaignDashboard);
