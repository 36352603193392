import React, {Component} from 'react';
import withSizes from 'react-sizes'
import {connect} from 'react-redux';
import Radium from 'radium';
import CircularProgress from '@material-ui/core/CircularProgress';
import withWidth from '@material-ui/core/withWidth';
import compose from 'recompose/compose';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { withStyles } from '@material-ui/core/styles';
import ReduxToastr from 'react-redux-toastr'
import AppFooter from './AppFooter';
import { Link } from 'react-router-dom';

const style = {
  body: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'space-between',
  },
  bar: {
    position: 'relative',
    height: 60,
    background: 'linear-gradient(90deg, #1E5E75 0%, #0E9BA6 63%)',
  },
  content: {
    position: 'relative',
    margin: 20,
  },
  logo: {
    height: 30,
    marginLeft: 0,
    display: 'block',
  },
  title: {
    marginLeft: 20,
    font: "24px 'ProximaNova-Light'",
  },
  titleMobile: {
    marginLeft: 20,
    font: "16px 'ProximaNova-Light'",
  },
}

class ExternalLayout extends Component {

  render() {
    const {
      children,
      isLoading,
      logout,
      isMobile,
      classes
    } = this.props;

    return (
      <div className={classes.body}>
        <AppBar className={classes.bar}>
          <Toolbar>
            <Link to="/login"><img className={classes.logo} src={isMobile ? "https://cloudfront.startupmedia.com/greenback/icons/gb-icon.png" : "https://cloudfront.startupmedia.com/greenback/logos/gb-logo-icon-light.png"} /></Link>
            <span className={isMobile ? classes.titleMobile : classes.title}>Management Portal</span>
          </Toolbar>
        </AppBar>
        <div className={classes.content}>
          {children}
          <ReduxToastr />
        </div>
        <AppFooter />
      </div>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 640,
})

const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0,
  open: state.admin.ui.sidebarOpen,
});

export default compose(
  connect(
    mapStateToProps,
    { }
  ),
  withStyles(style),
  withWidth({ resizeInterval: Infinity }) // used to initialize the visibility on first render
)(Radium(withSizes(mapSizesToProps)(ExternalLayout)));
