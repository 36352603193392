import React, {createElement} from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
// import {
//   crudGetOne
// } from 'admin-on-rest';
import {
  PaperPlaneIcon,
  Tooltip
} from './../';
import {
  getFromNestedSafely as gS,
  getSumSentBySubentityIdWithChildren,
  getSubentityIds,
  entityMgrConnect
} from './../../services';

const style = {
  invites: {
    fontSize: 72,
    fontFamily: 'ProximaNova-SemiBold',
    color: '#2CBBB7',
    textAlign: 'center',
    textDecoration: 'none',
  }
}

class InvitesSent extends React.Component {

  componentDidUpdate(prevProps, prevState) {
    if (prevProps && prevProps.entityMgr !== this.props.entityMgr) this.updateData();
  }

  componentDidMount(prevProps, prevState) {
    this.updateData();
  }

  updateData() {
    if (!gS(this.props,'entityMgr.subentity')) return;
    Promise.all([
      getSumSentBySubentityIdWithChildren({
        "subentity_id": this.props.entityMgr.subentity_id,
        "filter":{"is_active": true},
        "pluck_parent": gS(this.props,'entityMgr.subentity.type') === 'subunit' ? false : true,
      }),
      getSubentityIds({
        "subentity_ids": [this.props.entityMgr.subentity_id],
        "args": {
          "filter":{"is_active": true},
          "pluck_parent": gS(this.props,'entityMgr.subentity.type') === 'subunit' ? false : true,
        }
      })
    ]).then(res => {
      console.log(res);
      this.props.entityMgr.subentity.sumOfSent = gS(res[0],'sum_of_sent', 0);
      this.props.entityMgr.subentity.subentityIds = res[1] || [];
      this.setState({entityMgr: this.props.entityMgr});
    }).catch(err => {
      console.log(err);
      this.props.entityMgr.subentity.sumOfSent = 0;
      this.props.entityMgr.subentity.subentityIds = [];
      this.setState({entityMgr: this.props.entityMgr});
    });
  }


  render() {
    const {} = this.props;
    let invitesSent = gS(this.props,'entityMgr.subentity.sumOfSent', 0);


    return (
      <div style={style.body}>
        <div style={style.invites}><Link style={style.invites} to="/dis">{invitesSent}</Link></div>
      </div>
    );
  }
}

export default InvitesSent;
