import './index.css';
import React from 'react';
import moment from 'moment';

import {
  entityMgrConnect,
  formatMoney,
  getFromNestedSafely as gS
} from './../../services';

const today = new Date();
const defaultImg = '//cloudfront.startupmedia.com/greenback/mgmtportal/circle-upload-background.jpg';

const CobrandFundraiser = (props) => {

  //safe getter for props
  const pgs = gS.bind(null, props);

  // Generics
  const type = pgs('subentity.type');
  const goal = pgs(`subentity.${props.source}.fundraising.goal`);
  const causeId = pgs('entityMgr.entity.cause.sponsors_of', [])[0]
  const entityLogo = pgs('entityMgr.entity.cause.is_sponsor') ? `http://cdn.startupmedia.com/greenback/entities/${causeId}/0/logo`: pgs('entityMgr.entity.templating.logo');
  const selfLogo = pgs(`subentity.${props.source}.logo`, defaultImg);
  const amount_raised = pgs('subentity.earnings') ? pgs('subentity.earnings') : '0';

  let name, signature, short_desc, long_desc, parentLogo, end_date;

  if (type === 'unit') {
    name = pgs(`subentity.${props.source}.name`);
    signature = pgs(`subentity.${props.source}.signature`);
    short_desc = pgs(`subentity.${props.source}.fundraising.short_description`);
    long_desc = pgs(`subentity.${props.source}.fundraising.long_description`);
    end_date = pgs(`subentity.${props.source}.fundraising.end_date`);

  }
  else if (type === 'subunit' && pgs('entityMgr.subentity.type') === 'unit') {
    name = pgs(`entityMgr.subentity.${props.source}.name`);
    signature = pgs(`subentity.${props.source}.name`);
    short_desc = pgs(`entityMgr.subentity.${props.source}.fundraising.short_description`);
    long_desc = pgs(`entityMgr.subentity.${props.source}.fundraising.long_description`);
    parentLogo = pgs(`entityMgr.subentity.${props.source}.logo`, defaultImg);
    end_date = pgs(`entityMgr.subentity.${props.source}.fundraising.end_date`);
  }
  else if (type === 'subunit' && pgs('entityMgr.subentity.type') === 'subunit') {
    name = pgs(`entityMgr.subentity.subentity.${props.source}.name`);
    signature = pgs(`subentity.${props.source}.name`);
    short_desc = pgs(`entityMgr.subentity.subentity.${props.source}.fundraising.short_description`);
    long_desc = pgs(`entityMgr.subentity.subentity.${props.source}.fundraising.long_description`);
    parentLogo = pgs(`entityMgr.subentity.subentity.${props.source}.logo`, defaultImg);
    end_date = pgs(`entityMgr.subentity.subentity.${props.source}.fundraising.end_date`);
  }

  end_date = moment(end_date);
  const days_left = end_date.diff(today, 'days');
  end_date = end_date.format('MMMM Do');

  const styles = {
    bar: {
      width: `${(amount_raised / goal) * 100}%`,
      borderRadius: '20px'
    },
    selfLogo: {
      backgroundImage: `url("${selfLogo}")`
    },
    parentLogo: {
      backgroundImage: `url("${parentLogo}")`
    },
    center: {
      textAlign: 'center'
    },
    cardBox: {
      textAlign: 'center',
      fontSize: '14px'
    }
  };

  return (
    <div className="fundraising" id={props.id}>
      <div className="header__wrapper">
        <div className="header">
          <div className="header__img" style={styles.selfLogo}>
            {type === 'unit' ? '' : <div className="header__img__secondary" style={styles.parentLogo}></div> }
          </div>
          <div className="header__pitch">
            <h1>Help <b>{name}</b> and get $1,000’s of savings when you shop, dine and travel!</h1>
            <h3>- {signature ? signature : name}</h3>
          </div>
        </div>
      </div>
      <div className="donation">
        <div className="donation__btns">
          <h2>
            <span>Give and get &nbsp;
              <span className="gbLogo">
                <span>green</span>back<sup>®</sup>
              </span>
            </span>
          </h2>
          <div className="global__btn btn">
            Donate Now!
          </div>
          <div className="blue__btn btn">Share With Friends</div>
        </div>
        <div className="donation__details">
          <h2>
            <span>Help {type === 'subunit' ? 'me reach my goal' : 'us reach our goal'  } by {end_date}!</span>
          </h2>
          <div className="donation__bar">
            <div className="donation__bar--progress" style={styles.bar}></div>
          </div>
          <div className="donation__amounts">
            <div>
              <span className="green">${amount_raised}</span>
              &nbsp;raised of my&nbsp;
              <span className="blue">${formatMoney(goal)} </span>
              goal
            </div>
            <div className="daysLeft">{days_left >= 0 ? days_left : '0'} days left</div>
          </div>
        </div>
      </div>

      <div className="details">
        <div className="details__wrapper">
          <div className="details__col w66">
            <div className="details__row">
              <div className="details__card">
                <div className="details__card__box h100" style={styles.center}>
                  <h2>
                    <span>Save more than you give
                      with&nbsp;
                      <span className="gbLogo">
                        <span>green</span>back
                      </span>!</span>
                  </h2>
                  <h4 style={{margin: 0}}>Access over 350,000 discounts nationwide with the&nbsp;
                    <span className="gbLogo">
                      <span>green</span>back
                    </span>
                    &nbsp;everyday savings program and mobile app.</h4>
                  <br/>
                  <img src="//cloudfront.startupmedia.com/greenback/devices/grouped-phones-app-tour.png"/>
                  <p>Learn more about&nbsp;
                    <span className="gbLogo">
                      <span>green</span>back
                    </span>
                    &nbsp;here.</p>
                </div>
              </div>
            </div>
          </div>
          <div className="details__col">
            <div className="details__row">
              <div className="details__card">
                <div className="details__card__box">
                <div className="details__card__title">How Funds Are Used</div>
                  <p>{short_desc}</p>
                  <div className="details__card__title">Special Message</div>
                  <p>{long_desc}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

CobrandFundraiser.defaultProps = {
  source: 'templating'
};

export default entityMgrConnect(CobrandFundraiser);
