import React from 'react';
import { Link } from 'react-router-dom';
import Radium from 'radium';

const style = {
  footer: {
    width: '100%',
    height: 60,
    flexShrink: 0,
    background: '#1E5E75',
    display: 'flex',
    alignItems: 'center'
  },
  content: {
    width: '80%',
    margin: '0 auto',
    color: '#FFF',
    display: 'flex',
    justifyContent: 'space-between',
    font: '14px ProximaNova-Light',
    '@media (max-width: 640px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
  },
  copy: {
    '@media (max-width: 640px)': {
      marginBottom: 10,
    }
  },
  href: {
    color: '#FFF',
    textDecoration: 'none',
  }
}

const AppFooter = () => (
  <div style={style.footer}>
    <div style={style.content}>
      <div style={style.copy}>&copy; greenback {(new Date().getFullYear())}</div>
      <div><Link to="/terms-noauth" style={style.href}>Terms & Conditions</Link> &nbsp;&nbsp;|&nbsp;&nbsp; <Link to="/privacy-noauth" style={style.href}>Privacy Policy</Link></div>
    </div>
  </div>
);

export default Radium(AppFooter);
