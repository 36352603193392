import React, { Component } from "react";
import _ from 'lodash';
import { propTypes, reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { submit, reset } from 'redux-form';
import compose from 'recompose/compose'
import loopbackRestClient from './../../aor-loopback';
import config from './../../config/config.provider';

import {
  GreenButton,
  OrangeButton,
  LabeledTextInput
} from './../../components';

import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  showNotification,
  hideNotification,
} from './../../actions/customNotification';

import { SubmissionError } from 'redux-form';

import { required, email } from './../../validators';

const apiClient = loopbackRestClient(config);

const style = {
  btns: {
    marginTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  },
  btn: {
    display: 'inline-block',
    marginLeft: '10px',
    marginRight: '10px',
  },
  caption: {
    lineHeight: 1.5,
    fontSize: 14,
    fontFamily: 'ProximaNova-Regular',
    marginTop: 20,
  },
  error: {
    textAlign: 'center',
    color: '#f33',
    maxWidth: 300,
    fontSize: 14,
    lineHeight: 1.4,
    margin: '0 auto',
  },
  success: {
    textAlign: 'center',
    color: '#019490',
    maxWidth: 300,
    fontSize: 14,
    lineHeight: 1.4,
    margin: '0 auto',
  }
}

class AccountChangeEmail extends Component {

  state = {
    mode: 0,
    submitSuccess: false
  }

  onEmailChange = () => {
    this.setState({mode: 1});
  }

  onReset = () => {
    this.setState({mode: 0});
  }

  render() {
    const { invalid, error, pristine, reset, submitting, dispatch, handleSubmit, submitSucceeded, value } = this.props;

    let mode;
    if (submitting) {
      this.state.submitSuccess = false;
    }
    if (submitSucceeded && pristine && !this.state.submitSuccess) {
      mode = this.state.mode = 0;
      this.state.submitSuccess = true;
    }
    else {
      mode = this.state.mode;
    }

    switch(mode) {
      case 0:
        return (
          <form>
            {error && <p style={style.error}>{error}</p>}
            <div style={style.caption}>To change the email address you use to login, press the "Change Email" button below. Once you submit a request to change your email address, please check your existing email account for a confirmation link in order to complete the process.</div>
            <GreenButton style={style.btns} small="true" onClick={this.onEmailChange} primary disabled={submitting} label="Change Email" />
          </form>
        );
        break;
      case 1:
        return (
          <form onSubmit={handleSubmit} >
            {error && <p style={style.error}>{error}</p>}
            <Field
              {...this.props}
              name="email"
              component={LabeledTextInput}
              type="email"
              validate={[required, email]}
              label="Enter New Email Address"
              palceholder=""
              style={style.input}
            />
            <Field
              {...this.props}
              name="password"
              component={LabeledTextInput}
              type="password"
              validate={[required]}
              label="Password"
              style={style.input}
            />
            <div style={style.btns}>
              <GreenButton style={style.btn} small="true" type="submit" primary disabled={submitting || invalid} label="Submit" />
              <OrangeButton style={style.btn} small="true" onClick={this.onReset} primary disabled={submitting} label="Cancel" />
            </div>
          </form>
        );
        break;
    }
  }
};

let ChangeEmail = reduxForm({
  form: 'change-email',
  onSubmit: (values, dispatch, props) => {
    return new Promise((resolve, reject) => {
      apiClient("WITH_ROUTE", "EntityManagers", {
        route:`${values.id}/changeEmailRequest`,
        data: {
          newEmail: values.email,
          password: values.password
        },
        method: "POST"
      }).then(({response}) => {
        if (response.status === 200) {
          dispatch(reset('change-email'));
          dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
              type: "success",
              title: "Email Change Request Sent",
              message: "Please check your email for instructions to change your email address."
            }
          });
          resolve();
        }
        else {
          const resetErr = {_error: _.get(response, 'error.message', 'Reset Email failed!')};
          reject(new SubmissionError(resetErr));
        }
      }).catch((err) => {
        const {message} = err;
          const _error = 'Failed to change email.';
        if (message.includes("Email")) {
          reject(new SubmissionError({_error, email: message}));
        }
        else if (message.includes("Password")) {
          reject(new SubmissionError({_error, password: message}));
        }
        else {
          reject(new SubmissionError({_error}));
        }
      });
    });
  }
})(AccountChangeEmail);

ChangeEmail = connect( state => ({
  initialValues: state.entityManagerReducer.record
}))(ChangeEmail)

export default ChangeEmail;
