import React from 'react';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Icon from '@material-ui/core/Icon';
import { MenuItemLink } from 'react-admin';
import {Link} from 'react-router-dom';
import Divider from "@material-ui/core/Divider";

const NestedItems = ({classes, children, ...props}) => {
  return (
    <div>
      <ListItem
        button
        onClick={props.openNested}
        className={classes.item}
        disabled={props.disabled}
      >
        {props.in ? (
          <Icon className={classes.icon}>expand_more</Icon>
        ) : (
          <Icon className={classes.icon}>{props.icon}</Icon>
        )}
        <ListItemText
          primary={props.name}
          classes={{
            root: classes.itemText,
            primary: props.in ? `${classes.link}` : classes.link
        }} />
      </ListItem>
      <Collapse
        component="li"
        in={props.in}
        timeout="auto"
        unmountOnExit
      >
        <List disablePadding>
          {children}
        </List>
      </Collapse>
    </div>
  )
}

export default NestedItems
