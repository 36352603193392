import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
  Card,
  GreenButton,
} from './../';
import {clickToCopy} from '../../services';

let style = {
  title: {
    font: '18px ProximaNova-SemiBold',
    marginBottom: 20,
  },
  caption: {
    font: '14px ProximaNova-Regular',
    lineHeight: 1.5,
    marginBottom: 20,
  },
  buttons: {
    display: 'flex',
    // justifyContent: 'space-around',
  },
  button: {
    marginRight: 20,
  },
  input: {
    position: 'absolute',
    left: -1000,
    top: -1000,
    opacity: 0,
  }
}

// <CopyInput
//   label="Copy URL"
//   contentEditable="true"
//   value={cobrandLink({entity: gS(this.props, 'entityMgr.entity.cause'), slug: gS(this.props, 'record.slug')})}
//   id="campaign_site"
//   successMsg="Link Copied"
//  />

class ExternalSite extends Component {
  render() {

    const {
      id,
      successMsg,
      title,
      caption,
      url,
    } = this.props

    return (
      <Card root={this.props.root}>
        {title && (<div style={style.title}>{title}</div>)}
        {caption && (<div style={style.caption}>{caption}</div>)}
        <div style={style.buttons}>
          <GreenButton
            label="View"
            href={url}
            target="_blank"
            small
            icon="open_in_new"
            style={style.button}
            disabled={this.props.disabled}
          />
          <GreenButton
            label="Copy"
            small
            icon="link"
            onClick={clickToCopy({id,"component":this, successMsg})}
            style={style.button}
            disabled={this.props.disabled}
          />
        </div>
        <input value={url} id={id} style={style.input} />
      </Card>
    )
  }
}

export default connect()(ExternalSite);
