import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

const style = {
  root: {
    color: '#23BFB8',
    paddingLeft: 20,
    paddingRight: 20,
    fontFamily: "'ProximaNova-SemiBold'",
    fontSize: 16,
    letterSpacing: 1,
    borderRadius: 20,
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      color: '#1e5e75',
      border: '1px solid #AA4100',
    }
  },
  disabled: {
    opacity: '0.4',
    color: '#23bfb8 !important',
  },
  outlined: {
    color: '#23BFB8',
    background: 'transparent',
    paddingLeft: 20,
    paddingRight: 20,
    fontFamily: "'ProximaNova-SemiBold'",
    fontSize: 16,
    letterSpacing: 1,'@media (max-width: 533px)': {
      justifyContent: 'space-evenly',
    }
  },
  outlineDisabled: {
    opacity: '0.4',
    border: '1px solid #23BFB8 !important',
    color: '#23BFB8 !important',
  },
  small: {
    fontSize: '12px !important',
    paddingTop: 8,
    marginRight: '20px',
    borderRadius: '20px',
    border: '1px solid #FFFFFF',
    '&:hover': {
      border: '1px solid #23bfb8',
      background: 'transparent'
    }
  },
  icon: {
    fontSize: 18,
    marginRight: 10,
  }
}

const GreenLink = ({classes, ...props}) => {
  return (
    <Button
      {...props}
      onClick={props.onClick}
      variant='text'
      size={props.small ? 'small' : ''}
      classes={{
        text: classes.root,
        disabled: props.outlined ? classes.outlineDisabled : classes.disabled,
        text: classes.outlined,
        sizeSmall: classes.small,
      }}
      >
        {props.icon && (<Icon style={style.icon}>{props.icon}</Icon>)}
        {props.label}
      </Button>
  )
}

export default withStyles(style)(GreenLink)
