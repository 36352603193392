import React, {Component, lazy, Suspense} from 'react';
import PropTypes from "prop-types";
import ListSubheader from "@material-ui/core/ListSubheader";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import Icon from '@material-ui/core/Icon';
import { MenuItemLink } from 'react-admin';
import {Link} from 'react-router-dom';
import Divider from "@material-ui/core/Divider";
import { withStyles } from "@material-ui/core/styles";
import { getFromNestedSafely as gS, entityMgrConnect } from '../../services';
import {
  EntityMenu,
  CampaignMenu,
  FundraiserMenu,
  BoosterMenu
} from './menus';

const style = {
  content: {
    width: '100%',
    marginTop: 20,
    // outline: '1px solid red',
  },
  item: {
    padding: '0 15px',
    margin: 0,
  },
  nestedItem: {
    padding: '0 15px 0 30px',
    margin: 0,
  },
  itemText: {
    margin: 0,
    padding: 0,
  },
  link: {
    width: '100%',
    margin: 0,
    padding: '12px 10px',
    font: '16px ProximaNova-Regular',
    textDecoration: 'none',
    color: '#464D5E',
  },
  nestedLink: {
    width: '100%',
    padding: '12px 10px',
    font: '16px ProximaNova-Regular',
    textDecoration: 'none',
    color: '#464D5E',
  },
  activeLink: {
    font: '16px ProximaNova-SemiBold',
    color: '#23BFB8'
  },
  icon: {
    fontSize: 16,
    color: '#ADB1BA',
  },
  activeIcon: {
    fontSize: 16,
    color: '#23BFB8',
  },
}

class Menu extends Component {
  render() {
    const { classes } = this.props;
    const entityMgr = gS(this.props, 'entityMgr') || {}

    return (
      <div className={classes.content}>
        <List
          className={classes.root}
        >
        {entityMgr.realm === 'entity' && (<EntityMenu {...this.props} />)}
        {entityMgr.realm === 'subentity' && (<CampaignMenu {...this.props} />)}
        {entityMgr.realm === 'unit' && (<FundraiserMenu {...this.props} />)}
        {entityMgr.realm === 'subunit' && (<BoosterMenu {...this.props} />)}

        </List>
      </div>
    )
  }
}

export default withStyles(style)(entityMgrConnect(Menu));
