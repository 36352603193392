const style = {
  buttons: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '@media (max-width: 533px)': {
      justifyContent: 'center',
    }
  },
  iconButtons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap',
    '@media (min-width: 533px)': {
      display: 'none',
    }
  },
  button: {
    marginRight: 5,
    marginLeft: 5,
    marginBottom: 5,
  },
  textButton: {
    marginRight: 5,
    marginLeft: 5,
    marginBottom: 5,
    alignItems: 'normal'
  },
  title: {
    font: '18px ProximaNova-SemiBold',
  },
  marketing: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      display: 'block',
    }
  },
  marketingCard: {
    boxShadow: `0px 0px 6px rgba(225,98,0,0.8)`,
    marginBottom: 10,
  },
  marketingButton: {
    flexShrink: 0,
  },
  guideTitle: {
    font: '18px ProximaNova-SemiBold',
    marginRight: 40,
    display: 'flex',
    alignItems: 'center',
    color: '#FF6200',
    '@media (max-width: 600px)': {
      font: '16px ProximaNova-SemiBold',
      marginBottom:10
    }
  },
}

export default style;
