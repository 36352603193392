import React, {Component} from 'react';
import {Field} from 'redux-form';
import {
  LabeledTextInput,
  GreenButton,
  Edit
} from './../';
import {
  formEnhancer,
  getFromNestedSafely as gS,
} from './../../services';
import { crudGetOne, crudUpdate } from './../../aor-loopback/dataActions';
import { required, phoneNumber } from './../../validators';

const style = {
  save: {
    margin: '30px auto 0',
    textAlign: 'center'
  },
  input: {
    width: '80%',
  },
  row: {
    display: 'flex'
  },
}

class AccountForm extends Component {

  submitDetails(values, action, props) {
    props.save(values, `${props.basePath}`);
  }

  render() {
    return (
    <div>
      <form id="details">
      <div style={style.row}>
        <Field
          {...this.props}
          name="first_name"
          component={LabeledTextInput}
          label="First Name"
          validate={required}
          style={style.input}
        />
        <Field
          {...this.props}
          name="last_name"
          component={LabeledTextInput}
          label="Last Name"
          validate={required}
          style={style.input}
        />
      </div>
      <div style={style.row}>
        <Field
          {...this.props}
          name="phone_number"
          component={LabeledTextInput}
          type="tel"
          validate={phoneNumber}
          label="Phone Number"
          defaultValue="XXX-XXX-XXXX"
          style={style.input}
        />
        <Field
          {...this.props}
          name="email"
          component={LabeledTextInput}
          type="email"
          label="Email Address"
          style={style.input}
          disabled
        />
        </div>
        <GreenButton
          label="Save Contact Details"
          onClick={this.props.handleSubmit(this.submitDetails)}
          style={style.save}
          small
        />
      </form>
    </div>
    )
  }
}

const EnhanceAccountForm = formEnhancer({
  FormComponent: AccountForm,
  formName: 'details'
});

const AccountDetails = (props) => {
  const entityMgr = gS(props, 'entityMgr') || {};
  return (
    <Edit
      crudGetOne={crudGetOne}
      crudUpdate={crudUpdate}
      resource="entitymanagers"
      id={entityMgr.id}
      location={{"pathname":"/account"}}
      basePath="/account"
      undoable={false}
    >
      <EnhanceAccountForm />
    </Edit>
  )
};

export default AccountDetails
