import React, {Component} from 'react';
import {
  entityMgrConnect,
  getFromNestedSafely as gS,
} from './../../services';
import {
  Card,
} from './../../components';


import './style.css'

const style = {
  root: {
    marginBottom: 20,
    minWidth: '280px'
  },
  content: {
    margin: 0,
  },
  mainTitle:{
    font: '22px ProximaNova-SemiBold',
    marginBottom: 0,
    marginTop: 10,
    textAlign: 'center'
  },
  title:{
    font: '20px ProximaNova-SemiBold',
    marginBottom: 10,
    marginTop: 40,
    marginLeft: 15,
  },
}

class FAQ extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    let q = document.getElementsByClassName('question');
    let i;

    for (i = 0; i < q.length; i++) {
      q[i].addEventListener('click', function() {
        this.classList.toggle('active');
        const a = this.nextElementSibling;
        if (a.style.maxHeight) {
          a.style.maxHeight = null;
        } else {
          a.style.maxHeight = a.scrollHeight + 'px';
        }
      })
    }
  }


  render() {
    const sponsor = gS(this.props.entityMgr, 'entity.cause.is_sponsor') === true ? true : false;
    const type = (gS(this.props.entityMgr,'realm'))
    const focps = gS(this.props.entityMgr, 'entity.gb_cause_id') === 76 ? true : false;
    const ocps = gS(this.props.entityMgr, 'entity.gb_cause_id') === 77 ? true : false;

    return (
<div>
  <div style={style.mainTitle}>Frequently Asked Questions</div>

  <div style={style.title}>Account</div>

        <Card root={style.root} content={style.content}>
          <button className="question">
            How do I change my email?
          </button>
          <div className="answer">
            <p>Go to the Account page located in the top right corner of your window, and click "Change Email."</p>
          </div>
        </Card>

        <Card root={style.root} content={style.content}>
          <button className="question">
            How do I change my password?
          </button>
          <div className="answer">
            <p>Go to the Account page located in the top right corner of your window, and click "Change Password."</p>
          </div>
        </Card>

  <div style={style.title}>Fundraising</div>

        {type === 'subentity' ? (
        <Card root={style.root} content={style.content}>
          <button className="question">
            Can I collect donations as a Campaign?
          </button>
          <div className="answer">
            <p>Yes! You can share your Campaign Site with others, who will be able to make a donation directly to your Campaign, or choose a specific fundraiser if you have started one.</p>
          </div>
        </Card>
        ) : ''}

        {type === 'subentity' ? (
        <Card root={style.root} content={style.content}>
          <button className="question">
            How do I create a Fundraiser?
          </button>
          <div className="answer">
            <p>You can create a new fundraiser by opening the "Fundraisers" dropdown on the side navigation, and clicking "Create Fundraiser"</p>
          </div>
        </Card>
        ) : ''}

        {type === 'subunit' ? (
        <Card root={style.root} content={style.content}>
          <button className="question">
            Can I edit my Booster Site during the fundraiser?
          </button>
          <div className="answer">
            <p>Yes, you can update your Booster Site at anytime!</p>
          </div>
        </Card>
        ) : ''}

        {type === 'unit' ? (
        <Card root={style.root} content={style.content}>
          <button className="question">
            Can I edit my Fundraiser Site during the fundraiser?
          </button>
          <div className="answer">
            <p>Yes, you can edit the Fundraiser Site at anytime!</p>
          </div>
        </Card>
        ) : ''}

        <Card root={style.root} content={style.content}>
          <button className="question">
            What's the best way to get donations?
          </button>
          <div className="answer">
            <p>It's no secret that people can only donate to causes that they know about. That is why we highly suggest you share your site everyday! Whether you share it on social media, email, text message, or by using our Donor Invitation System every time will help increase donations!</p>
          </div>
        </Card>

        {type === 'unit' ? (
        <Card root={style.root} content={style.content}>
          <button className="question">
            What are Boosters?
          </button>
          <div className="answer">
            <p>Boosters are individuals you invite to support your fundraiser. Once you request an individual to participate in your fundraiser, they will be able to create a personalized version of your fundraiser that they can share with their friends & family. As the Fundraiser Manager, you will be able to manage their page and track how much they have raised for your fundraiser!</p>
          </div>
        </Card>
        ) : ''}

        {type === 'unit' ? (
        <Card root={style.root} content={style.content}>
          <button className="question">
            How do I invite Boosters?
          </button>
          <div className="answer">
            <p>You can invite Boosters to your fundraiser by using the "Add Booster" button on your dashboard.</p>
          </div>
        </Card>
        ) : ''}

{(focps || ocps && type === 'unit') || (focps || ocps && type === 'subentity') ? (
<div>
  <div style={style.title}>Fund Collection & Disbursement</div>
  <Card root={style.root} content={style.content}>
    <button className="question">
      When someone makes a donation where do the funds go?
    </button>
    <div className="answer">
      <p>When an someone makes a donation to your site their donation is processed by PayPal, and then directly deposited to the Foundation for Orange County Public Schools.</p>
    </div>
  </Card>

  <Card root={style.root} content={style.content}>
    <button className="question">
      Do I need to setup a Fund Account with FOCPS?
    </button>
    <div className="answer">
      <p>Each school must have one Fund Account setup with FOCPS to use the greenback Fundraising Platform. If you school does NOT have a Fund Account you can easily submit a <a href="https://www.foundationforocps.org/uploads/docs/blocks/29/new-fund-form-1.pdf" target="_blank">New Fund Form</a> to FOCPS.</p>
    </div>
  </Card>

  <Card root={style.root} content={style.content}>
    <button className="question">
      How do I receive my funds?
    </button>
    <div className="answer">
      <p>The Foundation for Orange County Public Schools (FOCPS) will place the donations made to your site in your school's Fund Account. Each month your school will receive a report of your Fund Account's balance which can be accessed by submitting a <a href="https://www.foundationforocps.org/uploads/docs/blocks/29/check-reimbursement-form-v3-1.pdf" target="_blank">Check Request Form</a>.</p>
    </div>
  </Card>

  <Card root={style.root} content={style.content}>
    <button className="question">
      Check Request Instructions
    </button>
    <div className="answer">
      <p>The Foundation team is happy to process your check requests for dollars from your school fund. We can handle it a few different ways:<ol><li>You can attach an invoice to the check request; we then send the check directly to a vendor for a specific purchase or activity.</li><li>You may submit receipts for reimbursement for items or services purchased.</li><li>You may request a check made payable to your school.</li></ol>IMPORTANT: If you choose Option 3, we will need follow-up documentation from you showing how the funds were spent and verifying that the funds were used as the donor intended. This is because our auditors and the law require us to (1) record how charitable contributions are used and (2) ensure that the donor’s intent was honored.
When you submit invoices to be paid directly or receipts for reimbursement (Options 1 and 2), we have the documentation needed for our auditors and to be in compliance with the law.<br></br><br></br>Download the Check Request Form <a href="https://www.foundationforocps.org/uploads/docs/blocks/29/check-reimbursement-form-v3-1.pdf" target="_blank">here</a>.</p>
    </div>
  </Card>

  <Card root={style.root} content={style.content}>
    <button className="question">
      How soon will I receive my funds after submitting my request?
    </button>
    <div className="answer">
      <p>Checks are released weekly on Friday. Some exceptions may apply. To ensure timely payment, requests must be submitted Monday prior to Wednesday processing.</p>
    </div>
  </Card>

</div>
        ) : ''}

{type === 'subentity' ? (
<div>
  <div style={style.title}>Campaign Portal</div>
  <Card root={style.root} content={style.content}>
    <button className="question">
      What is a Campaign Portal?
    </button>
    <div className="answer">
      <p>The In-App Portal</p>
    </div>
  </Card>

  <Card root={style.root} content={style.content}>
    <button className="question">
      Who can see the Campaign Portal?
    </button>
    <div className="answer">
      <p>The In-App Portal is able to be viewed through the greenback mobile application by any member that has donated to your campaign or fundraiser related to your campaign.</p>
    </div>
  </Card>

  <Card root={style.root} content={style.content}>
    <button className="question">
      How often can I edit my Campaign Portal?
    </button>
    <div className="answer">
      <p>Campaign Portals can be edited as many times as you like. Once you make a change to your Campaign Portal any greenback member that has donated to your campaign, or fundraiser related to your campaign will see the changes through their greenback mobile app.</p>
    </div>
  </Card>
</div>
) : ''}

{type === 'entity' && sponsor ? (
<div>
  <div style={style.title}>Sponsor Portal</div>

  <Card root={style.root} content={style.content}>
    <button className="question">
      What is a Sponsor Portal?
    </button>
    <div className="answer">
      <p>The Sponsor Portal is a section inside the greenback mobile application where you can share information, news, and other details with your users.</p>
    </div>
  </Card>

  <Card root={style.root} content={style.content}>
    <button className="question">
      Who can see the Sponsor Portal?
    </button>
    <div className="answer">
      <p>Your Sponsor Portal is only visible to members that have donated to a Cause through your Sponsor Site, or has been delivered a membership by your organization.</p>
    </div>
  </Card>

  <Card root={style.root} content={style.content}>
    <button className="question">
      How often can I edit the Sponsor Portal?
    </button>
    <div className="answer">
      <p>You can edit your Sponsor Portal as many times as you like. Once you make a change to your Sponsor Portal your members will see the change in their greenback mobile app.</p>
    </div>
  </Card>

</div>

) : ''}

{type === 'entity' && !sponsor ? (
<div>
  <div style={style.title}>Cause Portal</div>

    <Card root={style.root} content={style.content}>
      <button className="question">
        What is a Cause Portal?
      </button>
      <div className="answer">
        <p>The Cause Portal is a section inside the greenback mobile application where you can share information, news, and other details with your donors.</p>
      </div> </Card>

      <Card root={style.root} content={style.content}>
        <button className="question">
          Who can see the Cause Portal?
        </button>
        <div className="answer">
          <p>Your Cause Portal is only visible to any member that has donated to a Cause through your Sponsor Site, or has been delivered a membership via  to your Cause.</p>
        </div>
      </Card>

      <Card root={style.root} content={style.content}>
          <button className="question">
            How often can I edit the Cause Portal?
          </button>
          <div className="answer">
            <p>You can edit your Cause Portal as many times as you like. Once you make a change to your Cause Portal your donors will see the changes through their greenback mobile app.</p>
          </div>
        </Card>

  </div>
    ) : ''}

<div style={style.title}>General</div>

    <Card root={style.root} content={style.content}>
      <button className="question">
        I have a different question.
      </button>
      <div className="answer">
        <p>Please contact us at <a href="mailto=help@gogreenback.com">help@gogreenback.com</a>, and we will be happy to answer your question!</p>
      </div>
    </Card>

  </div>
    )
  }
}

export default entityMgrConnect(FAQ)
