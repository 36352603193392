import React from 'react';
import Radium from 'radium';
import { connect } from 'react-redux';
import {entityMgrConnect, getFromNestedSafely as gS } from './../../services';
import {style} from './style.js';
import _ from 'lodash';

const CobrandedHomePreview = (props) => {
  const pgs = gS.bind(null, props);

  const causeID = pgs('entityMgr.entity.cause.is_sponsor') ? pgs('entityMgr.entity.cause.sponsors_of', [])[0] : pgs('entityMgr.entity.gb_cause_id');
  const parentName =  pgs('entityMgr.entity.gb_cause.name');
  const name = pgs('subentity.templating.name') || 'Campaign Name Here';
  const logo = pgs('subentity.templating.logo') || '//cloudfront.startupmedia.com/greenback/mgmtportal/circle-upload-background.jpg';
  const causeLogo = `//cloudfront.startupmedia.com/greenback/entities/${causeID}/0/logo`

  let mainPitch, donationPitch;
  if (pgs('subentity.entity_id') === 77 && pgs('subentity.type') === 'subentity') {
    // If is OCPS - ID 77
    mainPitch = `Your donation goes to ${pgs('subentity.templating.name')}, and you save everywhere you love to shop, dine, and travel!`
    donationPitch = `Make a donation to <b>${pgs('subentity.templating.name')}</b> and get your <b>greenback</b> membership today!<h6>Your donation is processed by: </br><b>The Foundation for Orange County Public Schools</b></h6>`

  } else if (pgs('entityMgr.entity.cause.is_sponsor')) {
    // Sponsor related Pitches
    mainPitch = `Support the ${pgs('subentity.templating.name')} Campaign, and save everywhere you love to shop, dine, and travel!`
    donationPitch = `Make a donation to <b>${_.isEmpty(pgs('entityMgr.entity.cause.sponsors_of')) || pgs('entityMgr.entity.cause.sponsors_of').length > 1 ? 'one of our causes' : pgs('entityMgr.entity.cause.name')} </b> and get your <b>greenback</b> membership today!`
  } else {
    // Cause related Pitches
    mainPitch = `Support the ${pgs('subentity.templating.name')} Campaign, and save everywhere you love to shop, dine, and travel!`
    donationPitch = `Make a donation to <b>${pgs('entityMgr.entity.cause.name')}</b> and get your <b>greenback</b> membership today!`
  }

  return (
    <div style={style.cobrand}>

      <div style={style.landing}>
        <div style={style.landingBefore} />
        <div style={style.wrapper}>
          <div style={style.colLeft}>
            <div style={style.header}>
              <div style={style.logos}>
                <img style={style.entityLogo} src={logo} />
                <img style={style.gbLogo} src="//cloudfront.startupmedia.com/gb/imgs/global/gb/gb-full-light-nogo.png" />
              </div>
              <div style={style.pitch}>
                {mainPitch}
              </div>
              <div style={style.providers}>
                <img style={style.providerLogos} src="//cloudfront.startupmedia.com/gb/imgs/global/brands-new-1.png" />
                <img style={style.providerLogos} src="//cloudfront.startupmedia.com/gb/imgs/global/brands-new-2.png" />
              </div>
            </div>
            <div style={style.content}>
              <div style={style.title} dangerouslySetInnerHTML={{__html: donationPitch}}></div>

              <img style={style.causeLogo} src={causeLogo} />
              <div style={style.fakeButton}>Donate</div>
            </div>
          </div>
          <div style={style.colRight}>
            <div style={style.phone} />
          </div>
        </div>
        <div style={style.quoteWrapper}>
          <div style={style.quote}>
            "This app is simply genius! I have had the app for a couple months and have saved $263! This is the best money I have ever given to charity!" <span style={{display:'block',fontStyle:'italic'}}>- Jayden</span>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Radium(CobrandedHomePreview);
