import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';

const style = {
  input: {
    color: '#464D5E',
    fontFamily: "'ProximaNova-Light'",
    lineHeight: 1.5,
    fontSize: 14,
    '&:hover': { border: 'none !important' },
    '&:before': {borderBottom: 'none !important'},
  },
  underline: {
    border: 'none',
    '&:before': { border: 'none' },
    '&:hover:before': { border: 'none' },
    '&:after': { border: 'none' },
  },
  label: {
    fontFamily: "'ProximaNova-Light'",
    color: '#777B84',
    fontSize: 16,
    '&:hover': { border: 'none' },
    '&$labelFocused': { color: '#777B84' },
  },
  labelFocused: {},
  adornment: {
    marginBottom: 3,
  }
}

const LabeledNumberField = ({
  meta: { touched, error } = {},
  classes,
  ...props}) => {
  return (

    <TextField
      {...props}
      error={touched && error}
      helperText={touched && error}
      fullWidth
      inputProps={{
        maxLength: props.maxLength,
        readOnly: true
      }}
      InputProps={{
        classes: {
          root: classes.input,
          underline: classes.underline,
        },
        startAdornment: <InputAdornment style={style.adornment} position="start">$</InputAdornment>
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.label,
          focused: classes.labelFocused,
        }
      }}
    />

  )
}

export default withStyles(style)(LabeledNumberField)
