export default function subscribePreviewToContentHeight() {
	function setHeightofPreview() {
    const contentEl = document.getElementById('content');
    if (contentEl && document.getElementById('preview')) {
      document.getElementById('preview').style.height = contentEl.offsetHeight;
    }
	}
	window.onresize = () => {
		setHeightofPreview();
	}
}
