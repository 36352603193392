import React from 'react';
import Radium from 'radium';
// import { Link } from 'react-router-dom';
// const RadiatingHashLink = Radium(HashLink);

const OrangeButton2 = (props) => {
  const style = {
    btn: {
      color: '#FFF',
      backgroundColor: '#FF6200',
      paddingLeft: 20,
      paddingRight: 20,
      paddingTop: 10,
      paddingBottom: 10,
      fontFamily: "'ProximaNova-SemiBold'",
      fontSize: 16,
      letterSpacing: 1,
      textDecoration: 'none',
      boxShadow: '0 8px 8px 0 rgba(255,98,0,0.10)',
      borderRadius: 20,
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        backgroundColor: '#AA4100'
      }
    },
  }

  return (
    <div>
      {props.onClick ? (
        <div {...props} style={style.btn}>
          {props.title ? props.title : 'Get Started'}
        </div>
      ) : props.href ? (
        <a style={style.btn} href={props.href} target='_blank'>{props.title}</a>
      ) : (
        <div />
      )}
    </div>
  )
}

export default Radium(OrangeButton2);
