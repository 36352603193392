import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'react-admin';
import _ from 'lodash';

export const ListName = ({record, source, maxLength}) => {
  const name = _.get(record, source) || ''
  const length = maxLength
  var trimmedName = name.length > length ? name.substring(0, length - 3) + '...' : name
  return (
    <div>{trimmedName}</div>
  );
};

export default ListName;
