let grid = {
  grid: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 1120,
    width: '100%',
    minWidth: 300,
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
  },
  col33: {
    display: 'flex',
    width: 'calc(33.3333% - 10px)',
    marginLeft: 5,
    marginRight: 5,
    '@media (max-width: 840px)': {
      width: '100%',
    }
  },
  col40: {
    display: 'flex',
    width: 'calc(40% - 10px)',
    marginLeft: 5,
    marginRight: 5,
    '@media (max-width: 840px)': {
      width: '100%',
    }
  },
  col50: {
    display: 'flex',
    width: 'calc(50% - 10px)',
    marginLeft: 5,
    marginRight: 5,
    '@media (max-width: 840px)': {
      width: '100%',
    }
  },
  col60: {
    display: 'flex',
    width: 'calc(60% - 10px)',
    marginLeft: 5,
    marginRight: 5,
    '@media (max-width: 840px)': {
      width: '100%',
    }
  },
  col: {
    width: 'calc(100% - 10px)',
    marginLeft: 5,
    marginRight: 5,
  },
  colWidget: {
    width: 'calc(100% - 40px)',
    marginLeft: 5,
    marginRight: 5,
  }
}

export default grid
