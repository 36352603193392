import React, { Component } from 'react';
import Radium from 'radium';
import moment from 'moment';
import {
  Card,
  GreenButton,
  ClayPhone,
  DynamicComponents,
  Edit,
  ImageField,
  LabeledTextField,
  LabeledNumberField,
  CopyInput,
  ExternalSiteWidget,
  FormCard
} from '../';
import { crudGetOne, crudUpdate } from './../../aor-loopback/dataActions';
import { Field } from 'redux-form';
import {
  entityMgrConnect,
  getFromNestedSafely as gS,
  clickToCopy,
  cobrandLink,
  getSubentityIds,
  getSumSentBySubentityIdWithChildren
} from './../../services';

let style = {
  content: {
    width: '100%',
    display: 'flex',
    '@media (max-width: 640px)': {
      flexDirection: 'column'
    }
  },
  details: {
    width: 'calc(50% - 10px)',
    marginRight: 10,
    height: 'fit-content',
    '@media (max-width: 640px)': {
      width: '100%',
      marginLeft: 0,
      marginBottom: 20,
    }
  },
  portal: {
    width: 'calc(50% - 10px)',
    marginLeft: 10,
    '@media (max-width: 640px)': {
      width: '100%',
      marginLeft: 0,
    }
  },
  title: {
    font: '18px ProximaNova-SemiBold',
    marginBottom: 20,
  },
  detailsHeader: {
    position: 'relative',
    display: 'flex',
    // justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 20,
  },
  col: {
    flex: 1,
    marginLeft: 20,
  },
  card: {
    marginBottom: 20,
  },
  input: {
    marginBottom: 20,
  },
  save: {
    textAlign: 'center',
    marginTop: 20,
  },
  campaignSite: {
    borderTop: '1px solid #ADB1BA',
    paddingTop: 20,
    marginTop: 20,
  },
  countWrap: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  countTitle: {
    font: '18px ProximaNova-SemiBold',
  },
  countLine: {
    height: 1,
    borderTop: '1px dashed #979797',
    flex: 1,
    marginBottom: 5,
    marginLeft: 10,
    marginRight: 10,
  },
  countNumber: {
    font: '38px ProximaNova-SemiBold',
  },
  fundLink: {
    display: 'block',
    fontSize: 22,
    fontFamily: 'ProximaNova-SemiBold',
    color: '#2CBBB7',
    marginBottom: 20,
  },
  fundCode: {
    display: 'block',
    color: '#1E5E75',
    fontSize: 22,
    fontFamily: 'ProximaNova-SemiBold',
    marginTop: 20,
    letterSpacing: 4
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  id: {
    font: '14px ProximaNova-SemiBold',
    color: '#ADB1BA'
  },
}

class FundraiserOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sumOfSent: 0,
      totalSubentities: []
    }
  }

  componentDidMount() {
    Promise.all([
      getSumSentBySubentityIdWithChildren({
        "subentity_id": gS(this.props, 'record.id'),
        "filter":{"is_active": true},
        "pluck_parent": gS(this.props,'entityMgr.subentity.type') === 'subunit' ? false : true,
      }),
      getSubentityIds({
        "subentity_ids": [gS(this.props, 'record.id')],
        "args": {
          "filter":{"where": {"subentity_id": gS(this.props, 'record.id')}, "is_active": true},
          "pluck_parent": gS(this.props,'entityMgr.subentity.type') === 'subunit' ? false : true,
        }
      })
    ]).then(res => {
      console.log(res);
      this.setState({
        sumOfSent: gS(res[0],'sum_of_sent', 0),
        totalSubentities: res[1],
      });
    }).catch(err => {
      console.log(err);
      this.setState({
        sumOfSent: 0,
        totalSubentities: []
      });
    });
  }

  render() {
    const entityMgr = gS(this.props, 'record.entity_manager') || {};
    const templating = gS(this.props, 'record.templating') || {};
    const endDate = moment(gS(this.props, 'record.templating.fundraising.end_date')).format('MM/DD/YYYY') || {};
    const disabledByFundraiserSite = gS(this.props, 'record.templating.logo') ? 0 : 1;

    return (
      <div style={style.content}>
        <Card root={style.details}>
          <div style={style.header}>
            <div style={style.title}>Fundraiser Site Details</div>
            <div style={style.id}>ID: {gS(this.props, 'record.id')}</div>
          </div>
            <div style={style.input}>
              <ImageField
                value={templating.logo}
                variant="circle"
                ratio={1/1}
              />
            </div>
            <LabeledTextField
              label="Fundraiser Title"
              value={templating.name}
              style={style.input}
            />
            <LabeledTextField
              label="Signature (optional)"
              value={templating.signature}
              style={style.input}
            />
            <LabeledNumberField
              label="Fundraiser Goal"
              value={gS(templating, 'fundraising.goal')}
              type="number"
              style={style.input}
            />
            <LabeledTextField
              label="Fundraiser End Date"
              value={endDate}
              style={style.input}
            />
            <LabeledTextField
              label="How Funds Are Used"
              value={gS(templating, 'fundraising.short_description')}
              multiline
              style={style.input}
            />
            <LabeledTextField
              label="Special Message"
              value={gS(templating, 'fundraising.long_description')}
              multiline
              style={style.input}
            />
        </Card>
        <div style={style.portal}>
          <Card root={style.card}>
            <div style={style.title}>Fundraiser Manager Details</div>
            <div style={style.input}>
              <LabeledTextField
                label="Manager's Name"
                value={entityMgr.first_name + ' ' + entityMgr.last_name}
              />
            </div>
            <div style={style.input}>
              <LabeledTextField
                label="Manager's Email"
                value={entityMgr.email}
              />
            </div>
          </Card>
          <ExternalSiteWidget
            root={style.card}
            title="Fundraiser Site"
            caption="View or copy the Fundraiser Site below."
            id="cobrand-site"
            successMsg="Fundraiser Site Link Copied"
            disabled={disabledByFundraiserSite}
            url={cobrandLink({entity: gS(this.props, 'entityMgr.entity.cause'), slug: gS(this.props, 'record.slug')})}
          />
        <Card root={style.card} content={{lineHeight: 1.5}}>
            <div style={style.title}>Invite Boosters</div>
            <div style={style.input}>
              Share this unique Fundraiser Code with individuals to become a Booster for this fundraiser.
              <br /><br/>
              <FormCard content={{textAlign:'center'}}>
                <div style={style.title}>Booster Registration Site:</div>
                <a href="http://getgb.us/join" style={style.fundLink} target="blank">getgb.us/join</a>
                <div style={style.title}>Fundraiser Code:</div>
                <span style={style.fundCode}>{this.props.record.fundraiser_code}</span>

              </FormCard>
            </div>
          </Card>
          <Card content={style.countWrap} root={{marginBottom: 20}}>
            <div style={style.countTitle}>Boosters</div>
            <div style={style.countLine} />
            <div style={style.countNumber}>{this.state.totalSubentities.length}</div>
          </Card>
          <Card content={style.countWrap} root={{marginBottom: 20}}>
            <div style={style.countTitle}>DIS Invites Sent</div>
            <div style={style.countLine} />
              <div style={{...style.countNumber, ...{color: '#FF6200'}}}>{this.state.sumOfSent}</div>
          </Card>
          <Card content={style.countWrap} root={{marginBottom: 20}}>
            <div style={style.countTitle}>Funds Raised</div>
            <div style={style.countLine} />
            <div style={{...style.countNumber, ...{color: '#23BFB8'}}}>${gS(this.props, 'record.earnings') || '0'}</div>
          </Card>
        </div>
      </div>
    )
  }
}

export default Radium(FundraiserOverview);
