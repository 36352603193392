import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Settings from '@material-ui/icons/Settings';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { withStyles } from '@material-ui/core/styles';
import { toggleSidebar as toggleSidebarAction } from 'ra-core';
import {
  LoadingIndicator,
  MenuItemLink,
  UserMenu,
 } from 'react-admin';

import {
  logout as logoutAction
} from './../../actions/entityManager';

let style = {
  bar: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: 60,
    zIndex: 500,
    background: 'linear-gradient(90deg, #1E5E75 0%, #0E9BA6 63%)',
  },
  logo: {
    height: 30,
    // marginLeft: 20,
    display: 'block',
    '@media (max-width: 640px)': {
      height: 30,
      marginLeft: 10,
      display: 'block',
    }
  },
  menuButton: {
    marginLeft: -12,
  },
  title: {
    marginLeft: 20,
    font: "24px 'ProximaNova-Light'",
    '@media (max-width: 640px)': {
      font: "16px 'ProximaNova-Light'",
    }
  },
  linkWrapper: {
    padding: 0,
    height: 'auto',
  },
  link: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: 16,
    textDecoration: 'none',
    color: '#464D5E',
    font: "14px 'ProximaNova-Light'",
    width: 200,
  },
  accountButton: {
    marginLeft: 'auto'
  }
}

class MenuAppBar extends React.Component {
  state = {
    auth: true,
    anchorEl: null,
  };

  handleChange = event => {
    this.setState({ auth: event.target.checked });
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleLogout = () => {
    this.props.logout();
  }

  render() {
    const { classes, toggleSidebar, open, isMobile } = this.props;
    const { auth, anchorEl } = this.state;
    const menuOpen = Boolean(anchorEl);

    return (
      <AppBar style={style.bar} className="appbar">
        <Toolbar>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
            onClick={toggleSidebar}
          >
            <MenuIcon />
          </IconButton>

          <a href="/"><img className={classes.logo} src={isMobile ? "https://cloudfront.startupmedia.com/greenback/icons/gb-icon.png" : "https://cloudfront.startupmedia.com/greenback/logos/gb-logo-icon-light.png"} /></a>
          <span className={classes.title}>Management Portal</span>

          <div className={classes.accountButton}>
            <IconButton
              aria-owns={menuOpen ? 'menu-appbar' : undefined}
              aria-haspopup="true"
              onClick={this.handleMenu}
              color="inherit"
            >
              <Settings />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={menuOpen}
              onClose={this.handleClose}
            >
              <MenuItem onClick={this.handleClose} classes={{"root":classes.linkWrapper}}><Link className={classes.link} to='/account'>Settings</Link></MenuItem>
              <MenuItem onClick={this.handleClose} classes={{"root":classes.linkWrapper}}><Link className={classes.link} to='/login' onClick={this.handleLogout}>Logout</Link></MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    )
  }
}


MenuAppBar.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  logout: PropTypes.element,
  open: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  toggleSidebar: PropTypes.func.isRequired,
  userMenu: PropTypes.node,
  width: PropTypes.string,
};

const enhance = compose(
  connect(
    state => ({
      locale: state.i18n.locale, // force redraw on locale change
    }),
    {
      logout: logoutAction,
      toggleSidebar: toggleSidebarAction,
    }
  ),
  withStyles(style)
);

export default enhance(MenuAppBar);
