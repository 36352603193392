import React, {Component} from 'react';
import {OutlinedButtonInput} from './';
import { connect } from 'react-redux';
import {clickToCopy} from '../../services';
import Fragment from 'react-dot-fragment';


class CopyInput extends Component {
  render() {

    const {
      id,
      successMsg
    } = this.props

    return (
      <Fragment>
        <OutlinedButtonInput
          {...this.props}
          btnOnClick={clickToCopy({id,"component":this, successMsg})}
         />
      </Fragment>
    )
  }
}


export default connect()(CopyInput)
