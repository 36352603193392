const defaultPortal = {
  get: () => ({
    components: [
      {
        "name": "portal-header",
        "img": ""
      },
      {
        "name": "text-post",
        "header": "",
        "text": "",
        "buttons": []
      },
      {
        "name": "alternating-circ-text",
        "sections": [
          {
            "header": "",
            "text": "",
            "buttons": []
          },
          {
            "header": "",
            "text": "",
            "buttons": []
          }
        ]
      },
      {
        "name": "floating-tabs"
      }
    ]
  })
}

export default defaultPortal;
