export let style = {
  cobrand: {
    position: 'relative',
    background: '#FFF',
  },
  cobrand: {
    width: '100%',
    overflow: 'hidden',
  },
  cobrandScaled: {
    width: 1200,
  },
  header: {
    position: 'relative',
  },
  headerImg: {
    position: 'relative',
    width: '100%',
    height: 610,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  gradient: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: '65%',
    zIndex: 1,
    background: 'linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 28%,rgba(255,255,255,1) 100%)',
    filter: 'progid:DXImageTransform.Microsoft.gradient( startColorstr="#00ffffff", endColorstr="#ffffff",GradientType=1 )',
  },
  headerContent: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '50%',
    height: '100%',
    zIndex: 20,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  logo: {
    position: 'relative',
    display: 'block',
    height: 140,
    width: 'auto',
    flexShrink: 0,
    alignSelf: 'start'
  },
  headerPhrase: {
    position: 'relative',
    width: '80%',
    textAlign: 'left',
    marginTop: 70,
    font: '33px ProximaNova-Regular',
  },
  headerCaption: {
    position: 'relative',
    width: '80%',
    marginTop: 25,
    font: '22px ProximaNova-Light',
    lineHeight: 1.7,
  },
  content: {
    marginTop: 80,
    marginBottom: 80,
    textAlign: 'center',
  },
  serviceTitle: {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
    font: '36px ProximaNova-Light',
    textAlignLast: 'center',
    color: '#4A4A4A'
  },
  serviceCaption: {
    width: '60%',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 20,
    lineHeight: 1.5,
    font: '18px ProximaNova-Light',
    textAlignLast: 'center',
    color: '#4A4A4A'
  },
  services: {
    position: 'relative',
    width: '90%',
    marginTop: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    flexFlow: 'row wrap',
  },
  service: {
    display: 'flex',
    clear: 'both',
    minWidth: 470,
    maxWidth: '50%',
    width: '48%',
    marginBottom: 35,
    flexShrink: 0,
  },
  serviceIconWrapper: {
    width: 80,
    height: 45,
    marginRight: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'self',
    flexShrink: 0,
  },
  serviceContent: {
    width: '100%',
  },
  sTitle: {
    fontSize: 18,
    fontFamily: 'ProximaNova-SemiBold',
    color: '#4A4A4A',
    textAlign: 'left',
  },
  sDescription: {
    fontSize: 14,
    lineHeight: 1.5,
    color: '#4A4A4A',
    marginTop: 20,
    textAlign: 'left',
  },
  remBtn: {
    outline: 'none',
    border: 'none',
    color: '#f33',
    fontFamily: 'ProximaNova-SemiBold',
    background: 'transparent',
    marginBottom: 5,
    marginTop: 10,
    padding: 0,
    cursor: 'pointer',
    display: 'block',
    marginLeft: 'auto',
  },
  addBtn: {
    outline: 'none',
    border: 'none',
    color: '#2CBBB7',
    fontSize: 14,
    fontFamily: 'ProximaNova-SemiBold',
    background: 'transparent',
    marginBottom: 5,
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: 0,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  addIcon: {
    fontSize: 18,
  },
  addTxt: {
    marginLeft: 10,
  },
  chooseIcon: {
    fontSize: 18,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  iconSelected: {
    fontSize: 36,
    color: '#1E5E75',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  section: {
    position: 'relative',
  },
  sectionItem: {
    position: 'relative',
  },
  sectionCircle: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 476,
    height: 476,
    zIndex: 100,
    margin: '-238px 0 0 -238px',
    borderRadius: '50%',
    fontSize: 28,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundSize: '70%',
    backgroundPosition: '34% 58%',
    backgroundImage: 'url(//cloudfront.startupmedia.com/gb/imgs/global/gb/bg-circle-background-dark.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor: '#FFF',
  },
  sectionCircleText: {
    width: '80%',
    margin: '0 auto',
    lineHeight: 1.5,
  },
  sectionCircleImg: {
    position: 'relative',
    width: '100%',
    height: 480,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
}
