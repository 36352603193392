import React, {Component} from 'react';
import moment from 'moment';

const style = {
  error: {
    lineHeight: 'inhert',
    textAlign: 'left',
    fontSize: 10,
    color: 'red',
    marginTop: 7,
  },
  errorBorder: {
    borderBottom: '2px solid red',
  },
  container: {
    marginTop: 24,
    borderBottom: '1px solid #A4ACAF',
  },
  label: {
    color: '#777B84',
    fontFamily: "'ProximaNova-Light'",
		transform: 'scale(0.75)',
		transformOrigin: 'top left',
		marginBottom: 10,
    textAlign: 'left'
  },
  content: {
    display: 'flex',
    justContent: 'space-between',
  },
  select: {
    border: 0,
    '-webkit-appearance': 'none',
    '-webkit-border-radius': 0,
    borderRadius: 0,
    outline: 'none',
    paddingBottom: 5,
    backgroundColor: 'transparent',
    width: '30%',
    fontSize: 14,
    color: '#777B84',
    fontFamily: "'ProximaNova-Light'",
    textAlign: 'center',
    textAlignLast: 'center'
  },
  day: {
    borderLeft: '1px solid #A4ACAF',
    borderRight: '1px solid #A4ACAF'
  }
}

export class AgeCheckDateInput extends Component {

	inputs = {};

  componentDidMount() {
    let months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
    let daysInCurrMonth = ''

    function daysInMonth(month, year) {
      return new Date(year, month, 0).getDate();
    }

    const populateDates = () => {
      let today = new Date(),
          day = today.getUTCDate(),
          month = today.getUTCMonth(),
          year = today.getUTCFullYear(),
          daysInCurrMonth = daysInMonth(month, year);

      for (var i = 0; i < 100; i++) {
        let opt = document.createElement('option');
        opt.value = year - i;
        opt.text = year - i;
        this.inputs.yeardropdown.appendChild(opt);
      }

      for (var i = 1; i <= 12; i++) {
        var opt = document.createElement('option');
        opt.value = i;
        opt.text = months[i - 1];
        this.inputs.monthdropdown.appendChild(opt);
      }

      for (var i = 0; i < daysInCurrMonth; i++) {
        var opt = document.createElement('option');
        opt.value = i + 1;
        opt.text = i + 1;
        this.inputs.daydropdown.appendChild(opt);
      }
    }

    this.inputs.daydropdown = document.getElementById('day');
		this.inputs.monthdropdown = document.getElementById('month');
		this.inputs.yeardropdown = document.getElementById('year');

    const isLeapYear = y => !(y&3||y&15&&!(y%25));

    const getInputMomentInstance = () => {
      const mInst = moment(`${this.inputs.yeardropdown.value}-${this.inputs.monthdropdown.value.padStart(2, '0')}-${this.inputs.daydropdown.value.padStart(2, '0')}`, 'YYYY-MM-DD', true);
      return mInst;
    }

    this.inputs.daydropdown.onchange = () => {
      this.props.input.onChange(getInputMomentInstance());
    }

    this.inputs.yeardropdown.onchange = () => {
      isLeapYear(this.inputs.yeardropdown.value) && this.inputs.monthdropdown.value == 2 && setDaysFromMonthAndYear();
      this.props.input.onChange(getInputMomentInstance());
    }

    this.inputs.monthdropdown.onchange = () => {
      setDaysFromMonthAndYear();
      this.props.input.onChange(getInputMomentInstance());
    }

    const setDaysFromMonthAndYear = () => {
      const daysInCurrMonth = daysInMonth(this.inputs.monthdropdown.value, this.inputs.yeardropdown.value);

      this.inputs.daydropdown.innerHTML = '';

      const optDay = document.createElement('option');
      optDay.value = '';
      optDay.text = 'Day';
      optDay.disabled = true;
      optDay.selected = true;
      this.inputs.daydropdown.appendChild(optDay);

      for (var i = 0; i < daysInCurrMonth; i++) {
        let opt = document.createElement('option');
        opt.value = i + 1;
        opt.text = i + 1;
        this.inputs.daydropdown.appendChild(opt);
      }
    }

    populateDates();
  }

  componentWillReceiveProps(nextProps) {
    //the form was reset by redux so we individually reset each input
    if (this.props.meta && !this.props.meta.pristine && nextProps.meta.pristine) {
      this.inputs.daydropdown.selectedIndex = 0;
      this.inputs.monthdropdown.selectedIndex = 0;
      this.inputs.yeardropdown.selectedIndex = 0;
    }
  }


  render() {

    return (
      <div>
        <div style={{...style.container, ...this.props.meta.touched && this.props.meta.error ? style.errorBorder : ''}}>
          <div style={style.label}>{this.props.label}</div>
          <div style={style.conent}>
            <select id="month" style={style.select} required>
              <option disabled selected value="">Month</option>
            </select>
            <select id="day" style={{...style.select, ...style.day}} required>
              <option disabled selected value="">Day</option>
            </select>
            <select id="year" style={style.select} required>
              <option disabled selected value="">Year</option>
            </select>
          </div>
        </div>
        <div style={style.error}>{this.props.meta.touched && this.props.meta.error}</div>
      </div>
    )
  }
}

export default AgeCheckDateInput
