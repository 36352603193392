const _ = require('lodash');
const assert = require('assert');
  
export function getLikePath(filter, storedKeys = []) {
  if (!_.isPlainObject(filter)) return {};
  let i, key, prop, like;
  const keys = _.keys(filter);
  for (i in keys) {
    key = keys[i];
    prop = filter[key]; 
    if (_.get(filter, 'like')) {
      return {like: filter.like, key: storedKeys.join("."), keys: storedKeys};
    }
    else if (prop) {
      like = getLikePath(filter[key], storedKeys.concat(key));
      if (_.get(like, 'like')) return like;
    }
  }
}

export const test = () => {


  const filter1 = {
    "where": {
      "templating": {
        "like": "john"
      },
    }
  };

  const filter2 = {
    "where": {
      "templating": {
        "name": {
          "like": "jacob"
        }
      },
    }
  };

  const filter3 = {
    "where": {
      "templating": {
        "first": {
          "name": {
            "like": "mary"
          }
        }
      },
    }
  };
  
  const filter4 = {
    "where": {
      "templating": {
        "name": "jacob"
      }
    }
  };

  /*
  console.log(getLikePath(filter1.where));
  console.log(getLikePath(filter2.where));
  console.log(getLikePath(filter3.where));
  console.log(getLikePath(filter4.where));
  */
  assert(_.isEqual(getLikePath(filter1.where), {like: "john", key: "templating", keys: ["templating"]}));
  assert(_.isEqual(getLikePath(filter2.where), {like: "jacob", key: "templating.name", keys: ["templating","name"]}));
  assert(_.isEqual(getLikePath(filter3.where), {like: "mary", key: "templating.first.name", keys: ["templating","first","name"]}));
  assert(_.isEqual(getLikePath(filter4.where), undefined));
}

//test(); 
