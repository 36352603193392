import React, { Component } from 'react';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link} from 'react-router-dom';
import Radium from 'radium';
import {
  Card,
  FormCard,
  GreenButton,
  LabeledTextInput,
  ImageInput,
  Edit,
  FundraiserOverview,
  ChangePassword,
  ChangeEmail,
  AccountProfile,
  AccountDetails,
  LegalEntity,
  SubentitySelection,
 } from '../../components';
import {
  List,
  CreateButton,
  RefreshButton,
  Filter,
  TextInput
} from 'react-admin';
import {
  formEnhancer,
  entityMgrConnect,
  getFromNestedSafely as gS,
} from './../../services';

const style = {
  wrapper: {
    position: 'relative',
  },
  header: {
    font: '18px ProximaNova-SemiBold',
  },
  title: {
    font: '18px ProximaNova-SemiBold',
    marginBottom: 20,
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems:' flex-start',
    alignContent: 'flex-start',
    marginTop: 20,
  },
  item: {
    width: '100%',
    minWidth: 335,
    marginBottom: 20,
    overflow: 'auto',
  },
  row: {
    marginBottom: 20,
    width: '100%',
    flexShrink: 0,
  },
  attention: {
    boxShadow: `0px 0px 6px rgba(224,10,31,0.8)`
  },
  tabs: {
    width: '100%',
    minWidth: '335px',
    background: '#FFF',
    boxShadow: `2px 2px 6px rgba(0,0,0,0.2)`,
    borderRadius: 4,
    marginBottom: 20,
    marginTop: 0,
  },
  container: {
    height: 60,
  },
  selected: {
    background: 'rgba(35,191,184,0.1)',
  },
  indicator: {
    background: '#23BFB8',
  },
  label: {
    font: '16px ProximaNova-SemiBold',
    color: '#464D5E',
    letterSpacing: 1,
    textTransform: 'none',
    '@media (max-width: 640px)': {
      fontSize: 12,
      letterSpacing: 0,
    }
  }
}


class SettingsTab extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;
    const type = (gS(this.props.entityMgr,'realm'))
    const sponsor = gS(this.props.entityMgr, 'entity.cause.is_sponsor') === true ? true : false;
    const logo = (gS(this.props.entityMgr,'entity.templating.logo'))




    return (
      <div>
        <div className={classes.tabs}>
          <Tabs
            variant='fullWidth'
            scrollButtons='on'
            value={value}
            onChange={this.handleChange}
            classes={{
              indicator: classes.indicator,
              flexContainer: classes.container
            }}
          >
            <Tab label="Account" classes={{selected: classes.selected, label:classes.label}}/>

            {type === 'entity' ? (
              <Tab value='Organization' label="Organization" classes={{selected: classes.selected, label:classes.label}}/>
            ) : ''}
            {type === 'entity' ? (
              <Tab value='Profile' label={sponsor === true ? ('Sponsor Profile'):'Cause Profile'} classes={{selected: classes.selected, label:classes.label}}/>
            ) : ''}
          </Tabs>
        </div>
        {value === 0 &&
          <div style={style.content}>
          <Card root={style.item}>
            <div style={style.title}>Contact Information</div>
            <AccountDetails entityMgr={this.props.entityMgr}/>
          </Card>
          <Card root={style.item}>
            <div style={style.title}>Change Password</div>
            <ChangePassword entityMgr={this.props.entityMgr} />
          </Card>
          <Card root={style.item}>
            <div style={style.title}>Change Email</div>
            <ChangeEmail entityMgr={this.props.entityMgr} />
          </Card>
          </div>
        }
        {value === 'Organization' &&
          <div style={style.content}>
            <Card root={style.item}>
              <div style={style.title}>Organization Information</div>
              <LegalEntity type={type}/>
            </Card>
          </div>
        }
        {value === 'Profile' &&
        <div style={style.content}>
            <Card root={logo ? style.item : {...style.item, ...style.attention}}>
              <div style={style.title}>{sponsor === true ? ('Sponsor Profile'):'Cause Profile'}</div>
              <AccountProfile/>
            </Card>
            {sponsor === true ? (''):<SubentitySelection></SubentitySelection>}
        </div>
        }
      </div>
    );
  }
}

SettingsTab = Radium(SettingsTab);

export default entityMgrConnect(withStyles(style)(SettingsTab));
