import React, {Component} from 'react';
import withSizes from 'react-sizes'
import {connect} from 'react-redux';
import Radium from 'radium';
import withWidth from '@material-ui/core/withWidth';
import compose from 'recompose/compose';
import { entityMgrConnect, getFromNestedSafely as gS } from '../../services';
import {
  AdminRoutes,
  Menu,
  setSidebarVisibility,
  Authenticated
} from 'react-admin';
import ReduxToastr from 'react-redux-toastr'
import MenuAppBar from './AppBar';
import Sidebar from './Sidebar';

const style = {
  body: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    // minWidth: 900,
  },
  content: {
    position: 'absolute',
    left: 200,
    top: 60,
    right: 0,
    transition: 'left 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
    padding: 20,
  },
  contentMobile: {
    left: 0,
  },
  contentExpand: {
    left: 0,
  }
}

class Layout extends Component {

  componentWillMount() {
    const { width, setSidebarVisibility } = this.props;
    setSidebarVisibility((width === 'xs' || width === 'sm'));
  }

  render() {
    const {
      children,
      isLoading,
      open,
      isMobile,
      width,
      setSidebarVisibility,
      location
    } = this.props;

    return (
      <Authenticated location={location} >
        <div style={style.body}>
          <MenuAppBar isMobile={isMobile} realm={gS(this.props, 'entityMgr.realm')}/>
          <Sidebar entityMgr={this.props.entityMgr}/>
          <div style={open ? {...style.content, ...style.contentExpand} : style.content}>
            {children}
            <ReduxToastr />
          </div>
        </div>
      </Authenticated>
    );
  }
}

const mapSizesToProps = ({ width }) => ({
  isMobile: width < 640,
})

const mapStateToProps = state => ({
  isLoading: state.admin.loading > 0,
  open: state.admin.ui.sidebarOpen,
  entityMgr: state.entityManagerReducer.record
});

export default compose(
  connect(
    mapStateToProps,
    { setSidebarVisibility }
  ),
  withWidth({ resizeInterval: Infinity }) // used to initialize the visibility on first render
)(Radium(withSizes(mapSizesToProps)(Layout)));
