import React, {Component} from 'react';
import {
  ExternalLayout,
  Terms
} from './../components';

const TermsExternal = (props) => {
  return (
    <ExternalLayout>
      <Terms />
    </ExternalLayout>
  )
}

export default TermsExternal;
