import { delay } from 'redux-saga';
import { put, takeEvery, all,  select, call} from 'redux-saga/effects';
import { push } from 'react-router-redux';
import {
	fetchEntitymanagerBegin,
	fetchEntitymanagerSuccess,
	fetchEntitymanagerFailure,
  fetchEntitymanagerById,
  getReporting,
  FETCH_ENTITYMANAGER_FAILURE,
  FETCH_ENTITYMANAGER_SUCCESS
} from './../actions/entityManager';
import {
	fetchEntityTemplatingBegin,
	fetchEntityTemplatingSuccess,
	fetchEntityTemplatingFailure,
  fetchEntityTemplatingById,
} from './../actions/entityTemplating';
import storage from './../aor-loopback/storage';
import {authClient} from './../aor-loopback';
import { debounce } from 'lodash';

export const getState = state => state.entityManagerReducer;
export const getTemplatingState = state => state.entityTemplatingReducer;

function* onLogout(action) {
  return new Promise((resolve) => {
    authClient()("AUTH_LOGOUT").then(resolve).then(window.location.replace('/login'));
  });
}

function* setEntitymanagerState(action) {
  const { type } = action;
  const { error, loading, record } = yield select(getState);
  if (loading || record) {
    console.log("setEntitymanagerState: early return");
    return record;
  }
  try {
    yield put(fetchEntitymanagerBegin());
    const lbtoken = storage.load('lbtoken') || {};
    if (!lbtoken.userId) throw new Error("cannot get user.");
    if (type === "RA/USER_LOGIN_SUCCESS") {
      //wait for streaming replication lag
      yield call(delay, 500);
    }
    const entityMgr = yield fetchEntitymanagerById({id: lbtoken.userId, action});
    if (!entityMgr || !entityMgr.response.json) throw new Error(entityMgr);
	  const reporting = yield getReporting({id: lbtoken.userId});
    yield put(fetchEntitymanagerSuccess({
      ...entityMgr.response.json,
      reporting: reporting.response.json,
    }));
  }
  catch(err) {
    yield put(fetchEntitymanagerFailure(err));
  }
}

function* setEntityTemplatingState(action) {
  const { type } = action;
  const { error, loading, record } = yield select(getState);
  const entityMgr = record;
  const { tLoading, tRecord } = yield select(getTemplatingState);
  if (!entityMgr || tLoading || tRecord) {
    console.log("setEntityTemplatingState: early return");
    return record;
  }
  try {
    yield put(fetchEntityTemplatingBegin());
    const entityTemplating = yield fetchEntityTemplatingById({id: entityMgr.entity_id});
    if (!entityTemplating || !entityTemplating.response.json) throw new Error("Could not get entityTemplatng");
    yield put(fetchEntityTemplatingSuccess(entityTemplating.response.json));
  }
  catch(err) {
    yield put(fetchEntityTemplatingFailure(err));
  }
}

function* setEntityTemplatingFeaturedSubentity(action) {
  const { type, payload } = action;
  const { loading, record } = yield select(getTemplatingState);
  if (loading || !record?.templating || !payload?.data?.id) {
    return record;
  }
  record.templating.featured_subentity = payload?.data?.id;
  yield put(fetchEntityTemplatingSuccess(record));
}


function* onUserLoginFailure() {
	yield put(fetchEntitymanagerFailure());
}

export default function* entityManagerSaga() {

  yield all([
			takeEvery('RA/USER_LOGIN_SUCCESS', setEntitymanagerState),
			takeEvery(FETCH_ENTITYMANAGER_SUCCESS, setEntityTemplatingState),
			takeEvery('GGB/FEATURE_SUBENTITY_SUCCESS', setEntityTemplatingFeaturedSubentity),
			// takeEvery('RA/USER_LOGIN_FAILURE', onUserLoginFailure),
      //entitymanager updated
			takeEvery('RA/USER_UPDATE', setEntitymanagerState),
      //application loads
			takeEvery('RA/SET_SIDEBAR_VISIBILITY', setEntitymanagerState),
      takeEvery('RA/USER_CHECK', setEntitymanagerState),
      takeEvery('GGB/LOGOUT', onLogout),
      takeEvery(FETCH_ENTITYMANAGER_FAILURE, onLogout),
	]);
}
