import React, {Component} from 'react';
import { style, validators } from './shared';
import loopbackRestClient from './../aor-loopback';
import storage from './../aor-loopback/storage';
import config from './../config/config.provider';
import { ExternalLayout, Card } from './../components';
import {Link} from 'react-router-dom';
import { confirmByToken } from './../actions/changeEmail';

const apiClient = loopbackRestClient(config);

export class ChangeEmailConfirm extends Component {
  state = {
    changeRequest: undefined,
    loading: true,
  }

  async componentDidMount() {
    const { entity_manager_id, token } = this.props.match.params;
    confirmByToken({entity_manager_id, token}).then((changeRequest) => {
      this.setState({changeRequest, loading: false});
    }).catch((err) => {
      console.log(err);
      this.setState({changeRequest: undefined, loading: false});
    });
  }

  render() {
    if (!this.state.loading && !this.state.changeRequest) {
      return (
        <ExternalLayout>
          <div style={style.content}>
            <Card content={style.cardModifier}>
              <div style={style.title}>The link you clicked is invalid or the session has expired.</div>
            </Card>
          </div>
        </ExternalLayout>
      )
    } else if (!this.state.loading && this.state.changeRequest) {
      return (
        <ExternalLayout>
          <div style={style.content}>
            <Card content={style.cardModifier}>
              <div style={style.title}>Your email address has been changed successfully.</div>
            </Card>
          </div>
        </ExternalLayout>
      )
    } else {
      return (
        <ExternalLayout>
          <div style={style.content}>
            <Card content={style.cardModifier}>
              <div style={style.caption}>Loading...</div>
            </Card>
          </div>
        </ExternalLayout>
      )
    }
  }
};

export default ChangeEmailConfirm;
