import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Tooltip } from './../';
import { getFromNestedSafely as gS } from './../../services';

const style = {
  wrapper: {
    position: 'relative',
    width: '100%',
  },
  input: {
    color: '#464D5E',
    fontFamily: 'ProximaNova-Light',
    lineHeight: 1.5,
    fontSize: 14,
  },
  underline: {
    '&:before': { borderBottomColor: '#A4ACAF' },
    '&:hover:before': { borderBottom: '2px solid #7C8487 !important' },
    '&:after': { borderBottomColor: '#23BFB8' },
  },
  label: {
    fontFamily: "'ProximaNova-Light'",
    color: '#777B84',
    fontSize: 16,
    '&:hover': { borderBottomColor: '#A4ACAF' },
    '&$labelFocused': { color: '#23BFB8' },
  },
  labelFocused: {},
  tip: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  helperText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  charCount: {
    marginLeft: 20,
  }
}

const fancyCount = str => {
  str = str ? str.toString(16) : "";
  const joiner = "\u{200D}";
  const split = str.split(joiner);
  let count = 0;

  for (const s of split) {
    //removing the variation selectors
    const num = Array.from(s.split(/[\ufe00-\ufe0f]/).join("")).length;
    count += num;
  }

  //assuming the joiners are used appropriately
  return count / split.length;
};

const LabeledTextInput = ({ 
  meta: { touched, error } = {}, 
  input: {...inputProps} = {}, 
  classes, 
  ...props
}) => {

  const helperText = (
    <div style={style.helperText}>
      <div>{(touched || props.errorWhenPristine) && error}</div>
      {props.maxLength && (<div style={style.charCount}>{fancyCount(gS(inputProps, 'value'))} / {props.maxLength}</div>)}
    </div>
  )

  return (
    <div style={style.wrapper}>
      <TextField
        {...inputProps}
        {...props}
        error={(touched || props.errorWhenPristine) && error}
        helperText={helperText}
        FormHelperTextProps={{
          component: 'div'
        }}
        fullWidth
        inputProps={{
          maxLength: props.maxLength
        }}
        InputProps={{
          style: props.customStyle,
          classes: {
            root: classes.input,
            underline: classes.underline,
          }
        }}
        InputLabelProps={{
          shrink: props.shrink,
          classes: {
            root: classes.label,
            focused: classes.labelFocused,
          }
        }}
      />
    {props.tip &&  (<Tooltip message={props.tip} title={props.title} tipPlacement={props.tipPlacement} target={props.className} />)}
    </div>

  )
}

export default withStyles(style)(LabeledTextInput)
