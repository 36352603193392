import React, {Component} from 'react';
import { style } from './shared';
import {propTypes, reduxForm, Field, reset, SubmissionError} from 'redux-form';
import {connect} from 'react-redux';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import Radium from 'radium';
import _ from 'lodash';
import {
  translate,
  userLogin as userLoginAction,
} from 'react-admin';
import loopbackRestClient from './../aor-loopback';
import storage from './../aor-loopback/storage';
import config from './../config/config.provider';
import { 
  fetchByToken,
  changeEmailConfirmBegin,
  changeEmailConfirmSuccess
} from './../actions/changeEmail';
import {
  required,
  email
} from './../validators';
import {
  ExternalLayout,
  Card,
  LabeledTextInput,
  GreenButton
} from './../components';

import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  showNotification,
  hideNotification,
} from './../actions/customNotification';

const apiClient = loopbackRestClient(config);
class ChangeEmailRevertFormUnconnected extends Component {

  async componentDidMount() {
    this.props.changeEmailConfirmBegin(); 
  }

  render() {
    const {handleSubmit, invalid, error, pristine} = this.props;
    const {loading, record } = this.props.changeEmail;
    if (!loading && record) {
      return (
        <div>
          <div style={style.title}>Success!</div>
          <div style={style.caption}>You have successfully recovered your account. You may now login using your recovered email address and password.</div>
        </div>
      );
    }
    else {
      return (
        <div>
          <div style={style.title}>Recover You Account</div>
          <div style={style.caption}>Enter your previous email and password to recover your account.</div>
          <form onSubmit={handleSubmit} style={style.form}>
            {error && <p style={style.error}>{error}</p>}
            <Field
              component={LabeledTextInput}
              name="email"
              label="Previous Email"
              hintText="youremail@domain.com"
              validate={[required, email]}
              style={style.input}
            />
            <Field
              component={LabeledTextInput}
              name="password"
              label="Password"
              hintText="*********"
              type="password"
              validate={required}
              style={style.input}
            />
            <GreenButton
              type="submit"
              style={style.submit}
              disabled={invalid && !pristine}
              label="Recover Email"
              small
            />
          </form>
        </div>
      );
    }
  }
}

let ChangeEmailRevertForm = connect((state) => ({
  changeEmail: state.changeEmailReducer
}),{
  changeEmailConfirmBegin,
  changeEmailConfirmSuccess
})(reduxForm({
  form: 'recover-email',
  onSubmit: (values, dispatch, props) => {
    return new Promise((resolve, reject) => {
      const { entity_manager_id, confirm_token } = _.get(props, 'changeRequest', {});
      apiClient("WITH_ROUTE", "EntityManagers", {
        route:`${entity_manager_id}/changeEmailRevert`,
        data: {
          old_email: values.email,
          password: values.password,
          confirm_token 
        },
        method: "POST"
      }).then(({response}) => {
        if (response.status === 200) {
          dispatch(reset('recover-email'));
          dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
              type: "success",
              title: "Email Recovery Success",
              message: "You may now login using your recovered email address and password."
            }
          });
          props.changeEmailConfirmSuccess(response);
          resolve();
        }
        else {
          reject(new SubmissionError({_error: 'Email recovery failed!'}));
        }
      }).catch((err) => {
        reject(new SubmissionError({_error: 'Email recovery failed!'}));
      });
    });
  }
})(ChangeEmailRevertFormUnconnected));


class ChangeEmailRevert extends Component {
  state = {
    changeRequest: undefined,
    loading: true
  }

  async componentDidMount() {
    const { entity_manager_id, token } = this.props.match.params;
    fetchByToken({entity_manager_id, token}).then((changeRequest) => {
      this.setState({changeRequest, loading: false});
    }).catch((err) => {
      this.setState({changeRequest: undefined, loading: false});
    });
  }

  render() {
    if (!this.state.loading && !this.state.changeRequest) {
      return (
        <ExternalLayout>
          <div style={style.content}>
            <Card content={style.cardModifier}>
              <div style={style.title}>The link you clicked is invalid or the session has expired.</div>
            </Card>
          </div>
        </ExternalLayout>
      )
    } else if (!this.state.loading && this.state.changeRequest) {
      return (
        <ExternalLayout>
          <div style={style.content}>
            <Card content={style.cardModifier}>
							<ChangeEmailRevertForm changeRequest={this.state.changeRequest}/>
            </Card>
          </div>
        </ExternalLayout>
      )
    } else {
      return (
        <ExternalLayout>
          <div style={style.content}>
            <Card content={style.cardModifier}>
              <div style={style.caption}>Loading...</div>
            </Card>
          </div>
        </ExternalLayout>
      )
    }
  }
};

export default ChangeEmailRevert;
