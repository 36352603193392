import React from 'react';
import Radium from 'radium';
import {
  OrangeButton
} from '../';

let styles = {
  wrapper: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
  },
  container: {
    position: 'relative',
    maxWidth: 1000,
    width: '90%',
    margin: '0 auto',
    alignItems: 'center',
    '@media (max-width: 580px)': {
      textAlign: 'center',
      width: '100%',
      margin: '15px',
    }
  },
  content: {
    position: 'relative',
    width: '100%',
    '@media (max-width: 580px)': {
      textAlign: 'center',
      width: '100%',
    }
  },
  img: {
    width: 'auto',
    maxWidth: '30%',
    height: 'auto',
    flexShrink: 0,
    flex: 1,
    margin: '0 auto',
    display: 'block',
    paddingBottom: 20
  },
  header: {
    fontSize: 30,
    fontFamily: 'ProximaNova-SemiBold',
    lineHeight: 1,
    marginBottom: 20,
    color: '#1E5E75',
    '@media (max-width: 780px)': {
      fontSize: 26,
    },
    '@media (max-width: 580px)': {
      fontSize: 20,
      // textAlign: 'center',
    }
  },
  caption: {
    fontSize: 18,
    lineHeight: 1.61803398875,
    marginTop: 20,
    fontFamily: 'ProximaNova-Regular',
    '@media (max-width: 780px)': {
      fontSize: 17,
      marginTop: 20,
    },
    '@media (max-width: 580px)': {
      // textAlign: 'center',
    }
  },
  btns: {
    marginTop: 30,
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
}

const Step = (props) => {
  let shiftedFlip = {
    width: '100%',
    margin: '0',
  }
  let shiftedFlop = {
    width: '100%',
    // margin: '80px 0',
  }
  styles.wrapper.backgroundColor = props.background ? 'rgba(229,246,245,0.5)' : ''
  styles.container.flexDirection = props.flipped ? 'column-reverse' : '';
  styles.wrapper['@media (max-width: 580px)'] = props.flipped ? shiftedFlip : shiftedFlop;

  return (
    <div style={[styles.wrapper, props.flipped ? styles.wrapperMinFlipped : styles.wrapperMin]}>
      <div style={styles.container}>
        <div style={[styles.content, styles.hidden]}>
        <div style={styles.header}>{props.header}</div>
          <img style={styles.img} src={props.image} alt=""/>
        </div>
        <div style={styles.content}>
          <div style={styles.caption}>{props.caption}</div>
          {props.to ? (
            <div style={styles.btns}>
            <OrangeButton
              title={props.buttonTitle}
              to={props.to}
            />
          </div>
          ) : ''}
        </div>
      </div>
    </div>
  )
}

export default Radium(Step);
