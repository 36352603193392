import React from 'react';
import { Link } from 'react-router-dom'
import Radium from 'radium';
import { grid } from './../../style';
import style from './style.js';
import _ from 'lodash';
import {
  Widget,
  WidgetDashboardHeader,
  FundsRaised,
  GreenButton,
  GreenLink,
  IconButtons,
  DISFormWidget,
  SocialContent,
  Card,
  TourWizard,
  DonorReporting
} from '../';
import { cobrandLink, } from './../../services';


const EntityCauseDashboard = (props) => {

  const entityMgr = props.entityMgr || {};
  const disableCauseView = !_.get(entityMgr, 'entity.templating.cobrand') ? true : false;
  const steps = [{
    target: '.appbar',
    title: 'Welcome to your Management Portal!',
    content: 'Through this portal you will be able to manage your Registered Cause. Follow the tour to finish creating your Cause!',
    disableBeacon: true,
    isFixed: true,
    hideCloseButton: true,
    placement: 'center',
    locale: {next: 'Get Started!',}
  },
  {
    target: '.cause_site',
    title: 'Cause Page Editor',
    content: 'Click the button above to edit your Cause Page.',
    disableBeacon: true,
    isFixed: true,
    hideFooter: true,
    hideCloseButton: true,
  },
  {
    target: '.in_app_portal',
    title: 'Edit In-App Cause Portal',
    content: 'Share important information with your donors through the In-App Cause Portal.',
    disableBeacon: true,
    isFixed: true,
    hideFooter: true,
    hideCloseButton: true,
  }]
  const stepIndex = _.get(entityMgr, 'entity.templating.cobrand') ? 2 : null;
  const joyrideTrigger = !_.get(entityMgr, 'entity.templating.portal', '') ? true : false;
  const continuous = !_.get(entityMgr, 'entity.templating.cobrand', '') ? true : false;
  const donorReporting = _.get(entityMgr, 'reporting', []);
  const mName = _.get(props, 'entityMgr.first_name');
  const entityName = _.get(props, 'entityMgr.entity.gb_cause.name');

  return (
    <div>
      <TourWizard
        steps={steps}
        continuous={continuous}
        run={joyrideTrigger}
        stepIndex={stepIndex}
      />
      <div style={grid.grid}>
        <div style={grid.row}>
        <WidgetDashboardHeader
          welcomeMessage={mName}
          caption={entityName}
        >
<div style={style.buttons}>
          <GreenLink
            label="Edit Cause Page"
            to="/entities"
            component={Link}
            small="true"
            icon="edit"
            className="cause_site"
            style={style.textButton}
          />
          <GreenLink
            label="View Cause Page"
            small="true"
            target="_blank"
            href={cobrandLink({entity: _.get(entityMgr, 'entity.cause'), slug: _.get(entityMgr, 'slug')})}
            icon="open_in_new"
            disabled={disableCauseView}
            style={style.textButton}
          />
          <GreenLink
            label="Edit In-App Cause Portal"
            to="/portal/edit"
            component={Link}
            small="true"
            icon="phone_iphone"
            className="in_app_portal"
            style={style.textButton}
          />
</div>
        </WidgetDashboardHeader>
        </div>
        <div style={grid.row}>
          <div style={grid.col50}>
            <Widget
              icon="message"
              title="Donor Invitation System"
              caption="Invite a friend or family member to donate."
              tip="The DIS will create a personalized email to send your recipient requesting a donation, explaining what the donation is for, and information about greenback!"
              className="dis"
            >
              <DISFormWidget />
            </Widget>
          </div>
          <div style={grid.col50}>
            <Widget
              icon="share"
              title="Share Registered Cause Site"
              caption="Share your site with others by sending the link directly or using social media."
              tip="Easily share your Cause Site via social media by copying your unique link, or by using one of the social shortcut icons."
              className="social"
            >
              <SocialContent
                entityMgr={entityMgr}
                sms="true"
                facebook="true"
                email="true"
              />
            </Widget>
          </div>
          {donorReporting.map((donorReport) => (<div style={grid.colWidget}>
            <Widget
              icon="list"
              title={donorReport.title}
              caption={donorReport.description}
              className="reporting"
            >
            <iframe
              src={donorReport.url}
              frameborder="0"
              width={_.get(donorReport,'width','100%')}
              height={_.get(donorReport,'height','600 px')}
              allowtransparency>
              </iframe>
            </Widget>
          </div> ))}
        </div>
      </div>
    </div>
  );
}

export default Radium(EntityCauseDashboard);
