import React, { Component } from "react";
import loopbackRestClient from './../../aor-loopback';
import config from './../../config/config.provider';
import { Field } from 'redux-form';
import { submit, reset } from 'redux-form';
import { userLogin as userLoginAction } from 'react-admin';
import {Link} from 'react-router-dom';
import {
    formEnhancer
} from './../../services';
import {
  required,
  isValidMoment,
} from './../../validators';
import {
  Card,
  GreenButton,
  LabeledTextInput,
  AgeCheckDateInput
} from './../../components';
import { SubmissionError } from 'redux-form';
import { style } from './../../routes/shared';

const apiClient = loopbackRestClient(config);

class AccountFormFromToken extends Component {

  submit(values, dispatch, props) {
    dispatch(submit('create-account'))
  }

  render() {
    const {
      error,
      pristine,
      reset,
      submitting,
      dispatch,
      submitSucceeded
    } = this.props;
    return (
      <div style={style.content}>
        <Card content={style.cardModifier}>
          <div style={style.title}>Create Account</div>
          <div style={style.caption}>Enter your information below to create a new account.</div>
          <form style={style.form}>
            {error && <p style={style.error}>{error}</p>}
            {submitSucceeded && pristine && <p style={style.success}>{config.response_messages.create_account_success}</p>}
            <Field
              name="newPassword"
              component={LabeledTextInput}
              label="Password"
              type="password"
              validate={[required]}
              style={style.input}
            />
            <Field
            name="newPassword_confirm"
            component={LabeledTextInput}
            label="Confirm Password"
            type="password"
            validate={[required]}
              style={style.input}
            />
            <Field
              name="confirmAge"
              component={AgeCheckDateInput}
              label="Your Brithday"
              placeholder="mm/dd/yyyy"
              validate={[isValidMoment]}
            />
            <GreenButton
              type="submit"
              style={style.submit}
              disabled={submitting}
              label="Submit"
              small="true"
              onClick={this.props.handleSubmit(this.submit)}
            />
            <div style={style.links}>
              <Link style={style.href} to="/login">Login</Link>
            </div>
          </form>
        </Card>
      </div>
    );
  }
}

const CreateAccountFormFromToken = formEnhancer({
  FormComponent: AccountFormFromToken,
  formName: 'create-account',
  validate: (values, props) => {
    const errors = {};
    if (values.newPassword !== values.newPassword_confirm)
      errors.newPassword_confirm = "Passwords do not match.";
    return errors;
  },
  onSubmit: (values, dispatch, props) => {
    return new Promise(async (resolve, reject) => {
      try {
        const {response} = await apiClient("WITH_ROUTE", "EntityManagers", {
          route:"reset-password",
          data: values,
          method: "POST"
        });
        if (response.status === 204) {
          dispatch(reset('create-account'));
          const { entityMgr } = props;
          if (entityMgr) {
            //wait for streaming replication
            setTimeout(() => {
              dispatch(userLoginAction({
                email: entityMgr.email,
                password: values.newPassword
              }, '/'));
            }, 500);
          }
          resolve();
        }
        else {
          reject(new SubmissionError({ _error: config.response_messages.reset_password_error}));
        }
      }
      catch(err) {
        if (err.code == "AGE_COPPA_NONCOMPLIANCE") {
          console.log('prevent user from submitting form again');
        }
        // reject(new SubmissionError({ _error: err.message}));
        window.location.replace('/unable-to-create');
      }
    });
  }
});

export default CreateAccountFormFromToken
