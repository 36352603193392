export let style = {
  cobrand: {
    position: 'relative',
    background: '#FFF',
    // boxShadow: `2px 2px 6px rgba(0,0,0,0.2)`,
    // borderRadius: 4,
    // overflow: 'hidden',
    // width: 1200
  },
  landing: {
    position: 'relative',
    paddingTop: 40,
    overflow: 'hidden',
  },
  landingBefore: {
    position: 'absolute',
    top: 0,
    height: '40%',
    maxHeight: 400,
    width: 'calc(100% + (10% *2))',
    left: '-10%',
    backgroundImage: 'linear-gradient(90deg, #1E5E75 0%, #0E9BA6 63%)',
    borderBottomLeftRadius: '100% 15vw',
    borderBottomRightRadius: '100% 15vw',
    zIndex: 0,
  },
  wrapper: {
    position: 'relative',
    maxWidth: 960,
    width: '90%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 1,
  },
  colLeft: {
    width: 600,
    marginLeft: '5%',
  },
  header: {
    font: '24px ProximaNova-SemiBold',
    color: '#FFF',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '15%',
    maxWidth: 600,
    width: '100%',
  },
  logos: {
    // width: '80%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    // flexWrap: 'wrap',
    marginTop: 20,
    marginBottom: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  entityLogo: {
    maxHeight: 110,
    height: 'auto',
    width: 'auto',
    maxWidth: 200,
  },
  gbLogo: {
    height: 50,
    width: 'auto',
  },
  providers: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 30,
    width: '100%',
  },
  providerLogos: {
    width: '45%',
    height: 'auto',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  content: {
    paddingTop: 20,
  },
  title: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    fontSize: 24,
    color: '#1E5E75',
    textAlign: 'center',
  },
  bold: {
    fontFamily: 'ProximaNova-SemiBold',
  },
  caption: {
    textAlign: 'center',
    marginTop: 20,
    fontSize: 16,
    color: '#1E5E75',
  },
  causeLogo: {
    display: 'block',
    maxWidth: 150,
    maxHeight: 100,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 30,
  },
  fakeButton: {
    width: 250,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#23BFB8',
    color: '#FFF',
    borderRadius: 3,
    fontSize: 16,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 30,
  },
  appLogos: {
    width: 260,
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  store: {
    width: 120,
    height: 'auto',
    display: 'block',
  },
  colRight: {
    width: 238,
    height: 660,
    display: 'block',
    '-webkit-transform-origin': '50% 0',
    transform: '50% 0',
  },
  phone: {
    backgroundImage: 'url(//cloudfront.startupmedia.com/gb/imgs/global/devices/iphone.png)',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    height: '100%',
  },
  quoteWrapper: {
    marginTop: 60,
    paddingTop: '2.5%',
    paddingBottom: '2.5%',
    backgroundColor: '#F3F5F6',
  },
  quote: {
    maxWidth: 900,
    width: '90%',
    fontSize: 22,
    color: '#1E5E75',
    marginLeft: 'auto',
    marginRight: 'auto',
    textAlign: 'center',
  }

}
