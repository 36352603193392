import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { getFromNestedSafely as gS } from './../../services';
import _ from 'lodash';


const style = {
  input: {
    color: '#464D5E',
    fontFamily: "'ProximaNova-Light'",
    lineHeight: 1.5,
    fontSize: 14,
    padding: 8,
  },
  focused: {
    background: 'rgba(255,98,0,0.08)'
  },
  outline: {
    border: '1px dashed #FF6200 !important',
  },
  label: {
    fontFamily: "'ProximaNova-Light'",
    color: '#777B84',
    fontSize: 16,
  },
  helperText: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 10,
    marginBottom: 3,
  },
  charCount: {
    marginLeft: 20,
  },
  input: {
    textAlign: 'left'
  }
}

const fancyCount = str => {
  str = str ? str.toString(16) : "";
  const joiner = "\u{200D}";
  const split = str.split(joiner);
  let count = 0;

  for (const s of split) {
    //removing the variation selectors
    const num = Array.from(s.split(/[\ufe00-\ufe0f]/).join("")).length;
    count += num;
  }

  //assuming the joiners are used appropriately
  return count / split.length;
};

const ResizableTextarea = ({ meta: { touched, error }, input: {...inputProps}, classes, centered, ...props}) => {

  // if( _.isObject(error) ) { error = '' }

  const helperText = (
    <div style={style.helperText}>
      <div>{touched && error}</div>
      {props.maxLength && (<div style={style.charCount}>{fancyCount(gS(inputProps, 'value'))} / {props.maxLength}</div>)}
    </div>
  )

  return (

    <TextField
      {...inputProps}
      {...props}
      error={touched && error}
      helperText={helperText}
      FormHelperTextProps={{
        component: 'div'
      }}
      fullWidth={true}
      multiline={true}
      variant="outlined"
      rowsMax={props.rowsMax || 25}
      inputProps={{
          maxLength: props.maxLength,
      }}
      InputProps={{
        style: props.customStyle,
        maxLength: props.maxLength,
        classes: {
          input: classes.input,
          root: classes.input,
          notchedOutline: classes.outline,
          focused: classes.focused,
        }
      }}
      InputLabelProps={{
        shrink: true,
        classes: {
          root: classes.label,
          focused: classes.labelFocused,
        }
      }}
    />

  )
}

export default withStyles(style)(ResizableTextarea)
