import React, { Component } from 'react';
import { connect } from 'react-redux';
import Radium from 'radium';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { formEnhancer, getSubentities } from './../../services';
import { FormControl, FormControlLabel, FormGroup, RadioGroup } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Card,
  Title,
  Caption,
  FormCard,
  LabeledTextInput,
  GreenButton,
  OrangeButton,
  RadioInput
} from './../';
import _ from 'lodash';
import {
  email,
} from './../../validators';
import {
  getLogo
} from './../../services';

import baseRequest from './../../services/api/baseRequest';
import {
  setSelectedSubentity,
} from './../../actions/subentitySelection';
import {
  featureSubentity,
  unfeatureSubentity,
  featureSubentityBegin,
  featureSubentitySuccess,
  featureSubentityFailure
} from './../../actions/featureSubentity';

import {
  SHOW_NOTIFICATION,
  HIDE_NOTIFICATION,
  showNotification,
  hideNotification,
  getDispatchers,
} from './../../actions/customNotification';

import Modal from 'react-modal';
import {
  style as modalStyle,
} from './../ReactModal/StandardModal';

const notificationDispatcher = getDispatchers({title: "Featured Campaign"});

//used for testing purposes to remove type filters
const shouldFilterTypes = true;

export const modalCustomStyles = {
  content : {
    maxHeight: '80vh',
    width: '80vw',
    maxWidth: '600px',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-30%',
    transform: 'translate(-50%, -50%)',
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
};

let style = {
  button: {
    marginTop: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  table: {
    position: 'relative',
    width: '100%',
    height: 400,
    overflow: 'auto',
    borderColor: '#34B0A7',
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 4,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    '@media (max-width: 600px)': {
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 10,
      paddingRight: 10,
    }
  },
  rowEven: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: 10,
    paddingBottom: 10,
    paddingLeft: 20,
    paddingRight: 20,
    backgroundColor: '#F3F5F6',
    '@media (max-width: 600px)': {
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 10,
      paddingRight: 10,
    }
  },
  logo: {
    height: 45,
    width: 100,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '@media (max-width: 600px)': {
      height: 30,
      width: 80,
    }
  },
  logoImg: {
    maxHeight: 45,
    height: 'auto',
    maxWidth: 100,
    width: 'auto',
    '@media (max-width: 600px)': {
      maxHeight: 30,
      maxWidth: 80,
    }
  },
  name: {
    fontFamily: 'ProximaNova-Regular',
    flex: 1,
    marginLeft: 20,
    '@media (max-width: 600px)': {
      fontSize: 12,
      marginLeft: 10,
    }
  },
  selectAll: {
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    marginLeft: 20,
    marginRight: 20,
  },
  loader: {
    width: '100%',
    height: 400,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    fontFamily: 'ProximaNova-Regular',
    color: 'red',
    fontSize: 14,
    marginBottom: 20,
  },
}

let renderSubentities = ({ subentities, gbCauseId, changeHandler, featuredSubentity, meta: {error}, props = {}}) => {
  return (
    <div>
      {error && <div style={style.error}>{error}</div>}
      <div style={style.table} id="table">
        <RadioGroup aria-label="Featured Subentity" name="featured_subentity" onChange={changeHandler}>

        {subentities.length < 1 ?
          <div style={style.loader}><CircularProgress size={100} thickness={5}/></div>
        :
          subentities.map((s,i) => (
          <div style={{...style.row, ...(i % 2 ? style.rowEven : '')}} key={i} className="row">
            <div style={style.logo}>
              <img style={style.logoImg} src={getLogo({gb_cause_id: gbCauseId, subentity_id: s.id})}/>
            </div>
            <div style={style.name}>{_.get(s, "templating.name")}</div>
            <FormControlLabel value={s.id} control={<RadioInput/>} checked={s.id == _.get(featuredSubentity, "subentity_id")}/>
          </div>
        ))}
        </RadioGroup>
      </div>
    </div>
  );
}

renderSubentities = connect((state) => ({
  featuredSubentity: _.get(state,"subentitySelectionReducer.record")
}))(Radium(renderSubentities));

function feature({props}) {
  return new Promise((resolve, reject) => {
    const {
      entityTemplating,
      featuredSubentity,
      featureSubentityBegin,
      featureSubentitySuccess,
      featureSubentityFailure,
      setSelectedSubentity,
      dispatch,
    } = props;
    const { subentity_id } = featuredSubentity;
    if (subentity_id) {
      featureSubentityBegin();
      featureSubentity({subentity_id}).then((res) => {
        console.log(res);
        featureSubentitySuccess(res?.model);
        notificationDispatcher.success({dispatch, message: "You've successfully featured the Campaign."});
        resolve();
      }).catch((err) => {
        console.error(err);
        if (_.get(err, "code") === "ENTITYID_ISFEATURED_UNIQUE_CONSTRAINT") {
          const old_subentity_id = _.get(entityTemplating, "templating.featured_subentity");
          //if the old_subentity_id is ever present we show the modal.
          old_subentity_id && setSelectedSubentity({old_subentity_id, subentity_id});
        }
        else {
          notificationDispatcher.error({dispatch, message: "We've encountered an error attempting to feature this campaign."});
        }
        featureSubentityFailure();
        resolve();
      });
    }
    else {
      notificationDispatcher.error({dispatch, message: "You must select a Campaign to be featured before you continue."});
      resolve();
    }
  });
}

function unfeatureAndFeature({props}) {
  return new Promise((resolve) => {
    const {
      entityTemplating,
      featuredSubentity,
      featureSubentityBegin,
      featureSubentitySuccess,
      featureSubentityFailure,
      setSelectedSubentity,
      dispatch,
    } = this.props || props;
    const { subentity_id, old_subentity_id } = featuredSubentity;
    const genericError = (error) => {
      console.log({error});
      notificationDispatcher.error({dispatch, message: "We've encountered an error attempting to feature this campaign."});
      featureSubentityFailure();
      resolve();
    }
    if (old_subentity_id && subentity_id) {
      featureSubentityBegin();
      unfeatureSubentity({subentity_id: old_subentity_id}).then((res) => {
        feature({props: this.props || props}).then((res) => {
          resolve(res);
          featureSubentitySuccess(res);
        }).catch(genericError);
        this.closeModal && this.closeModal();
      }).catch(genericError);
    }
    else {
      genericError(new Error(`Both new and old subentity required, ${subentity_id}, ${old_subentity_id}`));
    }
  });
}


class SubentitySelectionForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subentities: [],
      modalIsOpen: false,
    }
    this.onCloseModal = this.closeModal.bind(this);
    this.onUnfeatureAndFeature = unfeatureAndFeature.bind(this);
  }

  openModal() {
    console.log("open modal");
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    console.log("close modal");
    this.setState({modalIsOpen: false});
    this.props.setSelectedSubentity({
      subentity_id: _.get(this.props, "featuredSubentity.subentity_id"),
      old_subentity_id: null
    });
  }

  componentDidUpdate(prevProps) {
    const old_subentity_id = this.props.featuredSubentity?.old_subentity_id;
    const prev_old_subentity_id = prevProps.featuredSubentity?.old_subentity_id;

    //the old_subentity_id prop was given so we open the modal
    if (old_subentity_id && old_subentity_id !== prev_old_subentity_id) {
      this.openModal();
    }
    //the old_subentity_id prop was removed so we close the modal
    else if(!old_subentity_id && prev_old_subentity_id) {
      this.closeModal();
    }
  }

  componentDidMount() {

    const where = {
      is_active: true,
      templating: {neq: null}
    };

    if (shouldFilterTypes) where.type = "subentity";

    Promise.all([
      baseRequest({
        endpoint: "ersubentities",
        data: {
          filter: JSON.stringify({where})
        }
      })
    ]).then(res => {
      const whichContainPortalTemplating = shouldFilterTypes ? res[0].filter((s) => !!_.get(s, "templating.portal")) : res[0];
      this.setState({
        subentities: _.map(whichContainPortalTemplating, subentity => _.pick(subentity, ['templating.name','id'])),
      });
      this.props.setSelectedSubentity({
        old_subentity_id: null,
        subentity_id: this.props.entityTemplating?.templating?.featured_subentity
      });
    }).catch(err => {
      this.setState({
        subentities: []
      });
    });
  }

  handleChange = (e, subentityId) => {
    if(subentityId) {
      this.props.setSelectedSubentity({subentity_id: parseInt(subentityId)});
    }
  }

  search = (e) => {
    let input = e.target
    let filterBy = input.value.toUpperCase(); // non case sensitive
    let table = document.getElementById('table');
    let row = document.getElementsByClassName('row');

    for (let i = 0; i < row.length; i++) {
      let item = row[i];
      if (item) {
        let txtValue = item.textContent || item.innerText;
        if (txtValue.toUpperCase().indexOf(filterBy) > -1) {
          row[i].style.display = 'flex';
        } else {
          row[i].style.display = "none";
        }
      }
    }
  }

  render() {
    const { error, invalid, touched, handleSubmit, pristine, reset, submitting, featuredSubentity } = this.props
    return (
      <Card>
        <Title>Featured Campaign</Title>
        <Caption>Select an active Campaign from the list below to be Featured. The In-app Campaign Portal of the Featured Campaign will be displayed to your donors that receive a membership through the Membership Delivery System.</Caption>
        <form  onSubmit={handleSubmit}>
          <div style={style.header}>
            <LabeledTextInput
              label="Search for a Campaign"
              placeholder="A Great Campaign"
              name="featured_subentity"
              type="search"
              onInput={this.search}
              id="searchInput"
            />
          </div>
          <FieldArray name={`subentities`} subentities={this.state.subentities} gbCauseId={_.get(this.props, "entityMgr.entity.gb_cause_id")} changeHandler={this.handleChange} component={renderSubentities} />
          <GreenButton type="submit" label="Feature Campaign" small="true" style={style.button}/>

          {featuredSubentity && featuredSubentity.old_subentity_id && <Modal
            isOpen={this.state.modalIsOpen}
            onRequestClose={this.onCloseModal}
            style={modalCustomStyles}
            contentLabel="Are You Sure?">
              <div style={modalStyle.wrapper}>
                <div style={modalStyle.header}>
                  Performing this action will un-feature your current Campaign
                </div>
                <div style={modalStyle.subHeader}>
                  Only one Campaign can be featured at a time. Continuing will replace the existing Featured Campaign with the one selected.<br />Are you sure you want to continue?
                </div>
                    <div style={modalStyle.btnSection}>
                    <OrangeButton style={style.btn} small="true" onClick={this.onCloseModal} primary disabled={submitting} label="No, cancel." />
                          <GreenButton style={style.btn} small="true" onClick={this.onUnfeatureAndFeature} primary disabled={submitting || invalid} onSuccess={this.onCloseModal} label="Yes, continue." />
                </div>
              </div>
            </Modal>
          }
        </form>
      </Card>
    )
  }
}

let SubentitySelection = reduxForm({
  form: 'entity-registration_choosesubentity',
  onSubmit: (values, dispatch, props) => (feature({props}))
})(SubentitySelectionForm);

SubentitySelection = connect((state) => ({
  entityMgr: state.entityManagerReducer.record,
  featuredSubentity: state.subentitySelectionReducer.record,
  entityTemplating: state.entityTemplatingReducer.record
}), {
  setSelectedSubentity,
  featureSubentityBegin,
  featureSubentitySuccess,
  featureSubentityFailure
})(SubentitySelection);

export default SubentitySelection;
