import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const style = {
  error: {
    color: 'red',
    fontSize: 14,
    marginLeft: 40,
  },
  checkboxErr: {
    fill: 'rgb(255, 0, 0)'
  }
}

const CheckboxInput = ({ meta: { touched, error } = {}, input: { ...inputProps }, ...props }) => {
  return (
    <div>
      <Checkbox
        {...props}
        checked={inputProps.value ? true : false}
        onClick={inputProps.onChange}
        style={{color: "#23bfb8"}}
      />
      {touched && error && <div style={style.error}>{error}</div>}
    </div>
  )
}

export default CheckboxInput;
