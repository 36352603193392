import React, {createElement} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {
  getFromNestedSafely as gS,
  getEntityEarnings
} from './../../services';
import moment from 'moment';

let style = {
  content: {
    textAlign: 'center',
  },
  number: {
    fontSize: 40,
    fontFamily: 'ProximaNova-SemiBold',
    color: '#2CBBB7',
  },
  fundDate: {
    marginTop: 10,
    fontSize: 16,
    color: '#828C93',
    fontFamily: 'ProximaNova-SemiBold',
  },
}

class FundsRaised extends React.Component {

  componentDidUpdate(prevProps, prevState) {
    if (prevProps && prevProps.record !== this.props.record) this.updateData();
  }

  componentDidMount() {
    this.updateData();
  }

  updateData() {
    if (!gS(this.props,'record.id')) {
      return console.error('FundsWidget requires record.id prop');
    }
    getEntityEarnings({
      "subentity_id": gS(this.props, 'record.id'),
      "begins": gS(this.props,'record.created'),
      "withChildren": true
    }).then(res => {
      console.log(res);
      this.props.record.earnings = gS(res,'merged.earned', 0);
      this.setState({record: this.props.record});
    }).catch(err => {
      console.log(err);
      this.props.record.earnings = 0;
      this.setState({record: this.props.record});
    });
  }

  render() {
    const {
      disabled,
    } = this.props;
    const created = gS(this.props,'record.created');
    const startDate = moment(created).format('MMMM Do, YYYY');
    const earnings = gS(this.props,'record.earnings', 0);
    return (
      <div style={style.content}>
        <div style={style.number}>${earnings}</div>
        has been raised since
        <div style={style.fundDate}>{startDate}</div>
      </div>
    );
  }
}

export default FundsRaised;
