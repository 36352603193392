import React, {createElement} from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';
// import {
//   crudGetOne
// } from 'admin-on-rest';
import {
  PaperPlaneIcon,
  Tooltip
} from './../';
import {
  getFromNestedSafely as gS,
  getSumSentBySubentityIdWithChildren,
  getSubentityIds,
  entityMgrConnect
} from './../../services';

const style = {
  number: {
    fontSize: 72,
    fontFamily: 'ProximaNova-SemiBold',
    color: '#2CBBB7',
    textAlign: 'center',
    textDecoration: 'none'
  }
}

class TotalBoosters extends React.Component {

  componentDidUpdate(prevProps, prevState) {
    if (prevProps && prevProps.entityMgr !== this.props.entityMgr) this.updateData();
  }

  componentDidMount(prevProps, prevState) {
    this.updateData();
  }

  updateData() {
    if (!gS(this.props,'entityMgr.subentity')) return;
    getSubentityIds({
      "subentity_ids": [this.props.entityMgr.subentity_id],
      "args": {
        "filter":{"is_active": true},
        "pluck_parent": true,
      }
    }).then(res => {
      console.log(res);
      this.props.entityMgr.subentity.subentityIds = res || [];
      this.setState({entityMgr: this.props.entityMgr});
    }).catch(err => {
      console.log(err);
      this.props.entityMgr.subentity.subentityIds = [];
      this.setState({entityMgr: this.props.entityMgr});
    });
  }

  render() {
    const {} = this.props;
    let boosters = gS(this.props,'entityMgr.subentity.subentityIds.length', 0);


    return (
      <div style={style.body}>
        <div style={style.number}><Link style={style.number} to="/boosters">{boosters}</Link></div>
      </div>
    );
  }
}

export default TotalBoosters;
