import React from 'react';
import _ from 'lodash';
import {
  minLength,
  maxLength
} from 'react-admin';
import moment from 'moment';
function isValidJSONString(jsonString) {
  try {
    var o = JSON.parse(jsonString);

    // Handle non-exception-throwing cases:
    // Neither JSON.parse(false) or JSON.parse(1234) throw errors, hence the type-checking,
    // but... JSON.parse(null) returns null, and typeof null === "object",
    // so we must check for that, too. Thankfully, null is falsey, so this suffices:
    if (o && typeof o === "object") {
      return o;
    }
  }
  catch (e) { }
  return false;
}

function _baseValidation(values, errors) {
  if (values.templating
      && (typeof values.templating == "string")
      && !isValidJSONString(values.templating)) {
    errors.templating = ['Must provide valid JSON for templating'];
  }
}

export const Validator = (values) => {
  const errors = {};
   _baseValidation(values, errors);
  if (Object.keys(errors).length) {
    console.error(errors);
  }
  return errors;
};

export const required = value => (value || typeof value === 'number' ? undefined : 'Required')
export const minMaxCharCount = []
export const isValidMoment = value => moment.isMoment( value ) && value.isValid() ? undefined : 'Enter Birthdate';
export const phoneNumber = value => value && !/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(value)  ? 'Invalid phone number, must be 10 digits' : undefined;
export const phoneNumberWithoutDashes = [minLength(10), maxLength(10)];
export const email = value => value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ? 'Invalid email address' : undefined;
export const url = value => value && !/^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value) ? "Invaild URL. Please format as 'http://'" : undefined;

export const ein = value => value && !/^\d{2}\-?\d{7}$/.test(value) ? 'Please enter a valid EIN' : undefined;
export const zipCode = value => value && !/^\d{5}$/.test(value) ? 'Please enter a valid US zip code' : undefined;
export const matchEmail = (value, allValues) => {
  return value !== _.get(allValues,'entity_manager.email') ? 'Email addresses do not match.  Please be sure your email address is correct.' : undefined;
}
