import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import Menu from './Menu';

import {
  Responsive,
  MenuItemLink,
  getResources,
  DashboardMenuItem
} from 'react-admin';

const styles = {
  sidebar: {
    position: 'fixed',
    top: 60,
    left: 0,
    bottom: 0,
    width: 200,
    zIndex: 90,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    background: '#FFF',
    boxShadow: `2px 2px 6px rgba(0,0,0,0.2)`,
    transition: 'left 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms',
  },
  sidebarClosed: {
    left: -210,
  },
  sidebarHeader: {
    height: 50,
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 20,
    backgroundColor: '#F2F7F7',
    color: '#9B9B9B',
    fontSize: 16
  },
  copyright: {
    margin: 10,
    font: '12px ProximaNova-Light',
    color: '#95989A',
  },
  link: {
    font: '12px ProximaNova-Light',
    color: '#95989A',
    textDecoration: 'none',
    marginTop: 10,
    display: 'inline-block'
  }
};

// {React.cloneElement(children, {
//     onMenuTap: () => null,
// })}


class Sidebar extends PureComponent {

  render() {
    const { open, children, width, isMobile, ...rest } = this.props;

    return (
      <div style={open ? {...styles.sidebar, ...styles.sidebarClosed} : styles.sidebar}>
        {React.cloneElement(
          <div>
            <Menu entityMgr={this.props.entityMgr}/>
          </div>
        )}
        <div style={styles.copyright}>
          &copy; greenback {(new Date().getFullYear())}
          <br />
          <Link style={styles.link} to={`/privacy`}>privacy policy</Link> / <Link style={styles.link} to={`/terms`}>terms & conditions</Link>
        </div>
      </div>
    );
  }
}

Sidebar.propTypes = {
  open: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => ({
  open: state.admin.ui.sidebarOpen,
});

export default compose(
  connect(mapStateToProps)
)(Sidebar);
