import loopbackRestClient from './../../aor-loopback';
import config from './../../config/config.provider';
const restClient = loopbackRestClient(config)

export const FETCH_ENTITYTEMPLATING_BEGIN   = 'GGB/FETCH_ENTITYTEMPLATING_BEGIN';
export const FETCH_ENTITYTEMPLATING_SUCCESS = 'GGB/FETCH_ENTITYTEMPLATING_SUCCESS';
export const FETCH_ENTITYTEMPLATING_FAILURE = 'GGB/FETCH_ENTITYTEMPLATING_FAILURE';

export const fetchEntityTemplatingBegin = () => ({
  type: FETCH_ENTITYTEMPLATING_BEGIN
});

export const fetchEntityTemplatingSuccess = data => ({
  type: FETCH_ENTITYTEMPLATING_SUCCESS,
  payload: { data }
});

export const fetchEntityTemplatingFailure = error => ({
  type: FETCH_ENTITYTEMPLATING_FAILURE,
  payload: { error }
});

const initialState = {
  record: null,
  loading: false,
  error: null
};

export function fetchEntityTemplatingById({id, action}) {
  return new Promise((resolve, reject) => {
    if (!id) return reject(new Error("NO_ID"));
    restClient("WITH_ROUTE","entities",{
      "route": "getTemplatingById",
      "data": {"args": JSON.stringify({id})}
    }).then(resolve).catch(reject);
  });
}

export default function entityTemplatingReducer(state = initialState, action) {
  //console.log(state, action);
  // console.log(action);
  switch(action.type) {
    case FETCH_ENTITYTEMPLATING_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };
    case FETCH_ENTITYTEMPLATING_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: false,
        record: action.payload.data
      };
    case FETCH_ENTITYTEMPLATING_FAILURE:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere
      // Since it failed, we don't have items to display anymore, so set it empty.
      // This is up to you and your app though: maybe you want to keep the items
      // around! Do whatever seems right.
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        record: null
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
