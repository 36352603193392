import React from 'react';
import PropTypes from 'prop-types';

function FacebookIcon({fill, height}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" height={height} viewBox="0 0 40 40">
      <g fill={fill} transform="translate(0 0)">
        <path d="M0,19.968A19.978,19.978,0,0,1,34.095,5.835a20.031,20.031,0,0,1,.023,28.3A19.977,19.977,0,0,1,0,19.968ZM25.5,33.4V22.9h3.631l.544-4.217H25.5V15.989c0-1.22.339-2.053,2.089-2.053h2.232V10.163A29.823,29.823,0,0,0,26.573,10c-3.217,0-5.421,1.966-5.421,5.575v3.11h-3.64V22.9h3.64V33.4Z" transform="translate(0 0)"/>
      </g>
    </svg>

  );
}

FacebookIcon.propTypes = {
  fill: PropTypes.string,
  height: PropTypes.number,
};

FacebookIcon.defaultProps = {
  fill: '#4460A0',
  height: 40
};

export default FacebookIcon;
