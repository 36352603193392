import loopbackRestClient from './../../aor-loopback';
import config from './../../config/config.provider';
const restClient = loopbackRestClient(config)

export const CHANGE_EMAIL_CONFIRM_BEGIN   = 'GGB/CHANGE_EMAIL_CONFIRM_BEGIN';
export const CHANGE_EMAIL_CONFIRM_SUCCESS = 'GGB/CHANGE_EMAIL_CONFIRM_SUCCESS';
export const CHANGE_EMAIL_CONFIRM_FAILURE = 'GGB/CHANGE_EMAIL_CONFIRM_FAILURE';

export const changeEmailConfirmBegin = () => ({
  type: CHANGE_EMAIL_CONFIRM_BEGIN
});

export const changeEmailConfirmSuccess = data => ({
  type: CHANGE_EMAIL_CONFIRM_SUCCESS,
  payload: { data }
});

export const changeEmailConfirmFailure = error => ({
  type: CHANGE_EMAIL_CONFIRM_FAILURE,
  payload: { error }
});

const initialState = {
  record: null,
  loading: false,
  error: null
};

export function confirmByToken({entity_manager_id, token}) {
	return new Promise((resolve, reject) => {
		if (!token) return reject(new Error("NO_TOKEN"));
    restClient("WITH_ROUTE", "EntityManagers", {route:`${entity_manager_id}/changeEmailConfirm?confirm_token=${token}`,without_token: true}).then(({response}) => {
      (response.status == 200) ? resolve(response.json) : reject(response);
    }).catch(reject);
	});
}

export function fetchByToken({entity_manager_id, token}) {
	return new Promise((resolve, reject) => {
		if (!token) return reject(new Error("NO_TOKEN"));
    restClient("WITH_ROUTE", "EntityManagers", {route:`${entity_manager_id}/changeEmailFetch?confirm_token=${token}`,without_token: true}).then(({response}) => {
      (response.status == 200) ? resolve(response.json) : reject(response);
    }).catch(reject);
	});
}

export default function entitymanagerReducer(state = initialState, action) {
  switch(action.type) {
    case CHANGE_EMAIL_CONFIRM_BEGIN:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null
      };
    case CHANGE_EMAIL_CONFIRM_SUCCESS:
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      return {
        ...state,
        loading: false,
        record: action.payload.data
      };
    case CHANGE_EMAIL_CONFIRM_FAILURE:
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere
      // Since it failed, we don't have items to display anymore, so set it empty.
      // This is up to you and your app though: maybe you want to keep the items
      // around! Do whatever seems right.
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        record: null
      };
    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
}
