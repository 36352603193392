import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom'
import { grid } from './../../style';
import Radium from 'radium'
import style from './style.js';
import _ from 'lodash';
import {
  getFromNestedSafely as gS,
} from './../../services';
import {
  Widget,
  WidgetDashboardHeader,
  FundsRaised,
  OrangeButton,
  GreenButton,
  GreenLink,
  IconButtons,
  DISFormWidget,
  SocialContent,
  Card,
  TourWizard,
  InvitesSent,
} from '../';
import { cobrandLink } from './../../services';

const BoosterDashboard = (props) => {
  const entityMgr = props.entityMgr || {};
  const disableBoosterView = !_.get(entityMgr, 'subentity.templating.logo') ? true : false;
  const steps = [{
    target: '.appbar',
    title: 'Welcome to your Management Portal!',
    content: 'Through this portal you will be able to create your Booster Site, and share your page with friends & family! Let\'s get started by creating your Booster Site.',
    disableBeacon: true,
    isFixed: true,
    hideCloseButton: true,
    placement: 'center',
    locale: {close: 'Get Started!'}
  },
  {
    target: '.booster_site',
    title: 'Personalize Your Booster Site',
    content: 'Click to create your Booster Site. This site will be used to share with friends & family, while collecting donations that go towards your fundraiser!',
    disableBeacon: true,
    isFixed: true,
    hideFooter: true,
    hideCloseButton: true,
  }]
  const joyrideTrigger = !_.get(entityMgr, 'subentity.templating.logo', '') ? true : false;

  let
  unitName = '',

  mName = gS(props, 'entityMgr.first_name');
  unitName = gS(props, 'entityMgr.subentity.subentity.templating.name');

  return (
    <div>
      <TourWizard
        steps={steps}
        continuous={!joyrideTrigger}
        run={joyrideTrigger}
      />
      <div style={grid.grid}>
      <div style={grid.row}>
      <WidgetDashboardHeader
        welcomeMessage={mName}
        caption={unitName}
      >
      <div style={style.buttons}>
        <GreenLink
          label="Edit Booster Site"
          to="/boosters"
          component={Link}
          small="true"
          icon="edit"
          className="booster_site"
          style={style.textButton}
        />
        <GreenLink
          label="View Booster Site"
          small="true"
          target="_blank"
          href={cobrandLink({entity: _.get(entityMgr, 'entity.cause'), slug: _.get(entityMgr, 'subentity.slug')})}
          icon="open_in_new"
          disabled={disableBoosterView}
          style={style.textButton}
        />
        </div>
      </WidgetDashboardHeader>
      </div>
        <div style={grid.row}>
          <div style={grid.col50}>
            <Widget
              icon="email"
              title="Invites Sent"
              tip="The number of invitations you have sent using the Donor Invitation System (DIS)."
              className="invites_sent"
            >
              <InvitesSent entityMgr={entityMgr} />
            </Widget>
          </div>
          <div style={grid.col50}>
            <Widget
              icon="monetization_on"
              title="Funds Raised"
              tip="This is the amount you have personally raised through your Booster Site."
              className="funds_raised"
            >
              <FundsRaised record={entityMgr.subentity} />
            </Widget>
          </div>
        </div>
        <div style={grid.row}>
          <div style={grid.col50}>
            <Widget
              icon="message"
              title="Donor Invitation System (DIS)"
              caption="Invite a friend or family member to donate."
              tip="The DIS will create a personalized email to send your recipient requesting a donation, explaining what the donation is for, and information about greenback!"
              className="dis"
            >
              <DISFormWidget />
            </Widget>
          </div>
          <div style={grid.col50}>
            <Widget
              icon="share"
              title="Share Booster Site with Others"
              caption="Share your site with others by sending the link directly or using social media."
              tip="Easily share your Booster Site via social media by copying your unique link, or by using one of the social shortcut icons."
              className="social"
            >
              <SocialContent
                entityMgr={entityMgr}
                sms="true"
                facebook="true"
                email="true"
              />
            </Widget>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Radium(BoosterDashboard);
