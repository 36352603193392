import React, { Component } from 'react';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import CardActions from '@material-ui/core/CardActions';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {Link} from 'react-router-dom';
import Radium from 'radium';
import {
  Card,
  FormCard,
  GreenButton,
  LabeledTextInput,
  ImageInput,
  Edit,
  FundraiserOverview,
  BoosterList,
  FundraiserPage
 } from '../../components';
import {
  List,
  CreateButton,
  RefreshButton,
  Filter,
  TextInput
} from 'react-admin';
import {
  formEnhancer,
  entityMgrConnect
} from './../../services';

const style = {
  tabs: {
    width: '100%',
    background: '#FFF',
    boxShadow: `2px 2px 6px rgba(0,0,0,0.2)`,
    borderRadius: 4,
    marginBottom: 20,
    marginTop: 15,
  },
  container: {
    height: 60,
  },
  selected: {
    background: 'rgba(35,191,184,0.1)',
  },
  indicator: {
    background: '#23BFB8',
  },
  label: {
    font: '16px ProximaNova-SemiBold',
    color: '#464D5E',
    letterSpacing: 1,
    textTransform: 'none',
    '@media (max-width: 640px)': {
      fontSize: 12,
      letterSpacing: 0,
    }
  },
  list: {
    flexDirection: 'column',
  },
  back: {
    position: 'relative',
    marginBottom: 30,
    font: '14px ProximaNova-SemiBold',
    textDecoration: 'none',
    color: '#FF6200',
  }
}

const SubentityFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="where.templating.name.like" alwaysOn />
  </Filter>
);

const PostActions = ({ resource, filters, displayedFilters, filterValues, basePath, showFilter }) => (
  <CardActions>
    {filters && React.cloneElement(filters, { resource, showFilter, displayedFilters, filterValues, context: 'button' }) }
    <RefreshButton />
  </CardActions>
);
class FundraiserEdit extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div>
        <Link style={style.back} to="/fundraisers">Back to Fundraiser List</Link>
        <div className={classes.tabs}>
          <Tabs
            variant="fullWidth"
            value={value}
            onChange={this.handleChange}
            classes={{
              indicator: classes.indicator,
              flexContainer: classes.container
            }}
          >
            <Tab label="Fundraiser Overview" classes={{selected: classes.selected, label:classes.label}}/>
            <Tab label="Edit Fundraiser" classes={{selected: classes.selected, label:classes.label}}/>
            <Tab label="Boosters" classes={{selected: classes.selected, label:classes.label}}/>
          </Tabs>
        </div>
        {value === 0 &&
          <Edit {...this.props}>
            <FundraiserOverview entityMgr={this.props.entityMgr}/>
          </Edit>
        }
        {value === 1 &&
          <FundraiserPage {...this.props} entityMgr={this.props.entityMgr}/>
        }
        {value === 2 &&
          <div style={{minWidth:450}}>
            <List
              actions={<PostActions />}
              bulkActionButtons={false}
              {...this.props}
              resource="boosters"
              filter={{where: {subentity_id: this.props.id}}}
              classes={{root: classes.list}}
            >
              <BoosterList {...this.props}/>
            </List>
          </div>
        }
      </div>
    );
  }
}

FundraiserEdit = Radium(FundraiserEdit);

export default entityMgrConnect(withStyles(style)(FundraiserEdit));
