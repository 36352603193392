import React, {Component} from 'react';
import {
  Card,
  Privacy
} from './../../components';

const style = {
  wrapper: {
    position: 'relative',
  },
  title: {
    font: '18px ProximaNova-SemiBold',
  },
  content: {
    marginTop: 20,
  },
  href: {
    color: '#2CBBB7',
    textDecoration: 'none',
    fontFamily: "'ProximaNova-SemiBold'",
  },
}

const InternalPrivacy = (props) => {
  return (
    <Privacy />
  )
}

export default InternalPrivacy;
