import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Field, submit } from 'redux-form';
import Radium from 'radium';
import {
  Create,
} from 'react-admin';
import {
  Card,
  FormCard,
  GreenButton,
  LabeledTextInput,
  Show,
  SimpleShowLayout,
  CheckboxInput,
  Step,
  Flippy,
 } from '../../components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import {
  formEnhancer,
  entityMgrConnect,
  getFromNestedSafely as gS,
  clickToCopy,
  cobrandLink
} from './../../services';

const style = {
  tabs: {
    width: '100%',
    background: '#FFF',
    boxShadow: `2px 2px 6px rgba(0,0,0,0.2)`,
    borderRadius: 4,
    marginBottom: 20,
  },
  container: {
    height: 60,
    minWidth: 335,
  },
  selected: {
    background: 'rgba(35,191,184,0.1)',
  },
  indicator: {
    background: '#23BFB8',
  },
  label: {
    font: '16px ProximaNova-SemiBold',
    color: '#464D5E',
    letterSpacing: 1,
    textTransform: 'none',
    '@media (max-width: 640px)': {
      fontSize: 12,
      letterSpacing: 0
    }
  },
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media (max-width: 640px)': {
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    }
  },
  flyerCard: {
      width: '100%',
      background: '#FFF',
      boxShadow: `2px 2px 6px rgba(0,0,0,0.2)`,
      borderRadius: 4,
      marginTop: 15
    ,
  },
  cardCol: {
    width: 'calc(50% - 20px)',
    '@media (max-width: 640px)': {
      width: '100%',
      marginBottom: 20,
    }
  },
  title: {
    font: '22px ProximaNova-SemiBold',
    marginBottom: 20,
    '@media (max-width: 640px)': {
      textAlign: 'center'
    }
  },
  text: {
    font: '16px ProximaNova-Regular',
    lineHeight: 1.5,
    '@media (max-width: 640px)': {
      textAlign: 'center'
    }
  },
  input: {
    marginBottom: 10,
  },
  submit: {
    marginTop: 40,
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
  fundLink: {
    display: 'block',
    fontSize: 22,
    fontFamily: 'ProximaNova-SemiBold',
    color: '#2CBBB7',
    marginBottom: 20,
  },
  fundCode: {
    display: 'block',
    color: '#1E5E75',
    fontSize: 22,
    fontFamily: 'ProximaNova-SemiBold',
    marginTop: 20,
    letterSpacing: 4
  },
  button: {
    marginTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media (max-width: 640px)': {

    }
  },
  hiddenInput: {
    position: 'absolute',
    left: -1000,
    top: -1000,
    opacity: 0,
  },
  coppa: {
    margin: '15px auto 0',
    textAlign: 'center',
    fontSize: 12,
    // maxWidth: 450,
    lineHeight: 1.4,
    fontFamily: 'ProximaNova-Light'
  },
  coppa2: {
    width: '80%',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 15,
    fontSize: 12,
    lineHeight: 1.4,
    fontFamily: 'ProximaNova-Light',
  }
}

const termsAgreementValidator = value => {
  return !value ? 'Must agree to the terms.' : undefined;
}

class CreateBoosterForm extends Component {

  componentWillReceiveProps(nextProps) {
    if (gS(nextProps, 'entityMgr.id') !== gS(this.props,'record.entity_manager_id')) {
      this.props.change('entity_manager_id', gS(nextProps, 'entityMgr.id'));
      this.props.change('entity_id', gS(nextProps, 'entityMgr.entity_id'));
      if (!this.shouldAllowSubentitySelection())
        this.props.change('subentity_id', gS(nextProps, 'entityMgr.subentity_id'));
    }
  }

  submit(values, action, props) {
    switch (props.realm) {
      case "subentity":
        return props.save(values, "/ersubentities/create");
      default:
        return props.save(values, `/${props.realm}s/create`);
    }
  }

  shouldAllowSubentitySelection() {
    return ["unit","subunit"].includes(this.props.realm) && !["unit","subunit"].includes(gS(this.props, 'entityMgr.realm')) && !(this.props.realm === "unit" && (gS(this.props, 'entityMgr.realm') === "subentity"));
  }

  render() {
    const id = "fund_Code";
    const successMsg = "Fundrasier Code Copied"
    const bsa = gS(this.props.entityMgr, 'entity.gb_cause_id') === 154 ? true : false;
    return (
      <div>
      <Card>
        <div style={style.card}>
          <div style={style.cardCol}>
            <FormCard content={{textAlign:'center'}}>
              <div style={style.title}>Booster Registration Site:</div>
              <a href="http://getgb.us/join" style={style.fundLink} target="blank">getgb.us/join</a>
              <div style={style.title}>Fundraiser Code:</div>
              <span style={style.fundCode}>{gS(this.props,'entityMgr.subentity.fundraiser_code')}</span>

            </FormCard>
          </div>
          <div style={style.cardCol}>
            <div style={style.title}>
            Invite Boosters via Fundraiser Code
            </div>
              <div style={style.text}>Invite one or many individuals by copying the information below. Individuals can become a Booster by visiting the Booster Registration Site and registering with your unique Fundraiser Code.</div>
              <GreenButton
                label="Copy Details"
                small="true"
                onClick={clickToCopy({id,"component":this, successMsg})}
                style={style.button}
              />
            <textarea value={`Use the link and Fundraiser Code below to register as a Booster!\n\nBooster Registration Site:\ngetgb.us/join\n\nFundraiser Code:\n${gS(this.props,'entityMgr.subentity.fundraiser_code')}`} id={id} style={style.hiddenInput} />
          </div>
        </div>
        <div style={style.coppa2}>
          *greenback is not permitted to collect information from individuals under the age of 13. If an individual is under the permitted age, you must send the invite to a parent or guardian.
        </div>
      </Card>

      {bsa ? (
        <Card style={style.flyerCard}>
        <div style={style.card}>
        <Flippy
          header="Scout Launch Flyer"
          image="//cloudfront.startupmedia.com/greenback/misc/imgs/bsa-pdf.png"
          caption={["Download the ",<a href="http://cloudfront.startupmedia.com/greenback/misc/graphics/CFCBSA-Booster-Setup-Flyer.pdf" target="_blank" title='Fundraiser Launch PDF'>Scout Launch Flyer</a>, " and enter your 6-digit Fundraiser Code into the blue Fundraiser Code space. Share this PDF with your Scouts by print or email."]}
          href="http://cloudfront.startupmedia.com/greenback/misc/graphics/CFCBSA-Booster-Setup-Flyer.pdf"
          title="Download"
        />
        </div>
      </Card>
    ) : ""}
    </div>
    )
  }
}

class InviteBoosterManagerForm extends Component {

  componentWillReceiveProps(nextProps) {
    if (gS(nextProps, 'entityMgr.id') !== gS(this.props,'record.entity_manager_id')) {
      this.props.change('entity_manager_id', gS(nextProps, 'entityMgr.id'));
      this.props.change('entity_id', gS(nextProps, 'entityMgr.entity_id'));
      this.props.change('subentity_id', gS(nextProps, 'entityMgr.subentity_id'));
    }
  }

  submit(values, action, props) {
    props.save(values, `/boosters`);
  }


  render() {
    const {handleSubmit, submitting, error, submitSucceeded, location, classes} = this.props;
    return (

        <Card>
        <div style={style.card}>
          <div style={style.cardCol}>
            <div style={style.title}>Invite Booster via Email</div>
              <div style={style.text}>Send an invite to a specific individual by entering their information to the right. If you are inviting multiple Boosters we suggest using the Fundraiser Code!</div>
              </div>
          <div style={style.cardCol}>
            <FormCard>
              <form onSubmit={this.props.handleSubmit(this.submit)}>
                <Field
                  component={LabeledTextInput}
                  label="First Name"
                  name="entity_manager.first_name"
                  style={style.input}
                  required
                />
                <Field
                  component={LabeledTextInput}
                  label="Last Name"
                  name="entity_manager.last_name"
                  style={style.input}
                  required
                />
                <Field
                  component={LabeledTextInput}
                  label="Valid Email Address"
                  name="entity_manager.email"
                  type="email"
                  required
                />
                <div style={style.coppa}>
                  <FormControlLabel
                    classes={{
                      root: classes.formLabel
                    }}
                    control={
                      <Field
                        {...this.props}
                        name="coppa_acknowledged"
                        component={CheckboxInput}
                        required
                      />
                    }
                    label="By checking this box, you confirm that the recipient of this email is 13 years of age or older"
                  />
                </div>
                <GreenButton type="submit" label="Invite Booster" small style={style.submit}/>
              </form>
            </FormCard>
          </div>
          </div>
        </Card>
    )
  }
}

const CreateBoosterFormConnect = entityMgrConnect(CreateBoosterForm);

const CreateBooster = formEnhancer({
  FormComponent: Radium(CreateBoosterFormConnect),
  formName: 'create-component'
});
const InviteFundraiserManager = formEnhancer({
  FormComponent: Radium(InviteBoosterManagerForm),
  formName: 'invite-component'
});



class BoosterCreate extends React.Component {
  state = {
    value: 0,
  };

  handleChange = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { classes } = this.props;
    const { value } = this.state;

    return (
      <div style={{minWidth:335}}>
        <div className={classes.tabs}>
          <Tabs
            variant="fullWidth"
            value={value}
            variant="fullWidth"
            onChange={this.handleChange}
            classes={{
              indicator: classes.indicator,
              flexContainer: classes.container
            }}
          >
            <Tab label="Invite Booster(s) via Fundraiser Code" classes={{selected: classes.selected, label:classes.label}}/>
            <Tab label="Invite Booster via Email" classes={{selected: classes.selected, label:classes.label}}/>
          </Tabs>
        </div>
        {value === 0 && <CreateBooster {...this.props}/>}
        {value === 1 &&
          <Create {...this.props}>
            <InviteFundraiserManager
              {...this.props}
              entityMgr={gS(this.props, 'entityMgr') || {}}
            />
          </Create>
        }
      </div>
    );
  }
}

export default entityMgrConnect(Radium(withStyles(style)(BoosterCreate)));
